import axios from "axios";
import { checkForToken } from "../../constants/ApiCall";
import { BackendBaseApi } from "../../constants/constants";

export const COMPANY_LISTING = "COMPANY_LISTING";
export const JOB_SEGMENT_LIST = "JOB_SEGMENT_LIST";
export const JOB_ROLES_LIST = "JOB_ROLES_LIST";
export const CITY_LIST = "CITY_LIST";
export const ROUNDS_LIST = "ROUNDS_LIST";
export const SINGLE_JOB_DATA = "SINGLE_JOB_DATA";
export const JOB_DATA = "JOB_DATA";
export const JOB_NAVBAR = "JOB_NAVBAR";
export const SKILLS = "SKILLS";
export const PLACEMENT_JOB_TRACK = "PLACEMENT_JOB_TRACK";
export const JOB_DETAILS_SIDE_DATA = "JOB_DETAILS_SIDE_DATA";
export const DISABLE_FIELDS_POST_JOB = "DISABLE_FIELDS_POST_JOB";
export const GROUP_COLLEGE_LIST = "GROUP_COLLEGE_LIST";
export const GROUP_JOB_DATA = "GROUP_JOB_DATA";

export const AllCompanyAPI = (
  setLoading,
  alert,
  history,
  location,
  collegeId
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(
        BackendBaseApi.SANCHALAN + "api/company/list/?college_id=" + collegeId,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        throw error;
      });

    if (res.data.success) {
      var arr = [];
      res.data.data.forEach((item) => {
        if (item.id && item.name) {
          arr.push(item);
        }
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
    dispatch({
      type: COMPANY_LISTING,
      payload: arr,
    });
  };
};

export const AllCompanyAPI2 = (
  setLoading,
  alert,
  history,
  location,
  groupId
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(
        BackendBaseApi.SANCHALAN + `api/manage_job/college-company/?` + groupId,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        throw error;
      });

    if (res.data.success) {
      var arr = [];
      res.data.data.forEach((item) => {
        if (item.id && item.name) {
          arr.push(item);
        }
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
    dispatch({
      type: COMPANY_LISTING,
      payload: arr,
    });
  };
};

export const CollegeCompanyAPI = (
  setLoading,
  alert,
  history,
  location,
  collegeIds
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(
        BackendBaseApi.SANCHALAN +
          `api/manage_job/college-company/?college_ids=` +
          collegeIds,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        throw error;
      });

    if (res.data.success) {
      var arr = [];
      res.data.data.forEach((item) => {
        if (item.id && item.name) {
          arr.push(item);
        }
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
    dispatch({
      type: COMPANY_LISTING,
      payload: arr,
    });
  };
};

export const AllJobSegmentAPI = (setLoading, alert, history, location) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(BackendBaseApi.PRAVESH + "api/company/job_segment/", {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        throw error;
      });

    if (res.data.success) {
      res.data.data.forEach((item) => {
        item["id"] = item.job_segment_id;
        item["name"] = item.job_segment_name;
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
    dispatch({
      type: JOB_SEGMENT_LIST,
      payload: res?.data?.data,
    });
  };
};

export const AllJobRolesAPI = (setLoading, alert, history, location) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(BackendBaseApi.NIYUKTI + "job/role/", {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        throw error;
      });

    if (res?.data?.success) {
      res?.data?.data.forEach((item) => {
        item["name"] = item.job_role_name;
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
    dispatch({
      type: JOB_ROLES_LIST,
      payload: res.data.data,
    });
  };
};

export const AllLocationsAPI = (setLoading, alert, history, location) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(BackendBaseApi.PRAVESH + "api/location/city/", {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        throw error;
      });

    setLoading(false);
    dispatch({
      type: CITY_LIST,
      payload: res.data,
    });
  };
};

export const AllLocationsAPII = (setLoading, alert, history, location) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(BackendBaseApi.PRAVESH + "api/location/city_state/", {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        throw error;
      });

    setLoading(false);
    dispatch({
      type: CITY_LIST,
      payload: res.data,
    });
  };
};

export const AllJobRoundsAPI = (alert, history, location) => {
  return async (dispatch) => {
    const res = await axios
      .get(BackendBaseApi.SANCHALAN + "public/job/round/", {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });
    if (res.data.success) {
      dispatch({
        type: ROUNDS_LIST,
        payload: res.data.data,
      });
    }
  };
};

export const JobPostAPI = (
  data,
  setLoading = () => {},
  alert,
  history,
  location,
  handleClickOpen,
  jobData,
  setReturnJobId,
  jobText,
  setLoadingDraft,
  setJobDetails,
  setSalaryBreakup,
  setPlacementCycle,
  setJobDesc,
  setDataSelectedNewFormat,
  pushArray,
  setPushArray,
  setCoursesArray,
  selectedCourses,
  setDataSelected,
  setSpecializationInDgegreeId,
  setQualifications,
  handleClosePreview,
  setPpoSalaryBreakup,
  setConditionsText,
  setFormSubmitted,
  multiJobPosting = false,
  setStep = () => {}
) => {
  return async (dispatch) => {
    if (data.job_status === "OPEN") setLoading(true);
    else setLoadingDraft(true);

    const res = await axios
      .post(BackendBaseApi.SANCHALAN + "api/manage_job/view/", data, {
        withCredentials: true,
      })
      .catch((error) => {
        if (data.job_status === "OPEN") setLoading(false);
        else setLoadingDraft(false);
        setLoadingDraft(false);
        alert.error(error.response.data.error);
        checkForToken(error, history, location, alert);
        throw error;
      });
    if (res.data.success) {
      handleClickOpen();
      handleClosePreview();

      var arr_data = jobData ? [...jobData] : [];
      arr_data.unshift(res.data.data);
      data.college_job_data.degree_spec_selected = [];
      data.id = res.data.data.job_id;
      if (!multiJobPosting) history.push("/manage-jobs");
      else setStep(4);
      setReturnJobId({
        id: res.data.data.id,
        job_title: res.data.data.job_title,
      });
      setJobDetails({
        company_id: null,
        company_name: null,
        company: null,
        job_title: "",
        job_type_id: 7,
        job_type_name: "Full Time",
        job_role_id: null,
        job_role_name: null,
        job_role: null,
        job_segment_id: null,
        job_segement_name: null,
        job_segment: null,
        application_start_date: new Date(),
        application_end_date: new Date(),
        joining_date: new Date(),
        job_description: null,
        ctc_min: null,
        ctc_max: null,
        bonus: false,
        salary_type: "NOT DISCLOSED",
        salary_breakup: null,
        wfh: false,
        pan_india: false,
        job_location: [],
        vacancy: "",
        service_bond: false,
        conditions: null,
        ppo: false,
        ppo_ctc_min: null,
        ppo_salary_type: "NOT DISCLOSED",
        ppo_ctc_max: null,
        salary_breakup_ppo: null,
        degree_spec_selected: [],
        grad_scoring: "CGPA",
        grad_min_score: "",
        backlog: false,
        sec_scoring: "CGPA",
        sec_min_score: "",
        senior_sec_scoring: "CGPA",
        senior_sec_min_score: "",
        eligible_batches: [],
        rounds: [],
        documents: [],
        skills: [],
      });
      setPlacementCycle([]);
      setSalaryBreakup("");
      setJobDesc("");
      setPpoSalaryBreakup("");
      setConditionsText("");
      setDataSelectedNewFormat([]);
      setDataSelected([]);

      var arr = [...selectedCourses];
      arr.map((item) => {
        item.degrees.forEach((item2) => {
          item2.select_all = false;
          item2.specialization.forEach((item3) => {
            item3.selected = false;
          });
        });
      });
      setCoursesArray(arr);

      var temp = {};

      arr.forEach((item) => {
        item?.degrees.forEach((item2) => {
          temp[item2.degree_id] = [];
        });
      });
      setSpecializationInDgegreeId(temp);
      setQualifications({});

      var push_arr = [...pushArray];
      push_arr.push(1);
      setPushArray(push_arr);
      dispatch({
        type: SINGLE_JOB_DATA,
        payload: data,
      });
    }

    if (data.job_status === "OPEN") setLoading(false);
    else setLoadingDraft(false);
    setFormSubmitted(true);
    dispatch({
      type: JOB_DATA,
      payload: arr_data,
    });
  };
};

export const JobPatchAPI = (
  data,
  setLoading,
  alert,
  history,
  location,
  handleClickOpen,
  objId,
  setJobDetails,
  setReturnJobId,
  setSalaryBreakup,
  setPlacementCycle,
  setJobDesc,
  setDataSelectedNewFormat,
  pushArray,
  setPushArray,
  setCoursesArray,
  selectedCourses,
  setDataSelected,
  setSpecializationInDgegreeId,
  setQualifications,
  handleClosePreview,
  setPpoSalaryBreakup,
  setConditionsText,
  setFormSubmitted,
  multiJobPosting = false,
  setStep = () => {},
  jobAction = false
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .patch(`${BackendBaseApi.SANCHALAN}api/manage_job/view/${objId}`, data, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        throw error;
      });
    if (res.data.success) {
      setReturnJobId({
        id: objId,
        job_title: data?.college_job_data?.job_title,
      });
      setLoading(false);

      handleClickOpen();
      handleClosePreview();

      if (!multiJobPosting || jobAction === "edit")
        history.push(
          multiJobPosting ? "/educational-group/manage-jobs" : "/manage-jobs"
        );
      else setStep(4);

      setJobDetails({
        company_id: null,
        company_name: null,
        company: null,
        job_title: "",
        job_type_id: 7,
        job_type_name: "Full Time",
        job_role_id: null,
        job_role_name: null,
        job_role: null,
        job_segment_id: null,
        job_segement_name: null,
        job_segment: null,
        application_start_date: new Date(),
        application_end_date: new Date(),
        joining_date: new Date(),
        job_description: "",
        ctc_min: null,
        ctc_max: null,
        bonus: false,
        salary_type: "NOT DISCLOSED",
        salary_breakup: "",
        wfh: false,
        pan_india: false,
        job_location: [],
        vacancy: "",
        service_bond: false,
        conditions: "",
        ppo: false,
        ppo_ctc_min: null,
        ppo_salary_type: "NOT DISCLOSED",
        ppo_ctc_max: null,
        salary_breakup_ppo: "",
        degree_spec_selected: [],
        grad_scoring: "CGPA",
        grad_min_score: "",
        backlog: false,
        sec_scoring: "CGPA",
        sec_min_score: "",
        senior_sec_scoring: "CGPA",
        senior_sec_min_score: "",
        eligible_batches: [],
        rounds: [],
        documents: [],
        skills: [],
      });
      setPlacementCycle([]);
      setDataSelected([]);
      setConditionsText("");
      setSalaryBreakup("");
      setJobDesc("");
      setPpoSalaryBreakup("");
      setDataSelectedNewFormat([]);
      setQualifications({});

      var arr = [...selectedCourses];
      arr.map((item) => {
        item.degrees.forEach((item2) => {
          item2.select_all = false;
          item2.specialization.forEach((item3) => {
            item3.selected = false;
          });
        });
      });
      setCoursesArray(arr);

      var temp = {};

      arr.forEach((item) => {
        item?.degrees.forEach((item2) => {
          temp[item2.degree_id] = [];
        });
      });
      setSpecializationInDgegreeId(temp);
      var push_arr = [...pushArray];
      push_arr.push(1);
      setPushArray(push_arr);
    } else {
      setLoading(false);
      alert.error("Some Error Occurred!!!");
    }
    setFormSubmitted(true);
  };
};

export const JobNavbarAPI = (url, source) => {
  return async (dispatch) => {
    let cancelTokenObj = {};

    if (source && source?.token) {
      cancelTokenObj = {
        withCredentials: true,
        cancelToken: source?.token,
      };
    } else {
      cancelTokenObj = {
        withCredentials: true,
      };
    }

    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}${url}`, cancelTokenObj)
      .catch((error) => {
        throw error;
      });
    if (res?.data?.success) {
      dispatch({
        type: JOB_NAVBAR,
        payload: res?.data?.data,
      });
      //   history.push("/manage-jobs");
    }
  };
};

export const getSinglePostedJobAPI = (
  setLoading,
  alert,
  history,
  location,
  jobId,
  url
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}${url}`, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        throw error;
      });

    setLoading(false);
    if (res.data.success) {
      dispatch({
        type: SINGLE_JOB_DATA,
        payload: res.data.data,
      });
    }
  };
};

export const getOtherJobPostApi = (
  setLoading,
  alert,
  history,
  location,
  jobId,
  url
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}${url}`, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        throw error;
      });

    setLoading(false);
    if (res?.data?.success) {
      dispatch({
        type: SINGLE_JOB_DATA,
        payload: res?.data?.data,
      });
    }
  };
};

export const JobDetailsSideInfoAPI = (
  setLoading,
  alert,
  history,
  location,
  jobId
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(
        `${BackendBaseApi.SANCHALAN}api/manage_job/job_detail/info/?job_id=${jobId}`,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        throw error;
      });

    setLoading(false);
    dispatch({
      type: JOB_DETAILS_SIDE_DATA,
      payload: res?.data?.data,
    });
  };
};

export const getGroupJobsAPI = (
  setLoading = () => {},
  alert,
  history,
  location,
  url,
  setJobSearch,
  setCompanySearch,
  fromJobTrack,
  setApplyClickedCTC,
  setFirstApiCall,
  setCompanyList = () => {},
  source,
  setCompanyListTotalCount
) => {
  return async (dispatch) => {
    setLoading(true);

    let cancelTokenObj = {};

    if (source?.token) {
      cancelTokenObj = {
        withCredentials: true,
        cancelToken: source?.token,
      };
    } else {
      cancelTokenObj = {
        withCredentials: true,
      };
    }
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}${url}`, cancelTokenObj)
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        throw error;
      });
    if (res?.data?.success) {
      if (fromJobTrack) {
        setJobSearch(false);
        setCompanySearch(false);
        setApplyClickedCTC(false);
        setFirstApiCall(true);
      }
      setLoading(false);
      setCompanyListTotalCount(res.data.data.count);

      dispatch({
        type: GROUP_JOB_DATA,
        payload: res.data.data.data,
      });
    } else {
      setLoading(false);
    }
  };
};

export const getJobsAPI = (
  setLoading = () => {},
  alert,
  history,
  location,
  url,
  setJobSearch,
  setCompanySearch,
  fromJobTrack,
  setApplyClickedCTC,
  setFirstApiCall,
  setCompanyList = () => {},
  source
) => {
  return async (dispatch) => {
    setCompanyList([]);
    setLoading(true);

    let cancelTokenObj = {};

    if (source?.token) {
      cancelTokenObj = {
        withCredentials: true,
        cancelToken: source?.token,
      };
    } else {
      cancelTokenObj = {
        withCredentials: true,
      };
    }
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}${url}`, cancelTokenObj)
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        throw error;
      });
    if (fromJobTrack) {
      setJobSearch(false);
      setCompanySearch(false);
      setApplyClickedCTC(false);
      setFirstApiCall(true);
      setCompanyList(res.data.data);
    }
    setLoading(false);
    dispatch({
      type: JOB_DATA,
      payload: res.data.data,
    });
  };
};

export const getJobsAPI2 = (
  setLoading = () => {},
  // setLoading2,
  alert,
  history,
  location,
  url,
  setJobSearch,
  setCompanySearch,
  fromJobTrack,
  setApplyClickedCTC,
  setFirstApiCall,
  setCompanyList,
  // setCompanyListTable,
  source,
  setCompanyListTotalCount
) => {
  return async (dispatch) => {
    // setCompanyList([]);
    // setCompanyListTable([]);
    setLoading(true);
    setFirstApiCall(true);

    // setLoading2(true);

    let cancelTokenObj = {};

    if (source?.token) {
      cancelTokenObj = {
        withCredentials: true,
        cancelToken: source?.token,
      };
    } else {
      cancelTokenObj = {
        withCredentials: true,
      };
    }
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}${url}`, cancelTokenObj)
      .catch((error) => {
        if (error.message != "Cancelled due to stale request")
          setLoading(false);
        // setLoading2(false);
        checkForToken(error, history, location, alert);
        throw error;
      });
    if (res?.data?.success) {
      if (fromJobTrack) {
        setJobSearch(false);
        setCompanySearch(false);
        setApplyClickedCTC(false);
        setCompanyListTotalCount(res.data.data.count);
        // setFirstApiCall(true);
        // setCompanyList(res.data.data.results);
      }
      // if (!res.data.data.length) {
      //   setLoading2(false);
      // }
      setLoading(false);
      dispatch({
        type: JOB_DATA,
        payload: res.data.data.results,
      });
    } else {
      // setLoading2(false);
      setLoading(false);
    }
  };
};

export const JobDeleteAPI = (
  data,
  alert,
  history,
  location,
  jobList,
  handleClose,
  collegeId,
  setCallNavbar,
  setLoading
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .post(
        `${BackendBaseApi.SANCHALAN}api/manage_job/job_delete/?college_id=${collegeId}`,
        data,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        checkForToken(error, history, location, alert);
        setLoading(false);
        handleClose();
        throw error;
      });

    if (res.data.success) {
      alert.success(res.data.data.message);
      var arr = [...jobList];
      data.object_id.map((item) => {
        var index = arr.findIndex((item2) => item2.id === item);
        arr.splice(index, 1);
      });
      setCallNavbar(true);
      handleClose();
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
    dispatch({
      type: JOB_DATA,
      payload: arr,
    });
  };
};

export const EJDJobDeleteAPI = (
  data,
  alert,
  history,
  location,
  jobList,
  handleClose,
  collegeId,
  setCallNavbar,
  setLoading
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .post(
        `${BackendBaseApi.SANCHALAN}api/manage_job/ejd_job_delete/?college_id=${collegeId}`,
        data,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        checkForToken(error, history, location, alert);
        handleClose();
        setLoading(false);
        throw error;
      });

    if (res.data.success) {
      alert.success(res.data.data.message);
      var arr = [...jobList];
      data.object_id.map((item) => {
        var index = arr.findIndex((item2) => item2.id === item);
        arr.splice(index, 1);
      });
      dispatch({
        type: JOB_DATA,
        payload: arr,
      });
      setCallNavbar(true);
      handleClose();
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};

export const JobPlacementCycleChangeAPI = (
  data,
  alert,
  history,
  location,
  jobList,
  handleClose,
  setLoader,
  collegeId,
  setSelectedRowsData
) => {
  return async (dispatch) => {
    setLoader(true);
    const res = await axios
      .post(
        `${BackendBaseApi.SANCHALAN}api/manage_job/job_placement_edit/?college_id=${collegeId}`,
        data,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        checkForToken(error, history, location, alert);
        handleClose();
        setLoader(false);
        throw error;
      });

    if (res.data.success) {
      alert.success(res.data.data.message);
      setSelectedRowsData([]);
    } else {
      alert.error("Some Error Occurred!!!");
    }
    handleClose();
    setLoader(false);
  };
};

export const JobEJDRejectedAPI = (
  data,
  alert,
  history,
  location,
  jobList,
  handleClose,
  setLoader,
  collegeId,
  setSelectedRowsData
) => {
  return async (dispatch) => {
    setLoader(true);
    const res = await axios
      .post(
        `${BackendBaseApi.SANCHALAN}api/manage_job/ejd_job_status_change/?college_id=${collegeId}`,
        data,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        checkForToken(error, history, location, alert);
        handleClose();
        setLoader(false);
        throw error;
      });

    if (res.data.success) {
      alert.success(res?.data?.data?.message);
      var arr = [...jobList];
      data.object_id.map((item) => {
        arr.forEach((item2) => {
          if (item2.id === item) item2["ejd_status"] = data.job_status;
        });
      });
      setSelectedRowsData([]);

      dispatch({
        type: JOB_DATA,
        payload: arr,
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    handleClose();
    setLoader(false);
  };
};

export const JobStatusChangeAPI = (
  data,
  alert,
  history,
  location,
  jobList,
  handleClose,
  setLoader,
  collegeId,
  setCallNavbar
) => {
  return async (dispatch) => {
    setLoader(true);
    const res = await axios
      .post(
        `${BackendBaseApi.SANCHALAN}api/manage_job/job_status_change/?college_id=${collegeId}`,
        data,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        checkForToken(error, history, location, alert);
        handleClose();
        throw error;
      });

    if (res.data.success) {
      alert.success(res?.data?.data?.message);
      var arr = [...jobList];
      data.object_id.map((item) => {
        var index = arr.findIndex((item2) => item2.id === item);
        arr.splice(index, 1);
      });
      setCallNavbar(true);
    } else {
      alert.error("Some Error Occurred!!!");
    }
    handleClose();

    dispatch({
      type: JOB_DATA,
      payload: arr,
    });
    setLoader(false);
  };
};

export const AddTeamMemberAPI = (
  data,
  alert,
  history,
  location,
  jobList,
  handleClose,
  setLoader,
  collegeId,
  setSelectedRowsData
) => {
  return async (dispatch) => {
    setLoader(true);
    const res = await axios
      .post(
        `${BackendBaseApi.SANCHALAN}api/manage_job/add_team_member/`,
        data,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        checkForToken(error, history, location, alert);
        if (error?.response?.status !== 401) {
          alert.error(error?.response?.data?.error);
        }
        handleClose();
        throw error;
      });

    if (res.data.success) {
      alert.success(res?.data?.data?.message);
      setSelectedRowsData([]);
    } else {
      alert.error("Some Error Occurred!!!");
    }
    handleClose();

    setLoader(false);
  };
};

export const AddJobToTeamMemberAPI = (
  data,
  alert,
  history,
  location,
  setLoader,
  collegeId,
  fromJobDetails,
  item,
  teamMember,
  allData,
  setTeamMemberListing
) => {
  return async (dispatch) => {
    setLoader(true);
    const res = await axios
      .post(
        `${BackendBaseApi.SANCHALAN}api/manage_job/add_team_member/`,
        data,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        checkForToken(error, history, location, alert);
        if (error?.response?.status !== 401) {
          alert.error(error?.response?.data?.error);
        }
        throw error;
      });

    if (res.data.success) {
      alert.success(res.data.data.message);
      if (fromJobDetails) {
        var arr = [...item];
        arr.push({ team_member_id: teamMember?.id, name: teamMember?.name });
        allData["team_member"] = arr;
        dispatch({
          type: JOB_DETAILS_SIDE_DATA,
          payload: allData,
        });
        setTeamMemberListing(arr);
      }
    } else {
      alert.error("Some Error Occurred!!!");
    }

    setLoader(false);
  };
};

export const SKillsAPI = () => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.PRAVESH}api/education/skills`, {
        withCredentials: true,
      })
      .catch((error) => {
        throw error;
      });

    if (res.data.success) {
      var arr = [...res.data.data.skills];
      arr.forEach((item) => {
        item["id"] = item.skill_id;
        item["name"] = item.skill_name;
      });
      dispatch({ type: SKILLS, payload: arr });
    } else {
    }
  };
};

export const PlacementAccordingToJob = (
  alert,
  history,
  location,
  job_id,
  college_id
) => {
  return async (dispatch) => {
    const res = await axios
      .get(
        BackendBaseApi.SANCHALAN +
          "api/manage_job/job_placement/?job_id=" +
          job_id +
          "&college_id=" +
          college_id,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });

    if (res?.data?.success) {
      dispatch({
        type: PLACEMENT_JOB_TRACK,
        payload: res?.data?.data,
      });
    } else {
    }
  };
};

export const PlacementAccordingToJobPublic = (
  alert,
  history,
  location,
  job_id,
  college_id
) => {
  return async (dispatch) => {
    const res = await axios
      .get(
        BackendBaseApi.SANCHALAN +
          "public/job_placement/?job_id=" +
          job_id +
          "&college_id=" +
          college_id,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });

    if (res?.data?.success) {
      dispatch({
        type: PLACEMENT_JOB_TRACK,
        payload: res?.data?.data,
      });
    } else {
    }
  };
};

export const GetGroupCollegeListAPI = (
  setLoading,
  groupId,
  setNestedColleges,
  setGroups,
  selectedLocations,
  setLocationFilterApplied
) => {
  return async (dispatch) => {
    setLoading(true);
    const stateIds = selectedLocations
      .map((location) => location.state_id)
      .join(",");
    const cityIds = selectedLocations
      .map((location) => location.city_id)
      .join(",");

    let url = "api/manage_job/college-group/?";
    if (stateIds || cityIds) url += `state_id=${stateIds}&city_id=${cityIds}`;
    url += groupId;
    const res = await axios
      .get(BackendBaseApi.SANCHALAN + url, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        throw error;
      });

    if (res.data.success) {
      const formatedData = res?.data?.data?.data.map((input, index) => {
        return {
          id: input?.college_id,
          // is_job_applied: input?.college_job_data?.is_job_applied,
          details: {
            id: input?.college_id || false,
            logo: input?.logo,
            name: input?.name,
            location: input?.address,
            openJobs: input?.job_count,
          },
          checked: false,
          placementCycles: input?.placement_cycle?.map((cycle, cycleIndex) => ({
            name: cycle?.name,
            id: cycle?.id,
            checked: false,
          })),
          error: false,
        };
      });
      setNestedColleges(res?.data?.data?.nested_collges);
      setGroups(res?.data?.data?.groups);
      dispatch({ type: GROUP_COLLEGE_LIST, payload: formatedData });
      setLocationFilterApplied(true);
    } else {
    }
    setLoading(false);
  };
};
