import { Typography } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import * as React from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { themeSelectorFn } from "../../../api/Domain/selector";
import { CallCollegeAPI } from "../../../api/University/APIs/action";
import { ReactComponent as NoData } from "../../../assets/svg/New entries-rafiki.svg";
import {
  entityInfoSelectorFn,
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../../api/SignIn/selector";

export default function UniversityTable({ data, loading }) {
  const theme_change = useSelector(themeSelectorFn);

  const location = useLocation();
  const history = useHistory();
  const alert = useAlert();
  const dispatch = useDispatch();
  const user_details = useSelector((state) => state?.UserDetails?.user_details);

  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const entityInfo = useSelector(entityInfoSelectorFn) || {
    college: "College",
    university: "University",
  };

  const handleSubmit = (id, link) => {
    dispatch(CallCollegeAPI(id, alert, history, location, user_details, link));
  };
  const [pageSize, setPageSize] = React.useState(20);
  const columns = [
    // { field: "id", headerName: "No", width: 100 },

    {
      field: "college_name",
      width: 400,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "155px",
          }}
        >
          {entityInfo?.college}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
            cursor: "pointer",
          }}
        >
          <Typography
            color="primary"
            style={{ fontSize: "14px" }}
            onClick={() => {
              handleSubmit(params?.row?.college_id, "dashboard");
            }}
          >
            {params.row.college_name}
          </Typography>
        </span>
      ),
    },
    {
      field: "visited_company",
      headerName: "Email",
      type: "text",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "30px",
          }}
        >
          Visited Companies
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            cursor: "pointer",
          }}
        >
          <Typography
            color="primary"
            style={{ fontSize: "14px" }}
            onClick={() => {
              handleSubmit(params?.row?.college_id, "/manage-companies");
            }}
          >
            {params.row.visited_company}
          </Typography>
        </span>
      ),
    },
    {
      field: "open_jobs",
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Job Openings
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            cursor: "pointer",
          }}
        >
          <Typography
            color="primary"
            style={{ fontSize: "14px" }}
            onClick={() => {
              handleSubmit(params?.row?.college_id, "/manage-jobs");
            }}
          >
            {params.row.open_jobs}
          </Typography>
        </span>
      ),
    },
    {
      field: "registered_students",
      type: "text",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "20px",
          }}
        >
          Registered Students
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            cursor: "pointer",
          }}
        >
          <Typography
            color="primary"
            style={{ fontSize: "14px" }}
            onClick={() => {
              handleSubmit(params?.row?.college_id, "/manage-students");
            }}
          >
            {params.row.registered_students}
          </Typography>
        </span>
      ),
    },
    {
      field: "placed_students",
      type: "text",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "30px",
          }}
        >
          Placed Students
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            cursor: "pointer",
          }}
        >
          <Typography
            color="primary"
            style={{ fontSize: "14px" }}
            onClick={() => {
              handleSubmit(params?.row?.college_id, "/manage-students");
            }}
          >
            {params.row.placed_students}
          </Typography>
        </span>
      ),
    },

    {
      field: "ctc",
      type: "text",
      width: 180,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "25px",
          }}
        >
          Average CTC
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.ctc}
        </span>
      ),
    },
  ];

  return (
    <div style={{ height: 300, width: "100%" }}>
      <DataGrid
        style={{
          borderRadius: "8px",
          backgroundColor: "#fff",
          border: "none",
        }}
        rows={data}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 15, 20]}
        pagination
        // paginationMode="server"
        {...data}
        disableSelectionOnClick
        loading={loading}
        componentsProps={{
          pagination: {
            style: {
              display: "flex",
              justifyContent: "center",
              margin: "auto",
            },
          },
        }}
        components={{
          NoRowsOverlay: () => (
            <>
              <br /> <br />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "auto",
                  paddingTop: 30,
                }}
              >
                <NoData
                  height="180px"
                  width="180px"
                  alt="404 Error"
                  fill={theme_change?.main ? theme_change?.main : "#007BFF"}
                />

                <span align="center" style={{ fontSize: 14, color: "#6c757d" }}>
                  <b> {"No Data Available"}</b>
                </span>
              </div>
            </>
          ),
        }}
      />
    </div>
  );
}
