//Imports
import axios from "axios";
import { BackendBaseApi } from "../../constants/constants";
import { userDetailsApi } from "../SignIn/action";
//Exports
export const FORGOT_PASSWORD_API = "FORGOT_PASSWORD_API";

//Forgot Password Api Call Action
export const ForgotPasswordAPI = (
  data,
  setLoading,
  setErrors,
  alert,
  history
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .post(BackendBaseApi.PRAVESH + "api/saas/password_generate/", {
        email: data,
      })
      .catch((error) => {
        setLoading(false);
        setErrors("Please Enter valid Email Address");
        throw error;
      });
    if (res.data.success) {
      alert.success(
        "Reset Link Sent Successfully. Please check your main to change your Password"
      );
      history.push({
        pathname: "/reset-password-link-generated",
        email: data,
        state: 1022,
      });
    } else {
      alert.error(res.data.error);
    }
    setLoading(false);

    dispatch({
      type: FORGOT_PASSWORD_API,
      payload: res.data.data,
    });
  };
};

export const ForgotPasswordAgainAPI = (data, alert) => {
  return async (dispatch) => {
    const res = await axios
      .post(BackendBaseApi.PRAVESH + "api/saas/password_generate/", {
        email: data,
      })
      .catch((error) => {
        alert.error("Some Error Occurred!!");

        throw error;
      });
    if (res.data.success) {
      alert.success(
        "Reset Link Sent Successfully. Please check your main to change your Password"
      );
    } else {
      alert.error(res.data.error);

      //   window.alert("Login not Successfull!!");
    }

    dispatch({
      type: FORGOT_PASSWORD_API,
      payload: res.data.data,
    });
  };
};

export const ResetPasswordAPI = (data, alert, setLoading, setNextStep) => {
  return async (dispatch) => {
    setLoading(true);

    const res = await axios
      .post(BackendBaseApi.PRAVESH + "api/saas/reset_password/", data)
      .catch((error) => {
        alert.error("Some Error Occurred!!");
        setLoading(false);

        throw error;
      });
    if (res.data.success) {
      alert.success(
        "Reset Link Sent Successfully. Please check your email to change your Password"
      );
      setNextStep(1);
    } else {
      alert.error(res.data.error);

      //   window.alert("Login not Successfull!!");
    }
    setLoading(false);
  };
};

export const ResetNewPasswordAPI = (alert, setLoading, history, code) => {
  return async (dispatch) => {
    setLoading(true);

    const res = await axios
      .get(
        BackendBaseApi.PRAVESH + "api/saas/reset_new_password/?code=" + code,
        { withCredentials: true }
      )
      .catch((error) => {
        if (error.response.status === 422) {
          alert.error(error.response.data.error);
          history.push("/");
        }
        setLoading(false);

        throw error;
      });
    if (res.data.success) {
      console.log(res.data);
    } else {
      alert.error(res.data.error);
      history.push("/");
      //   window.alert("Login not Successfull!!");
    }
    setLoading(false);
  };
};

export const CreateFirstTimePasswordAPI = (
  data,
  token,
  alert,
  setLoading,
  history,
  isEducationGroup,
  location
) => {
  return async (dispatch) => {
    setLoading(true);

    const res = await axios
      .post(
        BackendBaseApi.PRAVESH +
          "api/saas/change_first_password/?code=" +
          token,
        data,
        { withCredentials: true }
      )
      .catch((error) => {
        alert.error("Some Error Occurred!!");
        setLoading(false);
        throw error;
      });
    if (res.data.success) {
      alert.success("New Password Created Successfully!!");
      // if (!isEducationGroup) history.push({ pathname: "dashboard" });
      // else history.push({ pathname: "/dashboard" });
      const collegeViewCollegeId = localStorage.getItem("college_id") || null;

      dispatch(
        userDetailsApi(
          collegeViewCollegeId,
          history,
          location,
          alert,
          false,
          true
        )
      );
    } else {
      alert.error(res.data.error);
    }
    setLoading(false);
  };
};

export const ChangeCurrentPasswordAPI = (data, alert, setLoading, setState) => {
  return async (dispatch) => {
    setLoading(true);

    const res = await axios
      .post(BackendBaseApi.PRAVESH + "api/saas/change_password/", data, {
        withCredentials: true,
      })
      .catch((error) => {
        alert.error("Some Error Occurred!!");
        setLoading(false);
        throw error;
      });
    if (res.data.success) {
      alert.success("New Password Created Successfully!!");
      setState({ password: "", confPassword: "", currentPassword: "" });
    } else {
      alert.error(res.data.error);
    }
    setLoading(false);
  };
};
