import { Button, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  PlacementListAPI,
  PlacementListEndedAPI,
  TeamMemberListAPI,
} from "../../api/Common/action";
import {
  getJobsAPI,
  getJobsAPI2,
  JobNavbarAPI,
} from "../../api/JobPost/action";
import {
  collegeDetailSelectorFn,
  collegeIdFn,
  userRolesActionsFn,
} from "../../api/SignIn/selector";
import CustomCircularProgress from "../../components/CircularProgressBar";
import JobTab from "../../components/Header/JobTab/index";
import DropDownButton from "../../components/Input/DropDownButton";
import NoDataFuncNew from "../../components/NoDataSVG/index2";
import { generateCsv } from "../../utility/commaSeparatedStrings";
import axios from "axios";
import { getColumnString } from "../../constants/constants";
import { sendExportLink } from "../../constants/ApiCall";
import { praveshAxiosGetReq } from "../../api/BaseApi/apiBase";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
    backgroundColor: theme.palette.primary.secondary,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
  grid: {
    display: "flex",
    marginTop: "10px",
    marginBottom: "10px",
    justifyContent: "space-between",
    flexDirection: "row",
  },
}));

export default function JobManage() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [applicationStatusLoading, setApplicationStatusLoading] =
    useState(true);

  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [callNavbar, setCallNavbar] = useState(false);
  const [filters, setFilters] = useState({
    job_status: "OPEN",
    job_title: null,
    company_name: null,
    job_type: null,
    created: null,
    ctc_min: null,
    ctc_max: null,
    job_stage_id: null,
  });

  useEffect(() => {
    if (location?.flag === "from-overview")
      setFilters({ ...filters, job_status: "UPCOMING" });
  }, [location]);

  const initialBackendDrivenFilter = {
    outerFilters: {
      job_search: null,
      company_search: null,
    },
    innerFilters: {},
  };
  const [backendDrivenFilter, setBackendDrivenFilter] = useState(
    initialBackendDrivenFilter
  );

  const [jobTitleSearch, setJobTitleSearch] = useState(false);
  const [companyNameSearch, setCompanyNameSearch] = useState(false);
  const jobList = useSelector((state) => state?.JobData?.details);
  const [applyClickedCTC, setApplyClickedCTC] = useState(false);

  const [jobTypeClick, setJobTypeClick] = useState(false);
  const [placementSession, setPlacementSession] = useState(null);

  const [firstApiCall, setFirstApiCall] = useState(false);
  const collegeDetails = useSelector(collegeDetailSelectorFn);
  const collegeId = useSelector(collegeIdFn);
  const userId = collegeDetails?.user_id;
  const [companyList, setCompanyList] = useState([]);
  const [companyListTotalCount, setCompanyListTotalCount] = useState(0);

  const [companyListTable, setCompanyListTable] = useState([]);

  const initialPageData = { currentPage: 0, pageSize: 20 };
  const [pageData, setPageData] = useState(initialPageData);

  useEffect(() => {
    if (
      companyList.length / pageData.pageSize !== pageData.currentPage + 1 &&
      !loading
    ) {
      setCompanyList((prev) => {
        // const remainder = companyList.length % pageData.pageSize;
        // console.log("companyList remainder", remainder);
        // let arr = prev;
        // if (remainder) {
        //   arr = prev.slice(0, -remainder);
        // }

        return [...prev, ...(jobList || [])];
      });
      // setCompanyListTotalCount(jobList?.count);
    }
    // setFirstCallDone(true);
    // setFirstApiCall(true);
  }, [jobList]);

  const [filterData, setFilterData] = useState([]);
  const [filterLoading, setFilterLoader] = useState(true);

  useEffect(() => {
    const getFilters = async () => {
      setFilterLoader(true);
      let endPoint = `api/saas/college/manage_job/get/filters/?${checkJobStatus()}`;
      endPoint = endPoint.replace(/^&+/, "");
      endPoint = endPoint.replace(/&+$/, "");
      try {
        const res = await praveshAxiosGetReq(endPoint);
        const data = res?.data?.data;
        if (res?.data?.success) {
          setFilterData(data);
        }
        setFilterLoader(false);
      } catch (error) {
        throw error;
        setFilterLoader(false);
      }
    };
    getFilters();
    setBackendDrivenFilter(initialBackendDrivenFilter);
  }, [filters.job_status]);

  const createQueryStringOuterFilter = (paramsObject) => {
    let queryParams = "";
    Object.keys(paramsObject).forEach((key) => {
      const value = paramsObject[key];

      if (value != null && value != undefined && value != "") {
        if (value instanceof Date) {
          queryParams += `${key}=${value.getDate()}/${
            value.getMonth() + 1
          }/${value.getFullYear()}&`;
        } else {
          if (Array.isArray(value)) {
            queryParams += `${key}=${value.join(",")}&`;
          } else {
            queryParams += `${key}=${value}&`;
          }
        }
      }
    });
    return queryParams;
  };

  const formatDate = (date) => {
    if (date instanceof Date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
    return "";
  };

  const createQueryStringInnerFilter = (paramsObject) => {
    let queryParams = "";
    Object.keys(paramsObject).forEach((key) => {
      const items = paramsObject[key];
      if (items) {
        const validItems = items.filter(
          (item) =>
            item.value != null &&
            item.value !== undefined &&
            item.value !== "" &&
            !(Array.isArray(item.value) && item.value.length === 0) &&
            !(
              typeof item.value === "object" &&
              !Array.isArray(item.value) &&
              !(item.value instanceof Date) &&
              Object.keys(item.value).length === 0
            )
        );
        if (validItems.length) {
          if (key == "ctc") {
            const { min, max } = validItems[0]?.value;

            if (min && max) {
              queryParams += `ctc_min=${Number(min) * 100000}&ctc_max=${
                Number(max) * 100000
              }`;
            } else if (min) {
              queryParams += `ctc_min=${Number(min) * 100000}`;
            } else if (max) {
              queryParams += `ctc_max=${Number(max) * 100000}`;
            }
          } else if (key === "action_date" || key === "drive_date") {
            const { start_date, end_date } = validItems[0]?.value;
            queryParams += `${key}=${start_date}-${end_date}`;
          } else {
            let values = validItems
              .map((item) => {
                if (item.value instanceof Date) {
                  return formatDate(item.value);
                } else if (Array.isArray(item.value)) {
                  return item.value.join(",");
                } else {
                  return item.value;
                }
              })
              .join(",");

            if (values) {
              queryParams += `${key}=` + values;
            }
          }

          queryParams += "&";
        }
      }
    });

    queryParams = queryParams.slice(0, -1);

    return queryParams;
  };
  useEffect(() => {
    // let CancelToken = axios.CancelToken;
    // let source = CancelToken.source();
    // let CancelToken2 = axios.CancelToken;
    // let source2 = CancelToken2.source();
    if (
      firstCallDone &&
      collegeId
      // firstApiCall
      // && pageData.currentPage
    ) {
      setPageData(initialPageData);
      setCompanyList([]);

      //   let params = `${checkJobStatus()}${createQueryStringOuterFilter(
      //     backendDrivenFilter?.outerFilters
      //   )}${createQueryStringInnerFilter(
      //     backendDrivenFilter?.innerFilters
      //   )}${checkPlacementSesion()}`;
      //   params = params.replace(/^&+/, "");
      //   params = params.replace(/&+$/, "");

      //   let url = `api/manage_job/view/?college_id=${collegeId}&${params}`;

      //   dispatch(
      //     getJobsAPI2(
      //       setLoading,
      //       alert,
      //       history,
      //       location,
      //       url,
      //       setJobTitleSearch,
      //       setCompanyNameSearch,
      //       true,
      //       setJobTypeClick,
      //       setApplyClickedCTC,
      //       setFirstApiCall,
      //       setCompanyList,
      //       source
      //     )
      //   );

      //   let url_navbar = `api/manage_job/job_navbar/?college_id=${collegeId}&${params}`;
      //   url_navbar = url_navbar.replace(/&$/, "");
      //   dispatch(JobNavbarAPI(url_navbar, source2));
      // }
      // return () => {
      //   source.cancel("Cancelled due to stale request");
      //   source2.cancel("Cancelled due to stale request");
    }
  }, [backendDrivenFilter.innerFilters]);

  useEffect(() => {
    // let CancelToken = axios.CancelToken;
    // let source = CancelToken.source();
    // let CancelToken2 = axios.CancelToken;
    // let source2 = CancelToken2.source();
    // console.log("useeffect 2", firstApiCall);

    if (
      firstCallDone &&
      collegeId &&
      // firstApiCall &&
      (jobTitleSearch || companyNameSearch)
      //  && pageData.currentPage
    ) {
      setPageData(initialPageData);
      setCompanyList([]);

      //   let params = `${checkJobStatus()}${createQueryStringOuterFilter(
      //     backendDrivenFilter?.outerFilters
      //   )}${createQueryStringInnerFilter(
      //     backendDrivenFilter?.innerFilters
      //   )}${checkPlacementSesion()}`;
      //   params = params.replace(/^&+/, "");
      //   params = params.replace(/&+$/, "");

      //   let url = `api/manage_job/view/?college_id=${collegeId}&${params}`;

      //   dispatch(
      //     getJobsAPI2(
      //       setLoading,
      //       alert,
      //       history,
      //       location,
      //       url,
      //       setJobTitleSearch,
      //       setCompanyNameSearch,
      //       true,
      //       setJobTypeClick,
      //       setApplyClickedCTC,
      //       setFirstApiCall,
      //       setCompanyList,
      //       source
      //     )
      //   );

      //   let url_navbar = `api/manage_job/job_navbar/?college_id=${collegeId}&${params}`;
      //   url_navbar = url_navbar.replace(/&$/, "");
      //   dispatch(JobNavbarAPI(url_navbar, source2));
      // }
      // return () => {
      //   source.cancel("Cancelled due to stale request");
      //   source2.cancel("Cancelled due to stale request");
    }
  }, [jobTitleSearch, companyNameSearch]);

  const [firstCallDone, setFirstCallDone] = useState(false);
  useEffect(() => {
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    let CancelToken2 = axios.CancelToken;
    let source2 = CancelToken2.source();

    if (
      placementSession &&
      collegeId &&
      // firstApiCall &&
      // pageData.currentPage &&
      // Math.ceil(companyList.length / pageData.pageSize) === pageData.currentPage
      companyList.length / pageData.pageSize === pageData.currentPage
    ) {
      let params = `${createQueryStringOuterFilter(
        backendDrivenFilter?.outerFilters
      )}${createQueryStringInnerFilter(backendDrivenFilter?.innerFilters)}`;
      params = params.replace(/^&+/, "");
      params = params.replace(/&+$/, "");

      params += `${checkPlacementSesion()}${checkJobStatus()}`;
      params = params.replace(/^&+/, "");
      params = params.replace(/&+$/, "");

      let url = `api/manage_job/view/paginated/?college_id=${collegeId}&page=${
        pageData.currentPage + 1
      }&page_size=${pageData.pageSize}&${params}`;

      dispatch(
        getJobsAPI2(
          setLoading,
          alert,
          history,
          location,
          url,
          setJobTitleSearch,
          setCompanyNameSearch,
          true,
          setApplyClickedCTC,
          setFirstApiCall,
          setCompanyList,
          source,
          setCompanyListTotalCount
        )
      )
        .then(() => {
          setFirstCallDone(true);
        })
        .catch((error) => {
          console.error("Fetch data failed:", error);
        });

      if (!companyList.length) {
        let url_navbar = `api/manage_job/job_navbar/?current_tab=${filters?.job_status}&college_id=${collegeId}&${params}`;
        url_navbar = url_navbar.replace(/&$/, "");
        dispatch(JobNavbarAPI(url_navbar, source2));
      }
    }
    return () => {
      source.cancel("Cancelled due to stale request");
      source2.cancel("Cancelled due to stale request");
    };
  }, [pageData, placementSession]);

  const checkJobStatus = () => {
    if (filters?.job_status === "E-JNF") return `&ejd_response=${1}`;
    else if (filters?.job_status === "REJECTED")
      return `&ejd_response=${1}&ejd_rejected=${1}`;
    else if (
      filters?.job_status &&
      filters?.job_status !== "E-JNF" &&
      filters?.job_status &&
      filters?.job_status !== "REJECTED"
    )
      return `&job_status=${filters?.job_status}`;
    else return "";
  };

  const checkJobTitle = () => {
    if (filters?.job_title) return `&job_search=${filters?.job_title}`;
    else return "";
  };
  const checkCompanyName = () => {
    if (filters?.company_name)
      return `&company_search=${filters?.company_name}`;
    else return "";
  };
  const checkJobType = () => {
    if (filters?.job_type) return `&job_type=${filters?.job_type}`;
    else return "";
  };

  const checkDate = () => {
    if (filters.created)
      return `&start_date=${new Date(filters.created)
        .toISOString()
        .substring(0, 10)}`;
    else return ``;
  };

  const checkPlacementSesion = () => {
    if (
      placementSession &&
      placementSession.id !== "All" &&
      // filters?.job_status !== "E-JD"
      backendDrivenFilter?.innerFilters?.job_status?.[0]?.value !== "E-JD"
    )
      return `&placement_session=${placementSession.id}`;
    else return ``;
  };

  const ctcFilter = () => {
    if (filters.ctc_min && filters?.ctc_max)
      return `&ctc_min=${Number(filters.ctc_min) * 100000}&ctc_max=${
        Number(filters.ctc_max) * 100000
      }`;
    else if (filters.ctc_min)
      return `&ctc_min=${Number(filters.ctc_min) * 100000}`;
    else if (filters.ctc_max)
      return `&ctc_max=${Number(filters.ctc_max) * 100000}`;
    else return ``;
  };
  const checkJobStageId = () => {
    if (filters?.job_stage_id) return `&job_stage_id=${filters?.job_stage_id}`;
    else return "";
  };

  // useEffect(() => {
  //   let CancelToken = axios.CancelToken;
  //   let source = CancelToken.source();
  //   let CancelToken2 = axios.CancelToken;
  //   let source2 = CancelToken2.source();

  //   if (firstApiCall) {
  //     console.log("calling useeffect 2");
  //     const url = `api/manage_job/view/?college_id=${collegeId}${checkJobStatus()}${checkPlacementSesion()}`;
  //     dispatch(
  //       getJobsAPI(
  //         setLoading,
  //         alert,
  //         history,
  //         location,
  //         url,
  //         setJobTitleSearch,
  //         setCompanyNameSearch,
  //         true,
  //         setJobTypeClick,
  //         setApplyClickedCTC,
  //         setFirstApiCall,
  //         setCompanyList,
  //         source
  //       )
  //     );
  //     const url_navbar = `api/manage_job/job_navbar/?college_id=${collegeId}${checkJobStatus()}${checkPlacementSesion()}`;
  //     dispatch(JobNavbarAPI(url_navbar, source2));
  //   }
  //   return () => {
  //     source.cancel("Cancelled due to stale request");
  //     source2.cancel("Cancelled due to stale request");
  //   };
  // }, []);

  useEffect(() => {
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    // console.log("useeffect 4", firstApiCall)

    if (collegeId && callNavbar) {
      let params = `${createQueryStringOuterFilter(
        backendDrivenFilter?.outerFilters
      )}${createQueryStringInnerFilter(backendDrivenFilter?.innerFilters)}`;
      params = params.replace(/^&+/, "");
      params = params.replace(/&+$/, "");

      params += `${checkPlacementSesion()}${checkJobStatus()}`;
      params = params.replace(/^&+/, "");
      params = params.replace(/&+$/, "");

      let url_navbar = `api/manage_job/job_navbar/?current_tab=${filters?.job_status}&college_id=${collegeId}&${params}`;
      url_navbar = url_navbar.replace(/&$/, "");
      // const url_navbar = `api/manage_job/job_navbar/?college_id=${collegeId}${checkJobStatus()}${checkJobTitle()}${checkCompanyName()}${checkJobType()}${checkDate()}${checkPlacementSesion()}${ctcFilter()}${checkJobStageId()}`;
      dispatch(JobNavbarAPI(url_navbar, source));
    }
    return () => {
      source.cancel("Cancelled due to stale request");
    };
  }, [callNavbar]);

  // useEffect(() => {
  //   let CancelToken = axios.CancelToken;
  //   let source = CancelToken.source();
  //   let CancelToken2 = axios.CancelToken;
  //   let source2 = CancelToken2.source();

  //   if (jobTitleSearch) {
  //     console.log("calling useeffect 4");
  //     const url = `api/manage_job/view/?college_id=${collegeId}${checkJobStatus()}${checkJobTitle()}${checkCompanyName()}${checkJobType()}${checkDate()}${checkPlacementSesion()}${ctcFilter()}${checkJobStageId()}`;

  //     dispatch(
  //       getJobsAPI(
  //         setLoading,
  //         alert,
  //         history,
  //         location,
  //         url,
  //         setJobTitleSearch,
  //         setCompanyNameSearch,
  //         true,
  //         setJobTypeClick,
  //         setApplyClickedCTC,
  //         setFirstApiCall,
  //         setCompanyList,
  //         source2
  //       )
  //     );

  //     const url_navbar = `api/manage_job/job_navbar/?college_id=${collegeId}${checkJobStatus()}${checkJobTitle()}${checkCompanyName()}${checkJobType()}${checkDate()}${checkPlacementSesion()}${ctcFilter()}${checkJobStageId()}`;
  //     dispatch(JobNavbarAPI(url_navbar, source));
  //   }
  //   return () => {
  //     source.cancel("Cancelled due to stale request");
  //     source2.cancel("Cancelled due to stale request");
  //   };
  // }, [jobTitleSearch]);

  // useEffect(() => {
  //   let CancelToken = axios.CancelToken;
  //   let source = CancelToken.source();
  //   let CancelToken2 = axios.CancelToken;
  //   let source2 = CancelToken2.source();

  //   if (companyNameSearch) {
  //     console.log("calling useeffect 5");
  //     const url = `api/manage_job/view/?college_id=${collegeId}${checkJobStatus()}${checkJobTitle()}${checkCompanyName()}${checkJobType()}${checkDate()}${checkPlacementSesion()}${ctcFilter()}${checkJobStageId()}`;
  //     dispatch(
  //       getJobsAPI(
  //         setLoading,
  //         alert,
  //         history,
  //         location,
  //         url,
  //         setJobTitleSearch,
  //         setCompanyNameSearch,
  //         true,
  //         setJobTypeClick,
  //         setApplyClickedCTC,
  //         setFirstApiCall,
  //         setCompanyList,
  //         source
  //       )
  //     );
  //     const url_navbar = `api/manage_job/job_navbar/?college_id=${collegeId}${checkJobStatus()}${checkJobTitle()}${checkCompanyName()}${checkJobType()}${checkDate()}${checkPlacementSesion()}${ctcFilter()}${checkJobStageId()}`;
  //     dispatch(JobNavbarAPI(url_navbar, source2));
  //   }
  //   return () => {
  //     source.cancel("Cancelled due to stale request");
  //     source2.cancel("Cancelled due to stale request");
  //   };
  // }, [companyNameSearch]);

  // useEffect(() => {
  //   let CancelToken = axios.CancelToken;
  //   let source = CancelToken.source();
  //   let CancelToken2 = axios.CancelToken;
  //   let source2 = CancelToken2.source();

  //   if (firstApiCall) {
  //     const url = `api/manage_job/view/?college_id=${collegeId}${checkJobStatus()}${checkJobTitle()}${checkCompanyName()}${checkJobType()}${checkDate()}${checkPlacementSesion()}${ctcFilter()}${checkJobStageId()}`;

  //     dispatch(
  //       getJobsAPI(
  //         setLoading,
  //         alert,
  //         history,
  //         location,
  //         url,
  //         setJobTitleSearch,
  //         setCompanyNameSearch,
  //         true,
  //         setJobTypeClick,
  //         setApplyClickedCTC,
  //         setFirstApiCall,
  //         setCompanyList,
  //         source
  //       )
  //     );

  //     const url_navbar = `api/manage_job/job_navbar/?college_id=${collegeId}${checkJobStatus()}${checkJobTitle()}${checkCompanyName()}${checkJobType()}${checkDate()}${checkPlacementSesion()}${ctcFilter()}${checkJobStageId()}`;
  //     dispatch(JobNavbarAPI(url_navbar, source2));
  //   }

  //   return () => {
  //     source.cancel("Cancelled due to stale request");
  //     source2.cancel("Cancelled due to stale request");
  //   };
  // }, [filters.job_type, filters.created]);

  // useEffect(() => {
  //   let CancelToken = axios.CancelToken;
  //   let source = CancelToken.source();
  //   let CancelToken2 = axios.CancelToken;
  //   let source2 = CancelToken2.source();

  //   if (firstApiCall) {
  //     console.log("calling useeffect 6");
  //     const url = `api/manage_job/view/?college_id=${collegeId}${checkJobStatus()}${checkJobTitle()}${checkCompanyName()}${checkJobType()}${checkDate()}${checkPlacementSesion()}${ctcFilter()}${checkJobStageId()}`;

  //     dispatch(
  //       getJobsAPI(
  //         setLoading,
  //         alert,
  //         history,
  //         location,
  //         url,
  //         setJobTitleSearch,
  //         setCompanyNameSearch,
  //         true,
  //         setJobTypeClick,
  //         setApplyClickedCTC,
  //         setFirstApiCall,
  //         setCompanyList,
  //         source
  //       )
  //     );
  //     const url_navbar = `api/manage_job/job_navbar/?college_id=${collegeId}${checkJobStatus()}${checkJobTitle()}${checkCompanyName()}${checkJobType()}${checkDate()}${checkPlacementSesion()}${ctcFilter()}${checkJobStageId()}`;
  //     dispatch(JobNavbarAPI(url_navbar, source2));
  //   }
  //   return () => {
  //     source.cancel("Cancelled due to stale request");
  //     source2.cancel("Cancelled due to stale request");
  //   };
  // }, [filters.job_stage_id, filters.job_status]);

  useEffect(() => {
    // let CancelToken = axios.CancelToken;
    // let source = CancelToken.source();
    // let CancelToken2 = axios.CancelToken;
    // let source2 = CancelToken2.source();

    if (
      firstCallDone &&
      collegeId &&
      placementSession
      // && pageData.currentPage
    ) {
      setPageData(initialPageData);
      setCompanyList([]);

      //   let params = `&${checkJobStatus()}${createQueryStringOuterFilter(
      //     backendDrivenFilter?.outerFilters
      //   )}${createQueryStringInnerFilter(
      //     backendDrivenFilter?.innerFilters
      //   )}${checkPlacementSesion()}`;
      //   params = params.replace(/^&+/, "");
      //   params = params.replace(/&+$/, "");

      //   let url = `api/manage_job/view/?college_id=${collegeId}&${params}`;
      //   url = url.replace(/&$/, "");

      //   dispatch(
      //     getJobsAPI2(
      //       setLoading,
      //       alert,
      //       history,
      //       location,
      //       url,
      //       setJobTitleSearch,
      //       setCompanyNameSearch,
      //       true,
      //       setJobTypeClick,
      //       setApplyClickedCTC,
      //       setFirstApiCall,
      //       setCompanyList,
      //       source
      //     )
      //   );

      //   let url_navbar = `api/manage_job/job_navbar/?college_id=${collegeId}&${params}`;
      //   url_navbar = url_navbar.replace(/&$/, "");
      //   dispatch(JobNavbarAPI(url_navbar, source2));
      // }
      // return () => {
      //   source.cancel("Cancelled due to stale request");
      //   source2.cancel("Cancelled due to stale request");
    }
  }, [placementSession]);

  // useEffect(() => {
  //   let CancelToken = axios.CancelToken;
  //   let source = CancelToken.source();
  //   let CancelToken2 = axios.CancelToken;
  //   let source2 = CancelToken2.source();

  //   if (applyClickedCTC) {
  //     console.log("calling useeffect 8");
  //     const url = `api/manage_job/view/?college_id=${collegeId}${checkJobStatus()}${checkJobTitle()}${checkCompanyName()}${checkJobType()}${checkDate()}${checkPlacementSesion()}${ctcFilter()}${checkJobStageId()}`;
  //     dispatch(
  //       getJobsAPI(
  //         setLoading,
  //         alert,
  //         history,
  //         location,
  //         url,
  //         setJobTitleSearch,
  //         setCompanyNameSearch,
  //         true,
  //         setJobTypeClick,
  //         setApplyClickedCTC,
  //         setFirstApiCall,
  //         setCompanyList,
  //         source
  //       )
  //     );

  //     const url_navbar = `api/manage_job/job_navbar/?college_id=${collegeId}${checkJobStatus()}${checkJobTitle()}${checkCompanyName()}${checkJobType()}${checkDate()}${checkPlacementSesion()}${ctcFilter()}${checkJobStageId()}`;
  //     dispatch(JobNavbarAPI(url_navbar, source2));
  //   }
  //   return () => {
  //     source.cancel("Cancelled due to stale request");
  //     source2.cancel("Cancelled due to stale request");
  //   };
  // }, [applyClickedCTC]);

  // useEffect(() => {
  //   let CancelToken = axios.CancelToken;
  //   let source = CancelToken.source();
  //   let CancelToken2 = axios.CancelToken;
  //   let source2 = CancelToken2.source();

  //   if (jobTypeClick) {
  //     console.log("calling useeffect 9");
  //     const url = `api/manage_job/view/?college_id=${collegeId}${checkJobStatus()}${checkJobTitle()}${checkCompanyName()}${checkJobType()}${checkDate()}${checkPlacementSesion()}${ctcFilter()}${checkJobStageId()}`;
  //     dispatch(
  //       getJobsAPI(
  //         setLoading,
  //         alert,
  //         history,
  //         location,
  //         url,
  //         setJobTitleSearch,
  //         setCompanyNameSearch,
  //         true,
  //         setJobTypeClick,
  //         setApplyClickedCTC,
  //         setFirstApiCall,
  //         setCompanyList,
  //         source
  //       )
  //     );

  //     const url_navbar = `api/manage_job/job_navbar/?college_id=${collegeId}${checkJobStatus()}${checkJobTitle()}${checkCompanyName()}${checkJobType()}${checkDate()}${checkPlacementSesion()}${ctcFilter()}${checkJobStageId()}`;
  //     dispatch(JobNavbarAPI(url_navbar, source2));
  //   }
  //   return () => {
  //     source.cancel("Cancelled due to stale request");
  //     source2.cancel("Cancelled due to stale request");
  //   };
  // }, [jobTypeClick]);

  const [loadingPlacement, setLoadingPlacement] = useState(true);

  const jobNavbar = useSelector((state) => state?.JobData?.navbar);
  useEffect(() => {
    if (collegeId) {
      dispatch(
        PlacementListAPI(
          alert,
          history,
          location,
          setLoadingPlacement,
          collegeId
        )
      );
      dispatch(PlacementListEndedAPI(alert, history, location, collegeId));
      dispatch(TeamMemberListAPI(alert, history, location, collegeId, userId));
    }
    // if (!jobNavbar) dispatch(JobNavbarAPI());
  }, [collegeId]);

  const placementSessionList = useSelector(
    (state) => state.commonData.placementList
  );
  const teamMemberList = useSelector((state) => state?.commonData?.teamMember);

  const checkPlacementName = (id) => {
    var name = "";
    placementSessionList.map((item) => {
      if (item.id === id) name = item.name;
    });
    return name;
  };

  useEffect(() => {
    if (placementSessionList?.length > 0) {
      if (location.search.includes("placement_cycle")) {
        var placement_name = checkPlacementName(
          location?.search.replace("?placement_cycle=", "")
        );
        setPlacementSession({
          id: location?.search.replace("?placement_cycle=", ""),
          name: placement_name,
        });
      } else {
        setPlacementSession({ id: "All", name: "All Placement Cycles" });
      }
    }
  }, [location, placementSessionList?.length]);

  // const [tempJobList, setTempJobList] = useState([]);

  // useEffect(() => {
  //   if (jobList?.length > 0) {
  //     var arr = [...jobList];
  //     let new_arr = [];
  //     arr.forEach((item) => {
  //       var a = [...item?.placement_cycle_list];
  //       console.log("aaaaa", a);
  //       var n = a.filter((i) => i.is_selected);
  //       new_arr.push({
  //         job_id: item?.job_id,
  //         job_title: item?.job_title,
  //         company: item?.company,
  //         job_type: item?.job_type,
  //         ctc: item?.ctc,
  //         vacancies: item?.vacancies,
  //         applicants: item?.applicants,
  //         job_creation_date: item?.created,
  //         application_start_date: item?.start_date,
  //         application_end_date: item?.end_date,
  //         placement_cycles: generateCsv(n, "name"),
  //         last_updated_by: item?.last_updated_by,
  //         reason: item?.reason,
  //         update_time: item?.update_time,
  //       });
  //     });
  //     setTempJobList(new_arr);
  //   }
  // }, [jobList?.length]);

  // useEffect(() => {
  //   if (selectedRowsData?.length > 0) {
  //     let arr = [...selectedRowsData];
  //     let new_arr = [];
  //     var obj = {};
  //     arr.forEach((item) => {
  //       var a = [...item?.placement_cycle_list];
  //       var n = a.filter((i) => i?.is_selected);
  //       new_arr.push({
  //         job_id: item?.job_id,
  //         job_title: item?.job_title,
  //         company: item?.company,
  //         job_type: item?.job_type,
  //         ctc: item?.ctc,
  //         vacancies: item?.vacancies,
  //         applicants: item?.applicants,
  //         job_creation_date: item?.created,
  //         application_start_date: item?.start_date,
  //         application_end_date: item?.end_date,
  //         placement_cycles: generateCsv(n, "name"),
  //         last_updated_by: item?.last_updated_by,
  //         reason: item?.reason,
  //         update_time: item?.update_time,
  //       });
  //     });
  //     setTempJobList(new_arr);
  //   } else {
  //     if (jobList?.length > 0) {
  //       var arr = [...jobList];
  //       let new_arr = [];
  //       var obj = {};
  //       arr.forEach((item) => {
  //         var a = [...item?.placement_cycle_list];
  //         var n = a.filter((i) => i.is_selected);
  //         new_arr.push({
  //           job_id: item?.job_id,
  //           job_title: item?.job_title,
  //           company: item?.company,
  //           job_type: item?.job_type,
  //           ctc: item?.ctc,
  //           vacancies: item?.vacancies,
  //           applicants: item?.applicants,
  //           job_creation_date: item?.created,
  //           application_start_date: item?.start_date,
  //           application_end_date: item?.end_date,
  //           placement_cycles: generateCsv(n, "name"),
  //           last_updated_by: item?.last_updated_by,
  //           reason: item?.reason,
  //           update_time: item?.update_time,
  //         });
  //       });
  //       setTempJobList(new_arr);
  //     }
  //   }
  // }, [selectedRowsData?.length]);

  const userRolesPermission = useSelector(userRolesActionsFn);

  const [selectedColumnFields, setSelectedColumnFields] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);

  const handleExport = () => {
    if (selectedColumnFields?.length > 0) {
      const columnStrings = getColumnString(selectedColumnFields);
      let params = `${createQueryStringOuterFilter(
        backendDrivenFilter?.outerFilters
      )}${createQueryStringInnerFilter(
        backendDrivenFilter?.innerFilters
      )}${checkPlacementSesion()}`;
      params = params.replace(/^&+/, "");
      params = params.replace(/&+$/, "");

      params += `${checkPlacementSesion()}${checkJobStatus()}`;
      params = params.replace(/^&+/, "");
      params = params.replace(/&+$/, "");

      let url = `api/manage_job/export-manage-job/?college_id=${collegeId}&column_name=${columnStrings}&${params}`;
      url = url.replace(/&$/, "");
      // const URL = `api/manage_job/export-manage-job/?college_id=${collegeId}&column_name=${columnStrings}${checkJobStatus()}${checkJobTitle()}${checkCompanyName()}${checkJobType()}${checkDate()}${checkPlacementSesion()}${ctcFilter()}${checkJobStageId()}`;
      sendExportLink(url, setExportLoading, alert, history, location, false);
    } else {
      alert.error("Select Columns to Export");
    }
  };

  return (
    <>
      {/* <Layout> */}
      {placementSessionList?.length > 0 && filters?.job_status !== "E-JD" && (
        <div style={{ marginTop: "5px", marginBottom: "5px" }}>
          <DropDownButton
            title="Placement Session 2021"
            placementSession={placementSession}
            setPlacementSession={setPlacementSession}
          />
        </div>
      )}
      {loadingPlacement ? (
        <CustomCircularProgress />
      ) : placementSessionList?.length > 0 ? (
        <>
          <div className={classes.grid}>
            <div>
              {/* <DropDownButton />
            <br /> */}
              <Typography variant="h2">Manage Job</Typography>
              <Typography
                variant="body2"
                color="secondary"
                // style={{ marginTop: "10px" }}
              >
                Manage your all Jobs in one place
              </Typography>
              {/* <FormControlLabel
              value="end"
              control={<Checkbox color="primary" size="small" />}
              label={
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                  Show Data of All Active Placement Sessions
                </Typography>
              }
              labelPlacement="end"
            /> */}
            </div>
            {userRolesPermission?.some(
              (item) => item.access_control_key === "manage_job"
            ) && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                {/* <CSVLink
                  data={tempJobList}
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                  filename={"Job_Profiles.csv"}
                >
                  <Button
                    variant="contained"
                    //     size="small"
                    startIcon={<GetAppIcon />}
                    style={{ marginRight: "0px" }}
                    className={classes.button}
                    //   startIcon={<VerticalAlignBottomIcon />}
                  >
                    Export
                  </Button>
                </CSVLink> */}
                <Button
                  variant="contained"
                  //     size="small"
                  startIcon={<GetAppIcon />}
                  style={{ marginRight: "0px" }}
                  className={classes.button}
                  onClick={handleExport}
                  //   startIcon={<VerticalAlignBottomIcon />}
                >
                  {exportLoading ? (
                    <CircularProgress size={18} style={{ color: "#fff" }} />
                  ) : (
                    "Export"
                  )}
                </Button>
              </div>
            )}
          </div>
          <JobTab
            loading={loading}
            setSelectedRowsData={setSelectedRowsData}
            filters={filters}
            setFilters={setFilters}
            selectedRowsData={selectedRowsData}
            jobTitleSearch={jobTitleSearch}
            companyNameSearch={companyNameSearch}
            setJobTitleSearch={setJobTitleSearch}
            setCompanyNameSearch={setCompanyNameSearch}
            setApplyClickedCTC={setApplyClickedCTC}
            setJobTypeClick={setJobTypeClick}
            jobTypeClick={jobTypeClick}
            jobNavbar={jobNavbar}
            placementSession={placementSession}
            teamMemberList={teamMemberList}
            collegeId={collegeId}
            userRolesPermission={userRolesPermission}
            setCallNavbar={setCallNavbar}
            applyClickedCTC={applyClickedCTC}
            companyList={companyList}
            setCompanyList={setCompanyList}
            selectedColumnFields={selectedColumnFields}
            setSelectedColumnFields={setSelectedColumnFields}
            backendDrivenFilter={backendDrivenFilter}
            setBackendDrivenFilter={setBackendDrivenFilter}
            filterData={filterData}
            filterLoading={filterLoading}
            applicationStatusLoading={applicationStatusLoading}
            setApplicationStatusLoading={setApplicationStatusLoading}
            companyListTable={companyListTable}
            setCompanyListTable={setCompanyListTable}
            companyListTotalCount={companyListTotalCount}
            pageData={pageData}
            setPageData={setPageData}
          />
        </>
      ) : (
        <>
          <NoDataFuncNew
            title={"Please Add Placement Cycle First"}
            subtitle={"Click the below Button to add placement Cycle"}
            buttonText={"Add Placement Cycle"}
            height={"320px"}
            width={"320px"}
            link={"/placement-cycles"}
          />
        </>
      )}
      {/* </Layout> */}
    </>
  );
}
