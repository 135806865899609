import { sanchalanAxiosGetReq } from "../api/BaseApi/apiBase";
import configs from "../config";
import { checkForToken } from "./ApiCall";

export const BackendBaseApi = {
  PRAVESH: configs.apis.pravesh,
  NIYUKTI: configs.apis.niyukti,
  SANCHALAN: configs.apis.sanchalan,
  CMS: configs.apis.cms,
};

export const APP_ENDPOINT = configs.app;
export const NEXT_APP_ENDPOINT = configs.next_app;

export const MENTORKART_ENDPOINT = configs.mentorkart;
export const FACEBOOK_APP_ID = configs.facebookAppId;

export const FACEBOOK_APP_SECRET = configs.facebookClientSecret;

export const GOOGLE_CLIENT_ID = configs.googleClientId;

export const maritalStatusList = [
  { id: "Married", name: "Married" },
  { id: "Unmarried", name: "Unmarried" },
];

export const getColumnString = (selectedColumnFields) => {
  let str = "";
  if (selectedColumnFields?.length > 0) {
    selectedColumnFields?.map((item) => {
      str += `${item.replace(/ /g, "_").toLowerCase()},`;
    });
    str = str?.replace(/,*$/, "");
    return str;
    //sendExportLink(str?.replace(/,*$/, ""));
  }
};

export const HIDE_PROFILE_ACTION = ["student-profile-request"];
