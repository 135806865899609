import createBrowserHistory from "history/createBrowserHistory";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import "../src/style/button.css";
import "../src/style/index.css";
import "../src/style/reactVideo.css";
//import "../src/style/global.scss";
import App from "./App/App";
import reportWebVitals from "./reportWebVitals";
import RootReducer from "./RootReducer/RootReducer";
import ScrollToTop from "./scrollToTop";
import { ErrorBoundary } from "react-error-boundary";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import errorsvg from "../src/assets/svg/No-data-rafiki.svg";
//import "react-modal-video/scss/modal-video.scss";
import Layout from "./components/Layout";
import { ThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core";

import {
  positions,
  Provider as AlertProvider,
  transitions,
  useAlert,
} from "react-alert";
import AlertTemplate from "./components/AlertTempelate";
import { themeSelectorFn } from "./api/Domain/selector";
import { getThemeDetails } from "./api/Domain/action";
import pSBC from "shade-blend-color";

export const history = createBrowserHistory();

//REACT-ALERT DESIGN AND OPTIONS
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 10001,
    fontSize: "14px",
    textTransform: "lowercase",
  },
};

const store = createStore(RootReducer, applyMiddleware(thunk, logger));

const useStyles = makeStyles((theme) => ({
  btn: {
    borderRadius: "8px",
    boxShadow: "none",
    paddingLeft: "30px",
    paddingRight: "30px",
  },
  img: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

function ErrorFallback({ error, resetErrorBoundary }) {
  const classes = useStyles();

  return (
    <div className={classes.img}>
      <img src={errorsvg} height="400px" width="400px" alt="404 Error" />
      <Typography
        variant="h1"
        style={{ fontWeight: "bold", marginBottom: "20px" }}
      >
        Something went Wrong
      </Typography>
      <pre>{error.message}</pre>
      <br />
      <br />
      <Button className={classes.btn} variant="contained" color="primary">
        Try Again
      </Button>
    </div>
  );
}

const ThemeWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const theme_change = useSelector(themeSelectorFn);

  useEffect(() => {
    if (!theme_change?.main) dispatch(getThemeDetails());
  }, []);

  const Theme = createMuiTheme({
    palette: {
      type: "light",
      common: {
        black: "#000000",
        white: "#ffffff",
      },
      primary: {
        main: theme_change?.main ? theme_change?.main : "#01203F",
        hover: theme_change?.hover ? theme_change.hover : "#0069D9",
        disable: theme_change?.disable ? theme_change?.disable : "#58AAFF",
        contrastText: theme_change?.contrast_text
          ? theme_change.contrast_text
          : "#fff",
        secondary: theme_change?.secondary ? theme_change.secondary : "#e0a800",
      },
      secondary: {
        main: "#000",
        hover: "#5A6268",
        disable: "#B0B6BA",
      },
      success: {
        main: "#28A745",
        hover: "#218838",
        disable: "#74C686",
      },
      text: {
        primary: "#424242",
        secondary: "#181818",
      },
      error: {
        main: "#DC3545",
        hover: "#C82333",
        disable: "#E97B86",
      },
      warning: {
        main: "#FFC107",
        hover: "#E0A800",
        disable: "#FFD75E",
      },

      grey: {
        50: "#F2F2F2",
      },
    },
    typography: {
      fontFamily: "Nunito",
      h1: {
        fontSize: "3em",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      h2: {
        fontSize: "34px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      h3: {
        fontSize: "24px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      h4: {
        fontSize: "20px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "600",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      h5: {
        fontSize: "18px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "600",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      h6: {
        fontSize: "16px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "600",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      body1: {
        color: "#000000",
        fontSize: "16px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      body2: {
        color: "#000000",
        fontSize: "14px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      body3: {
        fontSize: "14px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      subtitle1: {
        fontSize: "16px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      subtitle2: {
        fontSize: "14px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "600",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      caption: {
        fontSize: "14px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      overline: {
        fontSize: "10px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      button: {
        fontSize: "16px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      buttonmob: {
        fontSize: "14px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
    },
    overrides: {
      MuiButton: {
        root: {
          position: "relative",
          textTransform: "none",
          transition: "all .15s ease",
          letterSpacing: ".025em",
          fontSize: ".875rem",
          padding: ".625rem 1.25rem",
          willChange: "transform",
          border: "1px solid transparent",
          lineHeight: "1.5",
          borderRadius: "8px",
          userSelect: "none",
          display: "inline-block",
          boxShadow: "none",
          fontWeight: "600",
          textAlign: "center",
          verticalAlign: "middle",
        },
        contained: {
          "&$disabled": {
            color: "#FFF",
            backgroundColor: pSBC(
              0.7,
              theme_change?.main ? theme_change?.main : "#007BFF"
            ),
          },
        },
        text: {
          color: "#EE7D66",
        },
      },
      MuiChip: {
        root: {
          borderRadius: 8,
          fontSize: 14,
        },
      },
      MuiOutlinedInput: {
        // MuiInputLabel: {
        //   root: {
        //     // color:'black',
        //     fontSize: 13,
        //   },
        // },
        root: {
          height: 50,
          borderRadius: 8,
          fontSize: 15,

          // border: "1px solid #ced4da",
          // boxShadow: "0px 0px 3px 0px rgb(206 212 218)",
        },
        notchedOutline: {
          // borderColor: "transparent",
        },
        multiline: {
          height: "auto",
        },
      },

      MuiFab: {
        root: {
          backgroundColor: "#EE7D66",
          color: "#FFF",
        },
      },
      MuiTab: {
        wrapper: {
          fontSize: "14px",
          color: "#000",
        },
        textColorInherit: {
          color: "#FFF",
        },
      },
      MuiAutocomplete: {
        paper: {
          borderRadius: 8,
        },
      },
      MuiMenu: {
        paper: {
          borderRadius: 8,
        },
      },
      // MuiFormLabel: {
      //   root: {
      //     color: "rgba(0, 0, 0, 0.23)",
      //   },
      // },
      MuiSelect: {
        root: {
          background: "transparent",
        },
        select: {
          backgroundColor: "transparent",

          "&:focus": {
            backgroundColor: "transparent",
            borderRadius: 8,
          },
        },
      },
    },
    publicLeftSide: {},
  });

  return <ThemeProvider theme={Theme}>{children}</ThemeProvider>;
};

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <BrowserRouter>
        <ThemeWrapper>
          <AlertProvider template={AlertTemplate} {...options}>
            <App />
          </AlertProvider>
        </ThemeWrapper>
      </BrowserRouter>
    </ErrorBoundary>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
