import React from "react";
import { Box, Typography, IconButton } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Success icon

const CenteredSuccessPopup = ({ onClose }) => {
  return (
    <Box
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 1300,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Dark overlay
        backdropFilter: "blur(8px)", // Blur effect
      }}
    >
      <Box
        style={{
          position: "relative",
          backgroundColor: "#f9f9f9", // Light background
          borderRadius: "16px",
          width: "400px",
          textAlign: "center",
          padding: "24px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
          color: "#333",
        }}
      >
        {/* Close Button */}
        <IconButton
          onClick={onClose}
          style={{
            position: "absolute",
            top: "8px",
            right: "8px",
            color: "#333",
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* Success Icon */}
        <CheckCircleIcon
          style={{
            fontSize: "80px", // Large icon size
            color: "#4caf50", // Green color for the icon
            marginBottom: "16px",
          }}
        />

        {/* Message */}
        <Typography
          variant="h6"
          style={{
            fontWeight: 600,
            color: "#4caf50", // Green color for the main message
          }}
          gutterBottom
        >
          Authorized
        </Typography>
        <Typography
          variant="body2"
          style={{
            color: "#555", // Neutral dark color for additional description
            fontSize: "16px",
            marginTop: "8px",
          }}
        >
          Your authentication is successful. Now you can schedule your interview in your Google Calendar.
        </Typography>
      </Box>
    </Box>
  );
};

export default CenteredSuccessPopup;
