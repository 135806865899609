import { makeStyles, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import pSBC from "shade-blend-color";
import { niyuktiAxiosGetReq } from "../../../api/BaseApi/apiBase";
import JobDetailsCard from "../JobDetailsCard";
import OverViewSkelton from "./OverViewSkelton";
import {
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../../api/SignIn/selector";

const useStyles = makeStyles((theme) => ({
  infoitem: {
    display: "flex",
    flexDirection: "row",
    alignContent: "space-around",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  info_icon: {
    color: "info_icon",
    height: "18px",
  },
  detail: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "flex-start",
    alignContent: "center",
  },
  info: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  chip: {
    margin: theme.spacing(0.5),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",
  },
}));

function OverViewCard({ jobId, collegeId, isLiveJobs, pageloading }) {
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const [jobStatus, setJobStatus] = useState({
    logo: null,
    company_website: null,
  });

  const getNiyuktiJobDetails = async () => {
    setLoading(true);
    const prepareJobDetailData = (d) => {
      let modifiedData = { ...d };

      modifiedData = {
        ...d,
        company_name: d?.company?.company_name,
        application_end_date: d?.apply_end_date,
        job_type_name: d?.employment_type_name,
        joining_date: d?.job_duration_start,
        job_type_id: d?.job_type,
        skills: d?.eligibility_criteria?.skills,
        grad_min_score: d?.eligibility_criteria?.percentage,
        grad_scoring: "Percentage",
        eligible_batches: d?.eligibility_criteria?.graduation_years,
        rounds: d?.job_rounds,
      };

      return modifiedData;
    };

    // degree spec new sends data as required for the page to represent.
    let endPoint = `job/college_jobs/job_details/?job_id=${jobId}`;
    if (collegeId) endPoint += `&college_id=${collegeId}`;

    try {
      const res = await niyuktiAxiosGetReq(endPoint);
      if (res?.data?.success) {
        const d = res?.data?.data;
        // job rejection status is set here as per changes done on job recommendation page.

        // setUndo(data?.is_rejected);
        // setJobStatus((prevData) => ({ ...prevData, company_website: data?.company?.company_website }));
        const modifiedData = prepareJobDetailData(d);
        setJobStatus({
          logo: d?.company?.company_logo,
          company_website: d?.company?.company_website,
        });

        setData(modifiedData);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      (collegeId && jobId) ||
      (jobId && isEducationGroup) ||
      (jobId && isCollegeGroup)
    ) {
      getNiyuktiJobDetails();
    }
  }, [jobId, collegeId, isLiveJobs]);

  return (
    <>
      {loading || pageloading ? (
        <OverViewSkelton />
      ) : data && jobId ? (
        <JobDetailsCard
          data={data}
          liveJobs={true}
          jobStatus={jobStatus}
          fromJobDetail={true}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default OverViewCard;
