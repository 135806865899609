import {
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { sanchalanAxiosGetReq } from "../../../../api/BaseApi/apiBase";
import {
  AllLocationsAPII,
  COMPANY_LISTING,
} from "../../../../api/JobPost/action";
import {
  isCollegeGroupFn,
  isEducationGroupFn,
  collegeGroupListSelectorFn,
  entityInfoSelectorFn,
  collegeIdFn,
} from "../../../../api/SignIn/selector";
import { BackendBaseApi } from "../../../../constants/constants";
import { groupViewCheck } from "../../../../utility/groupViewCheck";
import { emailRegex, url } from "../../../../utility/regex";
import OnlyCollegeList from "../../../GroupCollegeList/onlyCollegeList";
import { generateCsvForParams } from "../../../../utility/commaSeparatedStrings";
import checkSelectedGroup from "../../../../utility/getGroupIdParam";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import CompanyGroupAccordian from "../../../Accordian/CollegeGroupAccordain";
const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  root1: {
    borderRadius: "8px",
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },

  btncancel: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },

  formControl: {
    minWidth: "100%",
    marginTop: "10px",
    marginBottom: "10px",
  },
  floatingLabelFocusStyle: {
    color: "#b0b6ba",
    fontSize: "14px",
  },
  redfloatingLabelFocusStyle: {
    color: "#DC3545",
    fontSize: "14px",
  },
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  circularProgress: {
    color: theme.palette.common.white,
  },
  errorMessage: {
    color: "#DC3545",
  },
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
}));

export default function AddNewCompanyForm({
  handleClose,
  industryList,
  collegeId,
  setCompanyList,
  // setSelectedCompany
  handleSelectedCompanyChange,
  companyDetails,
  setCompanyDetails,
  isRecommended = false,
  setJobDetails = () => {},
  jobDetails,
  source = "",
  initialCompanyDetails,
  setMakeCompanyCall = () => {},
  setRefreshData = () => {},
  selectedGroup = {},
  groupArrayInitialState,
  groupArray,
  setGroupArray = () => {},
  isOffCampusData = false,
}) {
  const classes = useStyles();
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const collegeGroupList = useSelector(collegeGroupListSelectorFn);
  const cityList = useSelector((state) =>
    state?.AllLocations?.details?.data ? state?.AllLocations?.details?.data : []
  );
  const entityInfo = useSelector(entityInfoSelectorFn) || {
    college: "College",
    university: "University",
  };
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [mainCollegeList, setMainCollegeList] = useState([]);
  const [loadingLocations, setLoadingLocations] = useState(false);

  useEffect(() => {
    dispatch(AllLocationsAPII(setLoadingLocations, alert, history, location));
  }, []);

  useEffect(() => {
    if (groupViewCheck(isEducationGroup, isCollegeGroup, collegeId)) {
      sanchalanAxiosGetReq(
        `api/university/university_count/?${checkSelectedGroup(
          isEducationGroup,
          isCollegeGroup,
          selectedGroup,
          collegeGroupList
        )}`
      )
        .then((res) => {
          if (res?.data?.success) {
            setMainCollegeList(res?.data?.data);
          } else {
            alert.error("Error in fetching colleges list");
          }
        })
        .catch((err) => {
          alert.error("Error in fetching colleges list");
        });
    }
  }, []);

  const [selectedIndustyValue, setSelectedIndustyValue] = useState(
    companyDetails?.industry_type_id
      ? companyDetails?.industry_type_id - 1
      : null
  );
  const [collegeList, setCollegeList] = useState([]);

  const [selectedIndustrySector, setSelectedIndustrySector] = useState(null);

  useEffect(() => {
    let res = null;
    if (industryList?.length && companyDetails?.industry_name) {
      res = industryList?.filter(
        (option) => option.name === companyDetails.industry_name
      );
    } else if (industryList?.length && companyDetails?.industry_id) {
      res = industryList?.filter(
        (option) => option.id === companyDetails.industry_id
      );
    }
    res?.length && setSelectedIndustrySector(res[0]);
  }, [industryList, companyDetails]);

  const [companyDetailsError, setCompanyDetailsError] = useState({
    college_list: null,
    company_name: null,
    company_website: null,
    industry_id: null,
    industry_name: null,
    industry_type: null,
    contact_person_name: null,
    email: null,
    phone: null,
    designation: null,
  });

  const getInvitationSource = () => {
    if (source === "RECOMMENDED") return "GETWORK_FULFILLED";
    else if (source === "INBOUND_JOB") return "GETWORK_INBOUND";
    else if (source === "OFF_CAMPUS") return "OFFCAMPUS_PLACEMENT";
    else {
      if (source) return "ADD_NEW";
      else return "OFFCAMPUS_PLACEMENT";
    }
  };

  // useEffect(() => {
  //   if (collegeId) {
  //     axios
  //       .get(
  //         `${BackendBaseApi.SANCHALAN}api/settings/college/tnp_rules/?college_id=${collegeId}`,
  //         { withCredentials: true }
  //       )
  //       .then((res) => {
  //         if (res?.data?.success) {
  //           setPlacementRule(res?.data?.data[5]?.is_selected);
  //         }
  //       })
  //       .catch((err) => {
  //         alert.error(err?.response?.data?.eror);
  //       });
  //   }
  // }, []);

  const getSelectedCollegeIds = (data) => {
    const selectedCollegeIds = new Set();

    data.forEach((item) => {
      item.groups.forEach((group) => {
        group.colleges.forEach((college) => {
          if (college.selected) {
            selectedCollegeIds.add(college.college_id);
          }
        });
      });
    });

    return Array.from(selectedCollegeIds);
  };

  const handleSubmit = async () => {
    if (validateData()) {
      var company_details = {
        company_name: companyDetails.company_name,
        company_website: companyDetails.company_website,
        industry_id: companyDetails.industry_id,
        industry_name: companyDetails.industry_name,
        industry_type_id: companyDetails.industry_type_id,
        industry_type_name: companyDetails.industry_type_name,
        company_location: companyDetails.industry_type_name,
        company_location: companyDetails?.company_location
          ? {
              id: companyDetails?.company_location?.city_id,
              name: companyDetails?.company_location?.city,
              state_id: companyDetails?.company_location?.state_id,
              state: companyDetails?.company_location?.state,
            }
          : null,
      };
      var data = {
        college_id: groupViewCheck(isEducationGroup, isCollegeGroup, collegeId)
          ? getSelectedCollegeIds(groupArray) //companyDetails?.college_list.map((college) => college.id)
          : [collegeId],
        company_form_data: company_details,
        poc_list: [
          {
            contact_person_name: companyDetails.contact_person_name,
            email: companyDetails.email,
            phone: companyDetails.phone,
            designation: companyDetails.designation,
            is_validated: true,
          },
        ],
        invitation_source: getInvitationSource(),
      };
      setLoading(true);
      const res = await axios
        .post(BackendBaseApi.SANCHALAN + "api/company/view/", data, {
          withCredentials: true,
        })
        .catch((error) => {
          setLoading(false);
          if (error?.response?.status !== 401) {
            alert.error("Unable to Create your company. Some Error Occurred");
          }
          throw error;
        });

      if (res?.data?.success) {
        // if (source === "RECOMMENDED" || source === "INBOUND_JOB") {
        setJobDetails({
          ...jobDetails,
          company: res?.data?.data,
          company_id: res?.data?.data?.id,
          company_name: companyDetails.company_name,
        });
        setGroupArray(groupArrayInitialState);
        // if (!placementRule)
        //   alert.success(
        //     "Company added successfully. Please go to Manage company -> Pending section and give approval"
        //   );
        // else
        alert.success("Company Added Successfully!!");
        setRefreshData(true);
        // } else alert.success("Company Added Successfully!!");
        if (isOffCampusData) {
          axios
            .get(
              BackendBaseApi.SANCHALAN +
                `api/company/list/?college_id=${collegeId}&invitation_source=OFFCAMPUS_PLACEMENT`,
              {
                withCredentials: true,
              }
            )
            .then((response) => {
              if (response?.data?.success) {
                dispatch({
                  type: COMPANY_LISTING,
                  payload: response?.data?.data,
                });
                if (setCompanyList) {
                  setCompanyList(response?.data?.data);
                }
                //  setSelectedCompany({name: companyDetails.company_name});
                const selectedCompany = response?.data?.data.find(
                  (company) => company.name === companyDetails.company_name
                );
                if (handleSelectedCompanyChange) {
                  handleSelectedCompanyChange(null, selectedCompany);
                }
              } else alert.error(response?.data?.error);
            })
            .catch((error) => {
              alert.error(error?.response?.data?.error);
              throw error;
            });
          setMakeCompanyCall(true);
          setCompanyDetails(initialCompanyDetails);
        }

        handleClose();
      } else {
        alert.error("something went wrong");
      }
      setLoading(false);
    }
  };

  const isAtLeastOneCollegeSelected = (groupArray) => {
    for (let section of groupArray) {
      for (let group of section.groups) {
        const isAnyCollegeSelected = group.colleges.some(
          (spec) => spec.selected
        );

        if (isAnyCollegeSelected) {
          return true;
        }
      }
    }
    return false;
  };

  const validateData = () => {
    var is_valid = true;
    var company_name_error = null;
    var comapny_website_error = null;
    var contact_person_error = null;
    var email_error = null;
    var phone_error = null;
    var industry_error = null;
    var industry_type_error = null;
    var designation_error = null;
    var college_list_error = null;
    var company_location_error = null;

    if (
      groupViewCheck(isEducationGroup, isCollegeGroup, collegeId) &&
      // !companyDetails?.college_list?.length
      !isAtLeastOneCollegeSelected(groupArray)
    ) {
      college_list_error = `${entityInfo?.college} field cannot be left blank`;
      is_valid = false;
    }
    if (!companyDetails.company_name) {
      company_name_error = "Company Name field cannot be left blank";
      is_valid = false;
    }

    if (!companyDetails.company_website) {
      comapny_website_error = "Company Website field cannot be left blank";
      is_valid = false;
    }
    url.lastIndex = 0;
    if (!url.test(companyDetails.company_website)) {
      comapny_website_error = "Enter Correct Web Address";
      is_valid = false;
    }
    if (!companyDetails.contact_person_name) {
      contact_person_error = "Contact Person Name field cannot be left blank";
      is_valid = false;
    }
    if (!companyDetails.email) {
      email_error = "Email field cannot be left blank";
      is_valid = false;
    }
    if (!emailRegex.test(companyDetails.email)) {
      email_error = "Enter a valid email";
      is_valid = false;
    }
    if (!companyDetails.phone) {
      phone_error = "Phone Number field cannot be left blank";
      is_valid = false;
    }
    if (companyDetails.phone && companyDetails.phone.toString().length !== 10) {
      phone_error = "Enter a valid Phone No.";
      is_valid = false;
    }
    if (!companyDetails.designation) {
      designation_error = "Designation field cannot be left blank";
      is_valid = false;
    }
    if (!companyDetails.industry_id) {
      industry_error = "Industry Field cannot be left blank";
      is_valid = false;
    }
    if (!companyDetails.company_location) {
      company_location_error = "Company Location field cannot be left blank";
      is_valid = false;
    }
    if (!companyDetails.industry_type_id) {
      industry_type_error = "Industry Type field cannot be left blank";
      is_valid = false;
    }

    setCompanyDetailsError({
      ...companyDetailsError,
      company_name: company_name_error,
      company_website: comapny_website_error,
      contact_person_name: contact_person_error,
      email: email_error,
      phone: phone_error,
      industry: industry_error,
      industry_type: industry_type_error,
      designation: designation_error,
      college_list: college_list_error,
      company_location: company_location_error,
    });
    return is_valid;
  };

  const industryType = [
    { id: 1, name: "Private" },
    { id: 2, name: "Government" },
    { id: 3, name: "Semi-Government" },
  ];

  // useEffect(()=>{
  //   if(companyDetails?.industry_type_name){
  //    const res = industryType?.filter(item => item.name === companyDetails?.industry_type_name);
  //    setCompanyDetails({
  //     ...companyDetails,
  //     industry_type_id: res[0].id,
  //   });
  //   setSelectedIndustyValue(res[0].id);
  //   }

  // },[])

  const [searchSelected, setSearchSelected] = useState(null);

  const [searchLoading, setSearchLoading] = useState(false);
  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const getSearch = async () => {
    let url = `api/company/view/companies_names/?&search=${searchQuery}`;
    if (collegeId) {
      url += `&college_id=${collegeId}`;
    } else {
      url += checkSelectedGroup(
        isEducationGroup,
        isCollegeGroup,
        selectedGroup,
        collegeGroupList
      );
    }
    setSearchLoading(true);
    try {
      const res = await axios.get(BackendBaseApi.SANCHALAN + url, {
        withCredentials: true,
      });
      setSearchData([]);

      if (res.data.success) {
        setSearchData(res?.data?.data);
      } else {
        alert.error(res.data.error);
      }
      setSearchLoading(false);
    } catch (error) {
      alert.error("error?.response?.data?.error");
      setSearchLoading(false);
    }
  };

  useEffect(() => {
    if (searchQuery || searchQuery === "") {
      getSearch();
    }
  }, [searchQuery]);

  const debounce = (func) => {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, 500);
    };
  };
  const handleChange = (e) => {
    // setSearchData([]);
    if (e?.target?.value || e?.target?.value === "")
      setSearchQuery(e.target.value);
  };

  const optimisedHandleChange = useCallback(debounce(handleChange), []);

  useEffect(() => {
    if (searchQuery) {
      setOpenAutocomplete(true);
    }
  }, [searchQuery]);

  return (
    <>
      <div className={classes.paper}>
        <Typography variant="h3">Add New Company</Typography>
        <br />

        {/* <TextField
          variant="outlined"
          required
          margin="normal"
          inputProps={{
            style: {
              padding: "16px",
            },
          }}
          value={companyDetails.company_name}
          onChange={(e) => {
            setCompanyDetails({
              ...companyDetails,
              company_name: e.target.value,
            });
            setCompanyDetailsError({
              ...companyDetailsError,
              company_name: null,
            });
          }}
          error={companyDetailsError.company_name ? true : false}
          helperText={<span>{companyDetailsError.company_name}</span>}
          InputLabelProps={{
            className: classes.floatingLabelFocusStyle,
          }}
          fullWidth
          label="Company Name"
        /> */}

        <Autocomplete
          sx={{
            display: "inline-block",
            "& input": {
              bgcolor: "background.paper",
              color: (theme) =>
                theme.palette.getContrastText(theme.palette.background.paper),
            },
          }}
          forcePopupIcon={false}
          // open={openAutocomplete}
          noOptionsText={
            <div style={{ fontStyle: "italic", display: "flex" }}>
              <Typography sx={{ fontStyle: "italic" }}>
                {`Company not posted yet!`}
              </Typography>
              <Typography
                color="primary"
                sx={{ fontWeight: 600, cursor: "pointer", fontStyle: "italic" }}
              >
                {" "}
                &nbsp; Add Company{" "}
              </Typography>
            </div>
          }
          value={searchSelected}
          onChange={() => {}}
          onInputChange={(event, newValue) => {
            if (newValue || newValue === "") {
              setSearchSelected({
                company_name: newValue,
                company_location: "",
              });
              setCompanyDetails({
                ...companyDetails,
                company_name: newValue,
              });
              setCompanyDetailsError({
                ...companyDetailsError,
                company_name: null,
              });
              setOpenAutocomplete(true);
            }
          }}
          onClose={() => {
            setOpenAutocomplete(false);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id="free-solo-with-text-demo"
          options={searchData ? searchData : []}
          getOptionLabel={(option) => {
            return `${option.company_name}`;
          }}
          getOptionDisabled={(option) => true}
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,

                  style: {
                    border: "none",
                    paddingLeft: 5,
                    minWidth: "100%",
                    fontSize: "14px",
                  },
                }}
                margin="normal"
                variant="outlined"
                placeholder="Company Name * (Type & see already present Companies)"
                onChange={(ev) => {
                  if (ev.target.value !== null) {
                    optimisedHandleChange(ev);
                  }
                }}
                error={companyDetailsError.company_name ? true : false}
                helperText={<span>{companyDetailsError.company_name}</span>}
              />
            </div>
          )}
        />

        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          required
          inputProps={{
            style: {
              padding: "16px",
            },
          }}
          value={companyDetails?.company_website}
          onChange={(e) => {
            setCompanyDetails({
              ...companyDetails,
              company_website: e.target.value,
            });
            setCompanyDetailsError({
              ...companyDetailsError,
              company_website: null,
            });
          }}
          error={companyDetailsError.company_website ? true : false}
          helperText={<span>{companyDetailsError.company_website}</span>}
          InputLabelProps={{
            className: classes.floatingLabelFocusStyle,
          }}
          label="Website"
        />

        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          required
          inputProps={{
            style: {
              padding: "16px",
            },
          }}
          value={companyDetails?.contact_person_name}
          onChange={(e) => {
            setCompanyDetails({
              ...companyDetails,
              contact_person_name: e.target.value,
            });
            setCompanyDetailsError({
              ...companyDetailsError,
              contact_person_name: null,
            });
          }}
          error={companyDetailsError.contact_person_name ? true : false}
          helperText={<span>{companyDetailsError.contact_person_name}</span>}
          InputLabelProps={{
            className: classes.floatingLabelFocusStyle,
          }}
          label="Contact Person Name"
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          inputProps={{
            style: {
              padding: "16px",
            },
          }}
          value={companyDetails?.email}
          onChange={(e) => {
            setCompanyDetails({ ...companyDetails, email: e.target.value });
            setCompanyDetailsError({ ...companyDetailsError, email: null });
          }}
          error={companyDetailsError.email ? true : false}
          helperText={<span>{companyDetailsError.email}</span>}
          InputLabelProps={{
            className: classes.floatingLabelFocusStyle,
          }}
          label="Email ID"
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          required
          inputProps={{
            style: {
              padding: "16px",
            },
          }}
          className={classes.input}
          type="number"
          value={companyDetails.phone}
          onChange={(e) => {
            setCompanyDetails({ ...companyDetails, phone: e.target.value });
            setCompanyDetailsError({ ...companyDetailsError, phone: null });
          }}
          error={companyDetailsError.phone ? true : false}
          helperText={<span>{companyDetailsError.phone}</span>}
          InputLabelProps={{
            className: classes.floatingLabelFocusStyle,
          }}
          label="Phone Number"
          name="phone number"
        />

        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          required
          inputProps={{
            style: {
              padding: "16px",
            },
          }}
          value={companyDetails?.designation}
          onChange={(e) => {
            setCompanyDetails({
              ...companyDetails,
              designation: e.target.value,
            });
            setCompanyDetailsError({
              ...companyDetailsError,
              designation: null,
            });
          }}
          error={companyDetailsError.designation ? true : false}
          helperText={<span>{companyDetailsError.designation}</span>}
          InputLabelProps={{
            className: classes.floatingLabelFocusStyle,
          }}
          label="Designation"
          name="Designation"
        />
        <div style={{ marginTop: 20 }}>
          <Autocomplete
            id="country-select-demo"
            options={cityList ? cityList : []}
            getOptionLabel={(option) => option?.city + ",  " + option?.state}
            value={companyDetails?.company_location}
            onChange={(e, newValue) => {
              setCompanyDetails({
                ...companyDetails,
                company_location: newValue,
              });
            }}
            style={{
              marginTop: 10,
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                required
                label="Company Location"
                inputProps={{
                  ...params.inputProps,
                }}
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                error={companyDetailsError.company_location ? true : false}
                helperText={<span>{companyDetailsError.company_location}</span>}
              />
            )}
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <Autocomplete
            id="country-select-demo"
            options={industryList}
            getOptionLabel={(option) => (option?.name ? option?.name : "")}
            value={selectedIndustrySector}
            onChange={(e, newValue) => {
              if (newValue) {
                setCompanyDetails({
                  ...companyDetails,
                  industry_id: newValue.id,
                  industry_name: newValue.name,
                });
                setCompanyDetailsError({
                  ...companyDetailsError,
                  industry_id: null,
                  industry_name: null,
                });
                setSelectedIndustrySector(newValue);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Industry"
                required
                inputProps={{
                  ...params.inputProps,
                }}
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                error={companyDetailsError.industry ? true : false}
                helperText={<span>{companyDetailsError.industry}</span>}
              />
            )}
          />
        </div>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ marginTop: "20px" }}
        >
          <InputLabel
            required
            className={
              companyDetailsError.industry_type
                ? classes.redfloatingLabelFocusStyle
                : classes.floatingLabelFocusStyle
            }
          >
            Industry Type
          </InputLabel>
          <Select
            error={companyDetailsError.industry_type ? true : false}
            value={selectedIndustyValue}
            onChange={(e) => {
              setCompanyDetails({
                ...companyDetails,
                industry_type_id: industryType[e.target.value].id,
                industry_type_name: industryType[e.target.value].name,
              });
              setCompanyDetailsError({
                ...companyDetailsError,
                industry_type: null,
              });
              setSelectedIndustyValue(e.target.value);
            }}
            label="Industry Type"
            inputProps={{
              style: {
                padding: "16px",
                backgroundColor: "#fff",
                borderRadius: "8px",
              },
            }}
          >
            <MenuItem value={0}>Private</MenuItem>
            <MenuItem value={1}>Government</MenuItem>
            <MenuItem value={2}>Semi-Government</MenuItem>
          </Select>
          <FormHelperText className={classes.errorMessage}>
            {companyDetailsError.industry_type}
          </FormHelperText>
        </FormControl>
      </div>
      {groupViewCheck(isEducationGroup, isCollegeGroup, collegeId) && (
        // <div style={{ marginTop: 20 }}>
        //   <OnlyCollegeList
        //     groupData={companyDetails}
        //     setGroupData={setCompanyDetails}
        //     groupDataError={companyDetailsError}
        //     setGroupDataError={setCompanyDetailsError}
        //     mainCollegeList={mainCollegeList}
        //     collegeList={collegeList}
        //     setCollegeList={setCollegeList}
        //   />
        // </div>
        <CompanyGroupAccordian
          groupArray={groupArray}
          setGroupArray={setGroupArray}
          errorMessage={companyDetailsError?.college_list}
          setErrorMessage={setCompanyDetailsError}
          errorMessageKey={"college_list"}
          placeholder={`Select ${entityInfo?.college} in Group *`}
        />
      )}
      <br />

      <Grid container spacing={6}>
        <Grid item sm={6} style={{ display: "grid" }}>
          {!isRecommended && (
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClose}
              style={{
                height: "48px",
                borderRadius: "8px",
                fontSize: "16px",
                boxShadow: "none",
                fontWeight: "normal",
              }}
              disabled={loading}
            >
              Cancel
            </Button>
          )}
        </Grid>
        <Grid item sm={6} style={{ display: "grid" }}>
          {" "}
          <Button
            variant="contained"
            color="primary"
            style={{
              height: "48px",
              borderRadius: "8px",
              fontSize: "16px",
              fontWeight: "normal",
              boxShadow: "none",
            }}
            onClick={() => {
              if (!loading) handleSubmit();
            }}
          >
            {loading ? (
              <CircularProgress
                className={classes.circularProgress}
                size={14}
              />
            ) : (
              <>Add Company</>
            )}{" "}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
