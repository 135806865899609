//Imports
import axios from "axios";
import { checkForToken } from "../../constants/ApiCall";
import { BackendBaseApi } from "../../constants/constants";
//Exports
export const PLACEMENT_CYCLES = "PLACEMENT_CYCLES";
export const USER_ROLES_ALL = "USER_ROLES_ALL";

//Placement Api Call Action
export const PlacementCyclesGetAPI = (
  setLoading,
  alert,
  history,
  location,
  collegeId,
  group = false
) => {
  return async (dispatch) => {
    setLoading(true);
    let url = "api/placement_cycles/placements/";
    if (group) {
      url += "?" + collegeId.slice(1);
    } else {
      url += `?college_param=${collegeId}`;
    }
    const res = await axios
      .get(BackendBaseApi.SANCHALAN + url, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      setLoading(false);
      console.log("list success");
    } else {
      alert.error("Some Error Occurred!!!");
    }

    dispatch({
      type: PLACEMENT_CYCLES,
      payload: res?.data?.data || [],
    });
  };
};

export const PlacementCyclesTableGetAPI = (
  setLoading,
  alert,
  history,
  location,
  collegeId,
  group = false,
  setCompanyListTotalCount
) => {
  return async (dispatch) => {
    setLoading(true);
    let url = "api/placement_cycles/placements/";
    if (group) {
      url += "?" + collegeId.slice(1);
    } else {
      url += `?college_param=${collegeId}`;
    }
    const res = await axios
      .get(BackendBaseApi.SANCHALAN + url, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      setLoading(false);
      setCompanyListTotalCount(res.data.data.count);
      console.log("list success");
    } else {
      alert.error("Some Error Occurred!!!");
    }

    dispatch({
      type: PLACEMENT_CYCLES,
      payload: res?.data?.data?.results || [],
    });
  };
};

export const PlacementPostAPI = (
  data,
  setLoading,
  alert,
  history,
  location,
  placementList = [],
  handleClose,
  setPlacement,
  setSelectedDate,
  setAddStudentToPlacementCycle,
  addStudentToPlacementCycle,
  collegeId,
  setGroupArray = () => {},
  groupArrayInitialState = []
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .post(
        BackendBaseApi.SANCHALAN +
          "api/placement_cycles/placements/?college_param=" +
          collegeId,
        data,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        if (
          error?.response?.status !== 401 &&
          error?.response?.status !== 403
        ) {
          alert.error(
            "Unable to Create New Placement Cycle. Some Error Occurred!!"
          );
        }
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      alert.success("Placement Cycle Added Successfully");
      var arr = [...placementList];
      arr.unshift(res.data.data.data);
      if (res?.data?.data?.data?.id && addStudentToPlacementCycle)
        history.push(
          `/add-students/?placement_cycle_id=${res?.data?.data?.data?.id}&placement_cycle_name=${res?.data?.data?.data?.name}`
        );
      setPlacement("");
      setSelectedDate(null);
      setAddStudentToPlacementCycle(false);
      setGroupArray(groupArrayInitialState);
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
    handleClose();

    dispatch({
      type: PLACEMENT_CYCLES,
      payload: arr,
    });
  };
};

export const PlacementPatchAPI = (
  data,
  objId,
  setLoading,
  alert,
  history,
  location,
  placementList = [],
  handleClose,
  fromEndForm,
  multipleEdit,
  setSelectedID = () => {},
  setSelectedRowsData = () => {},
  setPlacement = () => {},
  setSelectedDate = () => {},
  setAddStudentToPlacementCycle = () => {},
  setGroupArray = () => {},
  groupArrayInitialState = []
) => {
  return async (dispatch) => {
    setLoading(true);
    let url = `${BackendBaseApi.SANCHALAN}api/placement_cycles/placements/`;
    if (!multipleEdit) url += `${objId}/`;
    const res = await axios
      .patch(url, data, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        if (
          error?.response?.status !== 401 &&
          error?.response?.status !== 403
        ) {
          alert.error("Unable to Update. Some Error Occurred!!");
        }
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      alert.success("Placement Cycle Updated Successfully");

      var arr = [...placementList];

      if (fromEndForm) {
        if (multipleEdit) {
          arr.forEach((i) => {
            if (data?.placement_id.includes(i.id)) {
              i["end_date"] = data.end_date;
              i["is_ended"] = true;
              i["last_update_date"] = new Date();
            }
          });
        } else {
          arr.forEach((i) => {
            if (i.id === objId) {
              i["end_date"] = data.end_date;
              i["is_ended"] = true;
            }
          });
        }
      } else {
        arr.forEach((i) => {
          if (i.id === objId) {
            i["name"] = data.name;
            i["start_date"] = data.start_date;
            i["last_update_date"] = new Date();
          }
        });
      }
      setPlacement("");
      setSelectedDate(null);
      setAddStudentToPlacementCycle(false);
      setGroupArray(groupArrayInitialState);
      setSelectedID([]);
      setSelectedRowsData([]);
      handleClose();
      dispatch({
        type: PLACEMENT_CYCLES,
        payload: arr,
      });
    } else {
      alert.error(res.data.error);
    }
    setLoading(false);
  };
};

export const changeState = (dispatcher, data) => {
  return async (dispatch) => {
    dispatch({ type: dispatcher, payload: data });
  };
};
