import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import React, { Suspense, useEffect, useLayoutEffect, useRef } from "react";
import {
  positions,
  Provider as AlertProvider,
  transitions,
  useAlert,
} from "react-alert";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import pSBC from "shade-blend-color";
import { getThemeDetails } from "../api/Domain/action";
import {
  collegeDetailsSelectorFn,
  mainDetailsFn,
  themeSelectorFn,
} from "../api/Domain/selector";
import {
  collegeIdFn,
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../api/SignIn/selector";
import favicon from "../assets/icons/favicon.ico";
import AlertTemplate from "../components/AlertTempelate";
import CircularProgressBar from "../components/CircularProgressBar";
import Layout from "../components/Layout";
import CollegeGroupLayout from "../components/Layout/CollegeGroupLayout";
import UniversityLayout from "../components/Layout/UniversityLayout";
import Overview from "../components/Cards/CompanyDetailInfo/Overview";
import Fallback from "../components/Fallback/Fallback";
//import EventSection from "../components/Cards/Event/EventSection";
import AllEvents from "../pages/AllEvents";
import AllGuest from "../pages/AllGuest";
import Assignment from "../pages/Assignment";
import AssignmentTrack from "../pages/AssignmentTrack";
import Billing from "../pages/Billing";
import NewFeed from "../pages/CampusFeed/NewFeed";
import NewsFeedGroup from "../pages/CampusFeed/NewsFeedGroup";
import ChatGroup from "../pages/ChatGroup";
import ChatRoom from "../pages/ChatRoom";
import CollegeProfile from "../pages/CollegeProfile";
import CompanyDetails from "../pages/CompanyDetails";
import CompanyDetailView from "../pages/CompanyDetailView";
import CompanyForm from "../pages/CompanyForm";
import CompanyTrack from "../pages/CompanyTrack";
import CompanyTrackGroup from "../pages/CompanyTrackGroup";
import CompanyUploader from "../pages/CompanyUploader";
import ContactThankYou from "../pages/ContactThankYou";
import Contact from "../pages/ContactUs";
import Course from "../pages/CourseSetting";
import CreateAssignment from "../pages/CreateAssignment";
import Dashboard from "../pages/Dashboard";
import Drive from "../pages/Drive";
import EditCompanyProfile from "../pages/EditCompanyProfile";
import EjdCompany from "../pages/EjdCompany";
import ForgotPassword from "../pages/ForgotPassword";
import Info from "../pages/Help/Info";
import NewHelp from "../pages/Help/NewHelp";
import Question from "../pages/Help/Question";
import Interview from "../pages/Interview";
import CalendarView from "../pages/Interview/CalendarView";
import CreateInterview from "../pages/Interview/CreateInterview";
import JobDetails from "../pages/JobDetails";
import JobManage from "../pages/JobManage";
import ManageEvents from "../pages/ManageEvents";
import ManageEjnf from "../pages/ManageJd/ManageEjnf";
import ManageJobPosting from "../pages/ManageJobPosting";
import ManageOffCampus from "../pages/ManageOffCampus";
import ManageTeam from "../pages/ManageTeam";
import InboundJobs from "../pages/InboundJobs/InboundJobs";
import GroupTeam from "../pages/ManageTeam/GroupTeam";
import NewPassword from "../pages/NewPassword";
import CreateNewPassword from "../pages/NewPassword/CreateNewPassword";
import NewPasswordLink from "../pages/NewPasswordLink";
import NotFound from "../pages/NotFound";
import PasswordResetLink from "../pages/PasswordResetLink";
import Placement from "../pages/Placement";
import PostDetails from "../pages/PostDetails";
import PostJob from "../pages/PostJob/index2";
import Report from "../pages/Report";
import ReportGroup from "../pages/Report/groupView";
import Rules from "../pages/Rules";
import Setting from "../pages/Setting";
import SignIn from "../pages/SignIn";
import StudentDetails from "../pages/StudentDetails";
import StudentForm from "../pages/StudentForm";
import StudentTrack from "../pages/StudentTrack";
import AddStudent from "../pages/StudentTrack/AddStudent";
import EligibleTrack from "../pages/StudentTrack/EligibleTrack";
import StudentGroupTrack from "../pages/StudentTrack/StudentGroupTrack";
import StudentUploader from "../pages/StudentUploader";
import TeamMemberInvite from "../pages/TeamMemberInvite";
import TeamMemberProfile from "../pages/TeamMemberProfile";
import TeamMemberRegistrationForm from "../pages/TeamMemberRegistrationForm";
import Testing from "../pages/Testing";
import ThankYou from "../pages/ThankYou";
import TotalMember from "../pages/TotalMember";
import Track from "../pages/Track";
import PublicTrack from "../pages/Track/publicTrack";
import StudentDetailsTrack from "../pages/Track/studentDetails";
import University from "../pages/University";
import UniversityAllCollege from "../pages/University/AllCollege/UniversityAllCollege";
import AllGroupDetails from "../pages/University/AllGroupDetails.js";
import AllSubGroups from "../pages/University/AllSubGroups";
import UniversityAssignment from "../pages/University/Assignment/UniversityPlacementcycle";
import BillingUniversity from "../pages/University/Billing";
import ChatRoomUniversity from "../pages/University/ChatRoom";
import UniversityCompanyERF from "../pages/University/CompanyERF";
import UniversityCompanyLink from "../pages/University/CompanyLink/UniversityCompanyLink";
import UniversityCourse from "../pages/University/Course/UniversityCourse";
import DegreeCampusPlacement from "../pages/University/DegreeCampusPlacement";
import UniversityEjdLink from "../pages/University/EjdLink/UniversityEjdLink";
import HighlightedIndustry from "../pages/University/HighlightedIndustry";
import HighlightedSkillsStudents from "../pages/University/HighlightedSkillsStudents";
import UniversityJobERF from "../pages/University/JobERF";
import UniversityManageEJD from "../pages/University/ManageEJD/UniversityManageEJD";
import UniversityPostJob from "../pages/University/PostJob/UniversityPostJob";
import UniversityPublicProfile from "../pages/University/PubicProfile/UniversityPublicProfile";
import UniversityRulesNew from "../pages/University/Rules";
import UniversitySetting from "../pages/University/Setting/UniversitySetting";
import UniversityStudentLink from "../pages/University/StudentLink/UniversityStudentLink";
import UniversityStudentsERF from "../pages/University/StudentsERF";
import UniversityTeamErf from "../pages/University/TeamErf/UniversityTeamErf";
import UniversityTeamLink from "../pages/University/TeamLink/UniversityStudentLink";
import UniversityManageJobs from "../pages/University/UniversityManageJobs";
import UniversityPlacementCycle from "../pages/University/UniversityPlacementCycle";
import UniversityTeamManage from "../pages/University/UniversityTeamManage";
import UniversityUserRole from "../pages/University/UserRole/UniversityUserRole";
import EditStudent from "../pages/StudentDetails/EditStudents";
import LinkNotFound from "../pages/NotFound/linkNotValid";
import RecommendedJobs from "../pages/RecommendedJobs/RecommendedJobs";
import CloneRecommended from "../pages/PostJob/CloneRecommended";
import JobRecommended from "../pages/JobRecommended";
import GetworkJob from "../pages/JobDetails/GetworkJob";
import ProfileUpdateRequest from "../pages/ProfileUpdateRequest";
import PublicJobDetails from "../pages/JobDetails/PublicJobDetails";
import PublicJobNavbar from "../components/Common/Navbar/PublicJobNavbar";
import AccountSetting from "../pages/AccountSetting";
import AddOffCampusPlacement from "../pages/AddOffCampusPlacement";
import ManageOffCampusNew from "../pages/ManageOffCampusNew";
import CampusPlacementReports from "../pages/CampusPlacementReports/index.js";
import Home from "../pages/Home";
import DownloadReports from "../pages/DownloadReports/DownloadReports";
import LiveJob from "../pages/LiveJob";
import NewBilling from "../pages/Billing/NewBilling.js";
import UniversityManageStudent from "../pages/University/UniversityManageStudent";
import UniversityManageCompany from "../pages/University/UniversityManageCompany";
import ServiceDesk from "../pages/ServiceDesk/ServiceDesk.js";
import { USER_DETAILS, userDetailsApi } from "../api/SignIn/action.js";
import { useHistory, useLocation } from "react-router-dom";
import { ALL_COLLEGE_SELECTED } from "../api/University/APIs/action.js";
import { allCollegeSelectedSelectorFn } from "../api/University/APIs/selector.js";

function App() {
  const dispatch = useDispatch();
  const theme_change = useSelector(themeSelectorFn);

  const trackingId = "UA-65993236-5";
  ReactGA.initialize(trackingId);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const collegeDetail = useSelector(collegeDetailsSelectorFn);
  const allData = useSelector(mainDetailsFn);

  useEffect(() => {
    if (collegeDetail)
      ReactGA.set({
        user_id: collegeDetail?.user_id,
      });
  }, [collegeDetail]);

  function RouteWithLayout({ layout, component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          React.createElement(
            layout,
            { ...props, appFirstLoadRef },
            React.createElement(component, props)
          )
        }
      />
    );
  }

  const checkForLayout = () => {
    if (isEducationGroup) return UniversityLayout;
    else if (isCollegeGroup) return CollegeGroupLayout;
    else return Layout;
  };

  const history = useHistory();
  const location = useLocation();
  const alert = useAlert();

  const publicRoutes = [
    "/",
    "/sign-in",
    "/create-new-password",
    "/create-new-password/:token",
    "/reset-new-password",
    "/forgot-password",
    "/reset-password-link-generated",
    "/reset-password",
    "/public/ejnf/:token?",
    "/public/student-form/:token",
    "/public/company-form/:token",
    "/public/track/:track_token",
    "/public/shared-student-details/:track_token",
    "/public/link-not-valid",
    "/public/college/profile/:college_id",
    "/public/team-form/:token?",
    "/public-job-details/:college_job_id",
    "/thank-you",
    "/ejd-company",
    "/testing",
  ];

  const isRoutePublic = (pathname) => {
    return publicRoutes.some((route) => {
      const routeRegex = new RegExp(
        `^${route.replace(/:[^\/?]+/g, "[^/]+").replace(/\?/g, "")}$`
      );
      return routeRegex.test(pathname);
    });
  };

  const appFirstLoadRef = useRef(true);

  useLayoutEffect(() => {
    if (
      location.pathname.includes("/college-group/") ||
      location.pathname.includes("/educational-group/")
    ) {
      dispatch(userDetailsApi(null, history, location, alert));
    } else if (!isRoutePublic(location.pathname) && appFirstLoadRef.current) {
      const collegeViewCollegeId = localStorage.getItem("college_id") || null;
      dispatch(userDetailsApi(collegeViewCollegeId, history, location, alert));
      appFirstLoadRef.current = false;
    }
  }, [location.pathname, history, alert]);

  return (
    <>
      <Helmet>
        <title>
          {!isEducationGroup
            ? collegeDetail?.college_name
            : allData?.college_name}
        </title>
        <meta
          name="description"
          content={
            !isEducationGroup
              ? collegeDetail?.college_name
              : allData?.college_name
          }
        />
        <link
          rel="icon"
          type="image/png"
          href={
            !isEducationGroup
              ? collegeDetail?.favicon
                ? collegeDetail?.favicon
                : favicon
              : allData?.favicon
              ? allData?.favicon
              : favicon
          }
          sizes="16x16"
        />
      </Helmet>

      {/* <ThemeProvider theme={Theme}> */}
      {theme_change ? (
        <Switch>
          <Route exact path="/" component={SignIn} />
          <Route exact path="/sign-in" component={SignIn} />
          <Route
            path="/create-new-password/:token"
            component={CreateNewPassword}
          />
          <Route path="/create-new-password" component={CreateNewPassword} />
          <Route path="/reset-new-password" component={NewPasswordLink} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route
            path="/reset-password-link-generated"
            component={PasswordResetLink}
          />
          <Route path="/reset-password" component={NewPassword} />

          <Route path="/public/ejnf/:token?" component={EjdCompany} />
          <Route path="/public/student-form/:token" component={StudentForm} />
          <Route path="/public/company-form/:token" component={CompanyForm} />
          <Route path="/public/track/:track_token" component={PublicTrack} />
          <Route
            // path="/public/SharedStudentDetails/:track_token"
            path="/public/shared-student-details/:track_token"
            component={StudentDetailsTrack}
          />
          <Route
            // path="/public/SharedStudentDetails/:track_token"
            path="/public/link-not-valid"
            component={LinkNotFound}
          />
          <Route
            path="/public/college/profile/:college_id"
            component={CompanyDetailView}
          />

          {/* <Route path="/reset-your-password" component={ResetPassword} /> */}
          <Route
            path="/public/team-form/:token?"
            component={TeamMemberInvite}
          />

          <Route
            // layout={PublicJobNavbar}
            path="/public-job-details/:college_job_id"
            component={PublicJobDetails}
          />

          <Route path="/thank-you" component={ThankYou} />
          <Route path="/ejd-company" component={EjdCompany} />
          <Route path="/testing" component={Testing} />

          <RouteWithLayout
            layout={checkForLayout()}
            path="/manage-ejnf/:token?"
            component={ManageEjnf}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/billing-info"
            component={NewBilling}
          />
          {/* <RouteWithLayout
                  layout={checkForLayout()}
                  path="/billing-info-new"
                  component={NewBilling}
                /> */}

          <RouteWithLayout
            layout={checkForLayout()}
            exact
            path="/home"
            component={Home}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            exact
            path="/dashboard"
            component={Dashboard}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            exact
            path="/overview"
            component={Dashboard}
          />
          {/* <RouteWithLayout layout={isEducationGroup?UniversityLayout:Layout} path="/manage-jobs" component={ManageJd} /> */}
          <RouteWithLayout
            layout={checkForLayout()}
            path="/placement-cycles"
            component={Placement}
          />
          {/* // <RouteWithLayout layout={isEducationGroup?UniversityLayout:Layout} path="/company" component={CompanyList} /> */}

          <RouteWithLayout
            layout={checkForLayout()}
            path="/student-erf"
            component={StudentUploader}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/company-erf"
            component={CompanyUploader}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/job-posting-form/:job_type?"
            component={ManageJobPosting}
          />

          <RouteWithLayout
            layout={checkForLayout()}
            path="/inbound-jobs"
            component={InboundJobs}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/manage-team"
            component={ManageTeam}
          />

          <RouteWithLayout
            layout={checkForLayout()}
            path="/create-event"
            component={ManageEvents}
          />

          <RouteWithLayout
            layout={Layout}
            // layout={checkForLayout()}
            path="/all-guest"
            component={AllGuest}
          />
          <RouteWithLayout
            layout={Layout}
            // layout={checkForLayout()}
            path="/total-member"
            component={TotalMember}
          />

          <RouteWithLayout
            layout={checkForLayout()}
            path="/track"
            component={Track}
          />

          <RouteWithLayout
            layout={checkForLayout()}
            path="/post-Job/:job_id?"
            component={PostJob}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/post-recommended-Job/:job_id?"
            component={CloneRecommended}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/student-profile/:user_id"
            component={StudentDetails}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/student-profile-request/:user_id"
            component={StudentDetails}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/pending-student-profile/:user_id"
            component={StudentDetails}
          />

          <RouteWithLayout
            layout={checkForLayout()}
            path="/company-profile/:company_id"
            component={CompanyDetails}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/pending-company-profile/:company_id"
            component={CompanyDetails}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/rejected-company-profile/:company_id"
            component={CompanyDetails}
          />

          {/* <RouteWithLayout layout={isEducationGroup?UniversityLayout:Layout} path="/oldHelp" component={Help} /> */}
          <RouteWithLayout
            layout={checkForLayout()}
            path="/Contact"
            component={Contact}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/job/:job_id"
            component={JobDetails}
          />

          <RouteWithLayout
            layout={checkForLayout()}
            path="/getwork-job/:job_id"
            component={GetworkJob}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/ejd-job/:job_id"
            component={JobDetails}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/drive"
            component={Drive}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/user-roles"
            component={Setting}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/manage-jobs"
            component={JobManage}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/jobs-recommended"
            component={RecommendedJobs}
            // component={JobRecommended}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/college-courses"
            component={Course}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/account"
            component={AccountSetting}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/college-profile"
            component={CollegeProfile}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/contact-thankyou"
            component={ContactThankYou}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/manage-companies"
            component={CompanyTrack}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/offcampus-add-student"
            component={AddOffCampusPlacement}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/offcampus-placement"
            component={ManageOffCampusNew}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/manage-students"
            component={StudentTrack}
          />
          {/* <RouteWithLayout
                  layout={checkForLayout()}
                  path="/campus-placement-reports"
                  component={CampusPlacementReports}
                /> */}
          <RouteWithLayout
            layout={checkForLayout()}
            path="/profile-update-request"
            component={ProfileUpdateRequest}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/add-students/"
            component={AddStudent}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/manage-off-campus"
            component={ManageOffCampus}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/team-profile/:team_member_id"
            component={TeamMemberProfile}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/interview"
            component={Interview}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/assignments"
            component={AssignmentTrack}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/questions/:question_id?"
            component={Question}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/student-drive/:user_id?"
            component={Drive}
          />

          <RouteWithLayout
            layout={checkForLayout()}
            path="/help"
            component={NewHelp}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/group_FAQs/:card_id?"
            component={Info}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/create-assignment"
            component={CreateAssignment}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/report"
            component={CampusPlacementReports}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/download-report"
            component={DownloadReports}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/job-details/:job_id"
            component={PostDetails}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/assignment-details"
            component={Assignment}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/eligible-students/:job"
            component={EligibleTrack}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/chat-room"
            component={ChatRoom}
          />
          {/* <RouteWithLayout layout={isEducationGroup?UniversityLayout:Layout} path="/chat-group" component={ChatGroup} /> */}
          <RouteWithLayout
            layout={checkForLayout()}
            path="/chat-group/:thread_id?"
            component={ChatGroup}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/interview-calendar"
            component={CalendarView}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/edit-company-profile/:company_id"
            component={EditCompanyProfile}
          />

          <RouteWithLayout
            layout={checkForLayout()}
            path="/chat-group"
            component={ChatGroup}
          />
          {/* <RouteWithLayout layout={isEducationGroup?UniversityLayout:Layout} path="/edit-company-profile" component={EditCompanyProfile} /> */}
          <RouteWithLayout
            layout={checkForLayout()}
            path="/placement-rules"
            component={Rules}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/create-interview"
            component={CreateInterview}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/team-member-registration-form"
            component={TeamMemberRegistrationForm}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/manage-event"
            component={AllEvents}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/live-jobs"
            component={LiveJob}
          />

          <RouteWithLayout
            layout={checkForLayout()}
            path="/brand-jobs"
            component={LiveJob}
          />
          {/* <RouteWithLayout
                layout={checkForLayout()}
                path="/campus-feed"
                component={CampusFeed}
              /> */}
          <RouteWithLayout
            layout={checkForLayout()}
            path="/campus-feed"
            component={NewFeed}
          />

          {/*Educational Groups Routes  */}

          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/dashboard"
            component={University}
          />
          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/account-settings"
            component={AccountSetting}
          />
          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/profile-settings"
            component={UniversitySetting}
          />
          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/interview-calendar"
            component={CalendarView}
          />
          {/* <RouteWithLayout layout={checkForLayout()} path="/educational-group/interview" component={Interview} /> */}
          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/ejd-link"
            component={UniversityEjdLink}
          />
          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/user-roles"
            component={UniversityUserRole}
          />
          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/team-erf"
            component={UniversityTeamErf}
          />
          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/post-job/:job_id?"
            component={UniversityPostJob}
          />
          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/college-courses"
            component={UniversityCourse}
          />
          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/team-link"
            component={UniversityTeamLink}
          />
          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/placement-rules"
            component={UniversityRulesNew}
          />
          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/manage-team"
            component={GroupTeam}
          />
          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/manage-ejnf"
            component={UniversityManageEJD}
          />
          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/student-link"
            component={UniversityStudentLink}
          />
          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/company-link"
            component={UniversityCompanyLink}
          />
          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/all-colleges"
            component={UniversityAllCollege}
          />
          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/assignments"
            component={UniversityAssignment}
          />
          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/report"
            component={ReportGroup}
          />
          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/placement-cycles"
            component={UniversityPlacementCycle}
          />
          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/manage-jobs"
            component={UniversityManageJobs}
          />

          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/eligible-students/:job"
            component={EligibleTrack}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/educational-group/assignment-details"
            component={Assignment}
          />

          <RouteWithLayout
            layout={checkForLayout()}
            path="/educational-group/track/"
            component={Track}
          />

          <RouteWithLayout
            layout={checkForLayout()}
            path="/educational-group/interview"
            component={Interview}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/educational-group/job/:job_id"
            component={JobDetails}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/educational-group/ejd-job/:job_id"
            component={JobDetails}
          />
          <RouteWithLayout
            layout={checkForLayout()}
            path="/educational-group/company-profile/:company_id"
            component={CompanyDetails}
          />

          {/* <RouteWithLayout
                  layout={UniversityLayout}
                  path="/educational-group/manage-students"
                  component={StudentGroupTrack}
                /> */}
          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/manage-students"
            component={UniversityManageStudent}
          />
          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/company-erf"
            component={UniversityCompanyERF}
          />
          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/job-posting-form"
            component={UniversityJobERF}
          />
          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/student-erf"
            component={UniversityStudentsERF}
          />
          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/student-profile/:user_id"
            component={StudentDetails}
          />
          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/contact-us"
            component={Contact}
          />
          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/campus-feed"
            component={NewsFeedGroup}
          />
          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/chat-room"
            component={ChatRoomUniversity}
          />
          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/help"
            component={NewHelp}
          />
          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/questions/:question_id?"
            component={Question}
          />
          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/group_FAQs/:card_id?"
            component={Info}
          />
          <RouteWithLayout
            layout={UniversityLayout}
            exact
            path="/educational-group/sub-groups"
            component={AllSubGroups}
          />
          <RouteWithLayout
            layout={UniversityLayout}
            exact
            path="/educational-group/sub-groups/group-details/:groupId?"
            component={AllGroupDetails}
          />

          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/view-teams"
            component={UniversityTeamManage}
          />
          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/view-teams"
            component={UniversityTeamManage}
          />

          <Route
            path="/public/educational-group/profile/:group_id"
            component={UniversityPublicProfile}
          />
          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/skills"
            component={HighlightedSkillsStudents}
          />
          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/degree-graph"
            component={DegreeCampusPlacement}
          />

          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/manage-companies"
            component={UniversityManageCompany}
          />
          {/* <RouteWithLayout
                  layout={UniversityLayout}
                  path="/educational-group/manage-companies"
                  component={CompanyTrackGroup}
                /> */}
          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/billing-info"
            component={BillingUniversity}
          />
          <RouteWithLayout
            layout={UniversityLayout}
            path="/educational-group/industry"
            component={HighlightedIndustry}
          />

          {/*Educational Groups Routes  */}

          {/*College Groups Routes  */}
          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/dashboard"
            component={University}
          />
          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/account-settings"
            component={AccountSetting}
          />
          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/profile-settings"
            component={UniversitySetting}
          />
          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/interview-calendar"
            component={CalendarView}
          />
          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/ejd-link"
            component={UniversityEjdLink}
          />
          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/user-roles"
            component={UniversityUserRole}
          />
          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/team-erf"
            component={UniversityTeamErf}
          />
          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/post-job/:job_id?"
            component={UniversityPostJob}
          />
          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/college-courses"
            component={UniversityCourse}
          />
          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/team-link"
            component={UniversityTeamLink}
          />
          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/placement-rules"
            component={UniversityRulesNew}
          />
          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/manage-team"
            component={GroupTeam}
          />
          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/manage-ejnf"
            component={UniversityManageEJD}
          />
          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/student-link"
            component={UniversityStudentLink}
          />
          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/company-link"
            component={UniversityCompanyLink}
          />
          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/all-colleges"
            component={UniversityAllCollege}
          />
          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/assignments"
            component={UniversityAssignment}
          />
          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/report"
            component={ReportGroup}
          />
          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/placement-cycles"
            component={UniversityPlacementCycle}
          />
          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/manage-jobs"
            component={UniversityManageJobs}
          />
          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/manage-students"
            component={UniversityManageStudent}
          />
          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/company-erf"
            component={UniversityCompanyERF}
          />
          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/job-posting-form"
            component={UniversityJobERF}
          />
          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/student-erf"
            component={UniversityStudentsERF}
          />
          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/contact-us"
            component={Contact}
          />
          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/campus-feed"
            component={NewsFeedGroup}
          />
          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/chat-room"
            component={ChatRoomUniversity}
          />
          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/help"
            component={NewHelp}
          />
          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/questions/:question_id?"
            component={Question}
          />
          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/group_FAQs/:card_id?"
            component={Info}
          />
          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/view-teams"
            component={UniversityTeamManage}
          />

          <Route
            path="/public/college-group/profile/:group_id"
            component={UniversityPublicProfile}
          />
          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/skills"
            component={HighlightedSkillsStudents}
          />
          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/degree-graph"
            component={DegreeCampusPlacement}
          />

          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/manage-companies"
            component={UniversityManageCompany}
          />
          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/billing-info"
            component={BillingUniversity}
          />
          <RouteWithLayout
            layout={CollegeGroupLayout}
            path="/college-group/industry"
            component={HighlightedIndustry}
          />

          <RouteWithLayout
            layout={checkForLayout()}
            path="/edit-student/:user_id"
            component={EditStudent}
          />

          <RouteWithLayout
            layout={checkForLayout()}
            path="/service-request"
            component={ServiceDesk}
          />

          <Route exact={true} path="*" component={NotFound} />
        </Switch>
      ) : (
        <Switch>
          <Route path="*" component={CircularProgressBar} />
        </Switch>
      )}
      {/* </ThemeProvider> */}
    </>
  );
}

export default App;
