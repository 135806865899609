import React from "react";
import { withStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import { mergeClasses } from "@material-ui/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    color: "#000",
  },
  drop: {
    padding: "5px",
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    borderRadius: "8px",
    // width: "150px",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    height: 30,
    "&:focus": {
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
      // borderRadius: 30,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        // color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function DropDownButton({
  title,
  placementSession,
  setPlacementSession,
  setFilterDataText,
  show,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const placementSessionList = useSelector(
    (state) => state.commonData.placementList
  );

  return (
    <div>
      <Button
        className={mergeClasses.title}
        aria-controls="customized-menu"
        style={{
          display: "flex",
          borderRadius: "8px",
          backgroundColor: "#e2e6ea",
          color: "#7a7e83",
          boxShadow: "none",
        }}
        aria-haspopup="true"
        variant="contained"
        size="small"
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
      >
        <Typography
          variant="overline"
          // style={{ fontSize: "14px" }}
          color="inherit"
        >
          {placementSession
            ? placementSession?.name
            : placementSessionList
            ? placementSessionList[0]?.name
            : null}
        </Typography>
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem
          selected={
            placementSession && placementSession.id === "All" ? true : false
          }
        >
          <Typography
            variant="overline"
            color="inherit"
            style={{
              // fontSize: "14px",
              color:
                placementSession && placementSession.id === "All"
                  ? theme.palette.primary.main
                  : "",
            }}
            onClick={() => {
              setPlacementSession({ id: "All", name: "All Placement Cycles" });
              handleClose();
              if (show) setFilterDataText("No Data Found");
            }}
          >
            All Placement Cycles
          </Typography>
        </StyledMenuItem>
        {placementSessionList?.map((item, index) => {
          return (
            <StyledMenuItem
              selected={
                !placementSession && index === 0
                  ? true
                  : placementSession && placementSession.id === item.id
                  ? true
                  : false
              }
              onClick={() => {
                setPlacementSession(item);
                handleClose();
                if (show) setFilterDataText("No Data Found");
              }}
            >
              <Typography
                variant="overline"
                style={{
                  // fontSize: "14px",
                  color:
                    !placementSession && index === 0
                      ? true
                      : placementSession && placementSession.id === item.id
                      ? theme.palette.primary.main
                      : "",
                }}
                color="inherit"
              >
                {item.name}
              </Typography>
            </StyledMenuItem>
          );
        })}

        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Typography variant="body2" className={classes.drop}>
            demo
          </Typography>
          <Typography variant="body2" className={classes.drop}>
            demo
          </Typography>
          <Typography variant="body2" className={classes.drop}>
            demo
          </Typography>
          <Typography variant="body2" className={classes.drop}>
            demo
          </Typography>
        </div> */}
      </StyledMenu>
    </div>
  );
}
