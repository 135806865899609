import React from "react";
import { Divider, Typography, makeStyles, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    justifyContent: "space-around",
    borderRadius: theme.shape.borderRadius,
    marginTop: 45,
    marginBottom: 10,
    color: theme.palette.text.secondary,
    "& svg": {
      margin: theme.spacing(1.5),
    },
    "& hr": {
      margin: theme.spacing(0, 0.5),
    },
  },
}));
function TrustUs({ details }) {
  const classes = useStyles();
  return (
    <div
      style={{
        background:
          "linear-gradient(25.12deg, #282828 -36.21%, #14213D 123.58%)",
        borderRadius: 8,
        padding: 10,
        height: 160,
      }}
    >
      <Typography variant="h4" style={{ fontWeight: "600", color: "#fff" }}>
        {details?.company_name} <span style={{ color: "#fff" }}>Trusts us</span>
      </Typography>
      <Grid container alignItems="center" className={classes.root}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h3" style={{ fontWeight: "600", color: "#fff" }}>
            {details?.create_time ? details?.create_time?.slice(0, 4) : "NA"}
          </Typography>
          <Typography variant="body2" style={{ color: "#fff" }}>
            Hiring Since
          </Typography>
        </div>
        <Divider
          orientation="vertical"
          flexItem
          style={{ backgroundColor: "#fff" }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h3" style={{ fontWeight: "600", color: "#fff" }}>
            {details?.job_posted_count
              ? `${details?.job_posted_count} Jobs`
              : "NA"}
          </Typography>
          <Typography variant="body2" style={{ color: "#fff" }}>
            Opportunities Posted
          </Typography>
        </div>
      </Grid>
    </div>
  );
}

export default TrustUs;
