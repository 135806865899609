import React, { Component } from "react";
import Charst from "react-apexcharts";
import { Paper, Typography, Button } from "@material-ui/core";
import Course from "../../Course";
import NoDataFunc from "../../../../components/NoDataSVG/index1";

export default class Degree extends Component {
  constructor(props) {
    super(props);

    const { data, filterZeroData = false } = props;
    const categories = data?.categories || [];
    const series = data?.series_list || [];

    let filteredCategories = categories;
    let filteredSeries = series;

    if (filterZeroData) {
      const validIndices = categories
        .map((_, index) => index)
        .filter((i) => series.some((s) => s.data[i] > 0));

      filteredCategories = validIndices.map((i) => categories[i]);
      filteredSeries = series
        .map((s) => ({
          ...s,
          data: validIndices.map((i) => s.data[i]),
        }))
        .filter((s) => s.data.length > 0);
    }

    this.state = {
      options: {
        grid: {
          xaxis: { lines: { show: false } },
          yaxis: { lines: { show: false } },
        },
        chart: {
          id: "apexchart-example",
          toolbar: { show: false },
        },
        xaxis: {
          categories: filteredCategories,
        },
        yaxis: {
          title: {
            text: "Degree",
            style: {
              fontSize: "14px",
              fontFamily: "Nunito",
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: "75%",
            borderRadius: 8,
          },
        },
        dataLabels: { enabled: false },
        legend: { show: false },
      },
      series: filteredSeries,
      filterZeroData,
    };
  }

  render() {
    const { series, filterZeroData } = this.state;

    if (
      filterZeroData &&
      (!series.length || series.every((s) => s.data.every((val) => val === 0)))
    ) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <NoDataFunc width={"300Px"} height={"200px"} />
          <Typography style={{ fontSize: "16px" }}>
            {" "}
            No Placement Data Available to Display.
          </Typography>{" "}
        </div>
      );
    }

    return (
      <Charst
        options={this.state.options}
        series={this.state.series}
        type="bar"
        height={600}
      />
    );
  }
}
