import { makeStyles } from "@material-ui/core/styles";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { themeSelectorFn } from "../../../api/Domain/selector";
import { ReactComponent as NoData } from "../../../assets/svg/New entries-rafiki.svg";
import { useAlert } from "react-alert";
import { useHistory, useLocation } from "react-router-dom";
import {
  PLACEMENT_CYCLES,
  PlacementCyclesGetAPI,
} from "../../../api/PlacementCycles/action";

const useStyles = makeStyles((theme) => ({
  lay: {
    width: "100%",
    "@media only screen and (min-width: 2000px)": {
      height: "600px",
    },
    "@media only screen and (max-width: 2000px)": {
      height: "400px",
    },
    "@media only screen and (max-width: 1700px)": {
      height: "370px",
    },
    "@media only screen and (max-width: 1300px)": {
      height: "370px",
    },
  },

  root: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function PlacementTable({
  list,
  columns,
  setSelectedRowsData,
  selectedID,
  setSelectedID,
  filterDataText,
  checkBoxSelection = true,
  fromStudentDetials = false,
  isPaginated = true,
  setList,
  loading,
  pageData,
  setPageData,
  listTotalCount,
}) {
  const theme_change = useSelector(themeSelectorFn);
  const classes = useStyles();

  return (
    <div className={classes.lay}>
      <DataGrid
        style={{
          borderRadius: "8px",
          backgroundColor: "#fff",
          border: "none",
          textAlign: "center",
        }}
        rows={list?.length > 0 ? list : []}
        columns={columns}
        selectionModel={selectedID}
        page={pageData?.currentPage}
        slots={{
          toolbar: GridToolbar,
        }}
        onSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);

          const selectedRowData = list?.filter((row) =>
            selectedIDs.has(row?.id?.toString())
          );
          //   if (!selectedIDs?.length || selectedIDs?.length > 1) {
          //     setEdit(false);
          //   } else {
          //     setEdit(true);
          //     setSelectedRowsData([]);
          //   }

          setSelectedRowsData(selectedRowData);
          setSelectedID(ids);
        }}
        disableColumnSelector
        pageSize={pageData ? pageData?.pageSize : 20}
        onPageSizeChange={
          pageData
            ? (newPageSize) => {
                setPageData((pageData) => ({
                  ...pageData,
                  pageSize: newPageSize,
                  currentPage: 0,
                  // Math.ceil(
                  //   ((pageData.currentPage + 1) * pageData.pageSize) /
                  //     newPageSize
                  // ), //Math.floor(list.length / newPageSize),
                }));
                setList([]);
              }
            : null
        }
        // pageSize={pageData?.pageSize ?? pageSize}
        // page={pageData?.currentPage ? pageData?.currentPage : null}
        onPageChange={
          pageData
            ? (page) => {
                setPageData({
                  ...pageData,
                  previousPage: pageData?.currentPage,
                  currentPage: page,
                });
              }
            : null
        }
        // onPageSizeChange={
        //   pageData
        //     ? (newPageSize) =>
        //         setPageData({
        //           ...pageData,
        //           pageSize: newPageSize,
        //           currentPage: 0,
        //         })
        //     : (newPageSize) => setPageSize(newPageSize)
        // }
        rowsPerPageOptions={[5, 10, 15, 20]}
        hideFooterPagination={!isPaginated || loading}
        hideFooterSelectedRowCount={!isPaginated}
        pagination
        {...list}
        checkboxSelection={fromStudentDetials ? false : checkBoxSelection}
        disableSelectionOnClick
        rowCount={listTotalCount}
        loading={loading}
        componentsProps={{
          pagination: {
            style: {
              display: "flex",
              justifyContent: "center",
              margin: "auto",
            },
          },
        }}
        components={{
          NoRowsOverlay: () => (
            <>
              <br /> <br />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "auto",
                  paddingTop: 30,
                }}
              >
                <NoData
                  height="180px"
                  width="180px"
                  alt="404 Error"
                  fill={theme_change?.main ? theme_change?.main : "#007BFF"}
                />

                <span align="center" style={{ fontSize: 14, color: "#6c757d" }}>
                  <b>
                    {" "}
                    {filterDataText ? filterDataText : "No Data Available"}
                  </b>
                </span>
              </div>
            </>
          ),
        }}
      />
    </div>
  );
}
