import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import CollegeImage from "../../assets/img/company.jpg";
import NewPasswordForm from "../../components/Input/NewPasswordForm";
import ResetyourPassword from "../../components/Input/ResetyourPassword";
import RightSideComponent from "../../components/Public/LeftSideComponent";
import LeftSideComponent from "../../components/Public/RightSideComponent";
import { userLoggedInSelectorFn } from "../../api/Domain/selector";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    // marginTop: "50px",
  },
  image: {
    backgroundImage: `url(${CollegeImage})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
}));

export default function NewPassword() {
  const classes = useStyles();
  const [nextStep, setNextStep] = useState(0);
  const history = useHistory();
  const location = useLocation();
  const isLoggedIn = useSelector(userLoggedInSelectorFn);

  React.useEffect(() => {
    const temp = location.pathname.split("/");
    if (isLoggedIn) {
      history.push("dashboard");
      return;
    }
    if (!temp[2] && !temp[3]) history.push("/");
  }, []);
  return (
    <>
      <>
        <Grid container component="main" className={classes.root}>
          <RightSideComponent />
          <LeftSideComponent
            ChildComponent={
              nextStep === 0 ? NewPasswordForm : ResetyourPassword
            }
            nextStep={nextStep}
            setNextStep={setNextStep}
          />
        </Grid>
      </>
      )
      {/* : (
        <ResetPassword />
      )} */}
    </>
  );
}
