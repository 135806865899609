const dev = {
  apis: {
    pravesh: "http://praveshtest4.getwork.org/",
    niyukti: "http://niyuktitest3.getwork.org/",
    sujhav: "http://sujhavtest.getwork.org/",
    // sanchalan: "http://127.0.0.1:8000/",
    sanchalan: "http://sanchalantest2.getwork.org/",
    //sanchalan: "http://sanchalantest.getwork.org/",
    cms: "http://cmstest.getwork.org:1337",
  },

  app: "http://appreacttest.getwork.org",
  next_app: "http://test.getwork.org",

  facebookAppId: "125784129104913",
  facebookClientSecret: "0f1138a98b24c282c580dff5d808243d",
  googleClientId:
    "864459248480-290lhp44ijnm6kearq3vu15m344el56d.apps.googleusercontent.com",
  maintainance: false,
  prod: false,
};

const prod = {
  apis: {
    pravesh: "https://pravesh1.getwork.org/",
    niyukti: "https://niyukti.getwork.org/",
    sujhav: "https://sujhav.getwork.org/",
    // sanchalan: "http://127.0.0.1:8000/",
    sanchalan: "https://sanchalan.getwork.org/",
    cms: "https://cms.getwork.org",
  },
  app: "https://app.getwork.org",
  next_app: "https://getwork.org",

  facebookAppId: "3527199753979643",
  facebookClientSecret: "985184d9659e8d8d4e829cd6cbca1b6a",
  googleClientId:
    "864459248480-290lhp44ijnm6kearq3vu15m344el56d.apps.googleusercontent.com",
  maintainance: false,
  prod: true,
};

const config = process.env.REACT_APP_ENVIRONMENT === "production" ? prod : dev;

export default {
  // Add common config values here
  ...config,
};
