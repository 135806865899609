import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import pSBC from "shade-blend-color";
import { groupViewSelectorFn } from "../../../api/Common/selector";
import {
  collegeIdFn,
  entityInfoSelectorFn,
} from "../../../api/SignIn/selector";
import { IconSelector } from "../../../utility/iconSelector";
import { replaceEntityName } from "../../../utility";

const useStyles = makeStyles((theme) => ({
  text: {
    marginLeft: "16px",
    marginRight: "16px",
  },
  sidebarlink: {
    display: "flex",
    color: theme.palette.secondary.main,
    padding: "8px 0px 8px 16px",
    textDecoration: "none",
    listStyle: "none",

    height: "50px",
    alignItems: "center",
    padding: "0 16px",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: pSBC(0.7, theme.palette.primary.main),
      textDecoration: "none",
      color: "#fff",
    },
  },
  blueSideBar: {
    backgroundColor: theme.palette.primary.main,
    textDecoration: "none",
    color: "#fff",

    display: "flex",
    padding: "8px 0px 8px 16px",
    listStyle: "none",

    height: "50px",
    alignItems: "center",
    padding: "0 16px",
    borderRadius: "8px",
  },
  subNavSelected: {
    backgroundColor: pSBC(0.7, theme.palette.primary.main),
    textDecoration: "none",
    color: "#fff",

    display: "flex",
    padding: "8px 0px 8px 16px",
    listStyle: "none",

    height: "50px",
    alignItems: "center",
    padding: "0 16px",
    borderRadius: "8px",
  },
  dropdownlink: {
    display: "flex",
    color: "#060b26",
    textDecoration: "none",
    listStyle: "none",
    height: "30px",
    color: theme.palette.secondary.main,
    alignItems: "center",
    padding: "0px 16px 0px 55px",
    justifyContent: "flex-start",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: pSBC(0.7, theme.palette.primary.main),
      textDecoration: "none",
      color: "#fff",
    },
  },
  blueLink: {
    display: "flex",
    textDecoration: "none",
    listStyle: "none",
    height: "30px",
    alignItems: "center",
    padding: "0px 16px 0px 55px",
    justifyContent: "flex-start",
    borderRadius: "8px",

    backgroundColor: theme.palette.primary.main,
    textDecoration: "none",
    color: "#fff",
  },
}));

const UniversitySubMenu = ({ items, key }) => {
  const [subnav, setSubnav] = useState(false);
  const classes = useStyles();
  const location = useLocation();
  const [item, setItem] = useState({});

  const [navbarClicked, setNavbarClicked] = useState(false);
  const collegeId = useSelector(collegeIdFn);
  const groupView = useSelector(groupViewSelectorFn);
  const entityInfo = useSelector(entityInfoSelectorFn);
  const showSubnav = () => {
    setSubnav(!subnav);
  };

  useEffect(() => {
    if (location.pathname === items.path)
      setItem({
        ...items,
        selected: true,
      });
    else
      setItem({
        ...items,

        selected: false,
      });

    if (items.subNav && items.subNav.length) {
      items.subNav.forEach((i) => {
        if (i.path === location.pathname) i["selected"] = true;
        else i["selected"] = false;
      });
    }
  }, [items, location.pathname]);

  return (
    <>
      <div
        onMouseEnter={() => {
          if (item.subNav) showSubnav();
        }}
        onMouseLeave={() => {
          if (item.subNav) showSubnav();
        }}
      >
        {" "}
        <Link
          className={
            item.selected
              ? classes.blueSideBar
              : item.subNav &&
                item.subNav.length &&
                item.subNav.some((i) => i.path === location.pathname)
              ? classes.subNavSelected
              : classes.sidebarlink
          }
          style={{ width: "220px" }}
          to={groupView && item?.college_path ? item.college_path : item.path}
          onClick={() => {
            setNavbarClicked(true);
            if (item.subNav) showSubnav();
          }}
        >
          <div style={{ display: "contents" }}>
            {/* {item.icon} */}
            {IconSelector(item.type)}
            <Typography
              variant="body2"
              color="inherit"
              className={classes.text}
            >
              {replaceEntityName(item?.title, entityInfo)}
            </Typography>
          </div>
          <div style={{ display: "flex" }}>
            {item.subNav && subnav
              ? IconSelector("dropdown_open")
              : item.subNav
              ? IconSelector("dropdown_closed")
              : null}
          </div>
        </Link>
        {subnav &&
          item.subNav.map((item, index) => {
            return (
              ((collegeId && item?.show_college) || !collegeId) && (
                <Link
                  className={
                    item.selected ? classes.blueLink : classes.dropdownlink
                  }
                  to={
                    groupView && item?.college_path
                      ? item.college_path
                      : item.path
                  }
                  key={index}
                >
                  {item.icon}
                  <Typography variant="body2" color="inherit">
                    {groupView && item?.college_title
                      ? replaceEntityName(item?.college_title, entityInfo)
                      : replaceEntityName(item?.title, entityInfo)}
                  </Typography>
                </Link>
              )
            );
          })}
      </div>
    </>
  );
};

export default UniversitySubMenu;
