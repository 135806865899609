import {
  Typography,
  Box,
  useTheme,
  Divider,
  Grid,
  Link,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import styled from "@emotion/styled";
import { Rating } from "@material-ui/lab";
import CompanyLogo from "../../../assets/img/company.jpg";
import TrustUs from "./TrustUs";
import HrContactDetails from "./HrContactDetails";
import Facebook from "../../../assets/svg/facebook.svg";
import Glassdoor from "../../../assets/svg/glassdoor.svg";
import Linkedin from "../../../assets/svg/linkedin.svg";
import ReactHtmlParser from "react-html-parser";
import Insta from "../../../assets/svg/instagram.svg";
import Twi from "../../../assets/svg/twi.svg";
import { BackendBaseApi } from "../../../constants/constants";
import { useAlert } from "react-alert";
import axios from "axios";
import CompanyCardSkelton from "./CompanyCardSkelton";
import {
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../../api/SignIn/selector";
import { useSelector } from "react-redux";

const aiEffectSvg = (
  <svg
    width="66"
    height="69"
    viewBox="0 0 66 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.7326 36.2291C16.5539 37.477 13.2715 40.9554 12.0708 45.3658C10.8604 40.9678 7.5865 37.4854 3.42094 36.2327C7.59954 34.9848 10.882 31.5065 12.0827 27.0962C13.2931 31.4941 16.567 34.9764 20.7326 36.2291Z"
      stroke="#717171"
    />
    <path
      d="M52.854 19.0872C45.569 20.7614 39.8365 26.4923 38.1703 33.7887C36.4844 26.5128 30.7655 20.7724 23.502 19.091C30.7868 17.4168 36.5193 11.6859 38.1856 4.38961C39.8715 11.6654 45.5904 17.4058 52.854 19.0872Z"
      stroke="#717171"
    />
    <path
      d="M61.018 52.2011C55.1586 53.6759 50.5476 58.2859 49.0788 64.1541C47.595 58.3024 42.9954 53.6857 37.1536 52.2048C43.0129 50.73 47.6239 46.1201 49.0928 40.2519C50.5766 46.1036 55.1762 50.7203 61.018 52.2011Z"
      stroke="#717171"
    />
  </svg>
);

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: " #FFD700",
  },
  "& .MuiRating-iconHover": {
    color: " #FFD700",
  },
});

function CompanyCard({
  hrDetails,
  collegeId,
  companyId,
  setHrDetails,
  isLiveJobs,
  pageloading,
  allJobs,
  setAllJobs,
}) {
  const theme = useTheme();
  const [details, setDetails] = useState(null);
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [unlock, setUnlock] = useState(false);
  const [socialLinks, setSocialLinks] = useState([]);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);

  useEffect(() => {
    if (
      (collegeId && companyId) ||
      (companyId && isEducationGroup) ||
      (companyId && isCollegeGroup)
    ) {
      let url = `${BackendBaseApi.PRAVESH}api/saas/college/company_details/?company_id=${companyId}&is_live_job=${isLiveJobs}`;
      if (collegeId) url += `?college_id=${collegeId}`;
      setLoading(true);
      axios
        .get(url, { withCredentials: true })
        .then((res) => {
          if (res?.data?.success) setDetails({ ...res?.data?.data[0] });
          else alert.success(res?.data?.error);
          setLoading(false);
        })
        .catch((err) => {
          alert.error("something went wrong");
          setLoading(false);
        });
    }
  }, [collegeId, companyId, unlock, hrDetails, isLiveJobs]);

  useEffect(() => {
    if (details?.social_links?.length > 0) {
      const arr = [...details?.social_links];
      arr.forEach((item, index) => {
        if (item?.Name === "LinkedIn") {
          arr[index]["url"] = social[0];
        } else if (item?.Name === "Facebook") {
          arr[index]["url"] = social[3];
        } else if (item?.Name === "Instagram") {
          arr[index]["url"] = social[1];
        } else if (item?.Name === "Twitter") {
          arr[index]["url"] = social[2];
        } else if (item?.Name === "Glassdoor") {
          arr[index]["url"] = social[4];
        }
        setSocialLinks([...arr]);
      });
    }
  }, [details]);

  function stripHtmlTags(htmlString) {
    // Regular expression to match HTML tags and &nbsp; entities
    const regex = /<[^>]*>|&nbsp;/g;
    // Remove HTML tags and &nbsp; entities from the string
    return htmlString.replace(regex, "");
  }

  const jobRoleData = [
    {
      icons: (
        <CalendarTodayRoundedIcon style={{ color: "#fff", height: "18px" }} />
      ),
      title: details?.year_founded
        ? `Founded in ${details?.year_founded?.slice(0, 4)}`
        : "NA",
    },
    {
      icons: <FmdGoodOutlinedIcon style={{ color: "#fff", height: "18px" }} />,
      title: details?.address ? `${details?.city},${details?.state}` : "NA",
    },
    {
      icons: (
        <PermIdentityOutlinedIcon style={{ color: "#fff", height: "18px" }} />
      ),
      title: details?.size ? `${details?.size} Employees` : "NA",
    },
    {
      icons: <LanguageOutlinedIcon style={{ color: "#fff", height: "18px" }} />,
      title: (
        <Link
          href={details?.website}
          style={{ textDecoration: "none", color: "#fff" }}
          target="_blank"
        >
          {details?.website ? "Website" : "NA"}
        </Link>
      ),
    },
  ];

  const social = [Linkedin, Insta, Twi, Facebook, Glassdoor];

  return loading || pageloading ? (
    <>
      <CompanyCardSkelton />
    </>
  ) : companyId ? (
    <div
      style={{
        width: "100%",
        borderRadius: 10,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "stretch",
        backgroundColor: "#fff",
        position: "relative",
      }}
    >
      <div style={{ position: "relative", width: "100%" }}>
        <div
          style={{
            width: "100%",
            display: "flex",

            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              position: "relative",
              backgroundColor: "#282828",
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <Grid container spacing={2} style={{ margin: 0, width: "100%" }}>
              <Grid item>
                <Box
                  style={{
                    border: "1px solid #e1e1e1",
                    padding: 5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 6,
                    height: 54,
                    width: 54,
                  }}
                >
                  <img
                    alt="company logo"
                    src={details?.logo}
                    style={{
                      height: "100%",
                      width: "100%",
                      aspectRatio: "3/2",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid
                    item
                    xs
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography
                      variant="h4"
                      style={{ fontWeight: 600, color: "#fff" }}
                    >
                      {details?.company_name}
                    </Typography>
                    <Typography variant="h6" style={{ color: "#fff" }}>
                      {details?.industry_name}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",

                    // marginTop: "15px",
                  }}
                >
                  <Grid container spacing={2}>
                    {jobRoleData.map((item) => (
                      <Grid item md={3}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            // marginRight: "10%",
                          }}
                        >
                          {item.icons}{" "}
                          <Typography
                            noWrap
                            style={{
                              fontSize: 14,
                              marginLeft: 5,
                              color: "#fff",
                            }}
                          >
                            {item.title}
                          </Typography>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </Grid>
            </Grid>
            <div
              style={{
                position: "absolute",
                right: 30,
                top: 15,
                backgroundColor: theme.palette.primary.main,
                borderRadius: 30,
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 10,
                zIndex: 2,
                paddingRight: 10,
              }}
            >
              <Typography style={{ color: "#fff", fontSize: 14 }}>
                {" "}
                {details?.category ?? "NA"}{" "}
              </Typography>
            </div>
            <div style={{ position: "absolute", right: 0, top: 0 }}>
              {aiEffectSvg}
            </div>
          </div>

          <Grid
            container
            spacing={2}
            style={{
              marginTop: 10,
              margin: 0,
              width: "100%",
            }}
          >
            <Grid item md={6} style={{ paddingLeft: 0 }}>
              <TrustUs details={details} />
            </Grid>
            <Grid item md={6} style={{ paddingRight: 0 }}>
              {!window.location.toString().includes("brand-jobs") && (
                <HrContactDetails
                  details={details}
                  setHrDetails={setHrDetails}
                  collegeId={collegeId}
                  companyId={companyId}
                  unlock={unlock}
                  setUnlock={setUnlock}
                  allJobs={allJobs}
                  setAllJobs={setAllJobs}
                />
              )}
            </Grid>
          </Grid>
          {details?.reviews_rating?.length ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                marginTop: "15px",
              }}
            >
              <Typography
                style={{ fontSize: "16px", fontWeight: "600", color: "#000" }}
              >
                Reviews
              </Typography>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  marginTop: 10,
                }}
              >
                {details?.reviews_rating?.map((item) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 7,
                      paddingTop: 6,
                      paddingBottom: 6,
                      paddingLeft: 12,
                      paddingRight: 12,
                      borderRadius: "8px",
                      backgroundColor: theme.palette.primary.main,
                    }}
                  >
                    <Typography variant="body2" style={{ color: "#fff" }}>
                      {item?.name}
                    </Typography>

                    <StyledRating
                      defaultValue={item?.rating}
                      size="small"
                      readOnly
                      precision={0.5}
                      style={{ paddingLeft: 8, paddingRight: 8 }}
                    />

                    <Typography variant="body2" style={{ color: "#fff" }}>
                      {item?.rating}
                    </Typography>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          {socialLinks.length || details?.about ? (
            <div
              style={{
                marginTop: 15,
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Typography
                color="primary"
                style={{ fontSize: "16px", fontWeight: "600", color: "#000" }}
              >
                About The Company
              </Typography>
              {socialLinks.length ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  {socialLinks?.map((item) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: 10,
                        cursor: "pointer",
                      }}
                    >
                      <a href={item?.value} target="_blank">
                        <img src={item?.url} />
                      </a>
                    </div>
                  ))}
                </div>
              ) : null}
              {details?.about ? (
                <Typography
                  variant="body2"
                  style={{ fontSize: "14px", color: "#000", marginTop: 10 }}
                >
                  {stripHtmlTags(details?.about)}
                </Typography>
              ) : null}
            </div>
          ) : null}

          {details?.perks_benefit?.length ? (
            <div
              style={{
                marginTop: 15,
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Typography
                color="primary"
                style={{ fontSize: "16px", fontWeight: "600", color: "#000" }}
              >
                Perks & Benefits
              </Typography>

              <div
                style={{
                  // display: "flex",
                  // flexDirection: "column",
                  // alignItems: "flex-start",
                  marginTop: 10,
                  // display: "block",
                  width: "100%",
                }}
              >
                {details?.perks_benefit?.map((item) => (
                  <ul style={{ paddingLeft: 15 }}>
                    <li>
                      <Typography style={{ fontSize: "14px" }}>
                        {item}
                      </Typography>
                    </li>
                  </ul>
                ))}
              </div>
            </div>
          ) : null}

          {details?.founders_team?.length ? (
            <div
              style={{
                marginTop: 25,
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Typography
                color="primary"
                style={{ fontSize: "16px", fontWeight: "600", color: "#000" }}
              >
                Founders Team
              </Typography>

              <Grid container spacing={2} style={{ marginTop: 1 }}>
                {details?.founders_team?.map((item) => (
                  <Grid item md={6}>
                    <div
                      style={{
                        boxShadow: "0px 0px 4.5px 0px #0000001A",
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          width: 6,
                          borderTopRightRadius: 7,
                          borderBottomRightRadius: 7,
                          height: "100px",
                          backgroundColor: item.color,
                          marginRight: 15,
                        }}
                      />
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div>
                          <img
                            src={item?.image}
                            style={{
                              height: 70,
                              width: 70,
                              borderRadius: "50%",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "center",
                            marginLeft: 15,
                          }}
                        >
                          <Typography style={{ fontSize: 16, fontWeight: 600 }}>
                            {item?.name}
                          </Typography>
                          <Typography style={{ fontSize: 16 }}>
                            {" "}
                            {item?.designation}
                          </Typography>
                        </div>
                      </div>

                      <div
                        style={{
                          position: "absolute",
                          right: "20px",
                          top: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => window.open(item?.social_link)}
                      >
                        <img
                          src={Linkedin}
                          alt="social-link"
                          style={{ height: "20px", width: "20px" }}
                        />
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  ) : (
    ""
  );
}

export default CompanyCard;
