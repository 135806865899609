import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import PublishIcon from "@material-ui/icons/Publish";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  Typography,
  LinearProgress,
  TextField,
  Chip,
  Paper,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import Csv from "../../../assets/icons/csv.png";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TagFacesIcon from "@material-ui/icons/TagFaces";
import pSBC from "shade-blend-color";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { Autocomplete } from "@material-ui/lab";
import { useAlert } from "react-alert";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 8,
    borderRadius: 8,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 8,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "8px",
  },
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: "100%",
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "none",
  },
  pro: {
    width: "100%",
  },
  button: {
    borderRadius: "8px",
    // margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "45px",
    // paddingLeft: "50px",
    /// paddingRight: "50px",
    display: "flex",
  },
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",

    // border:"1px solid #007bff"
  },
  chipsection: {
    //  display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.7),
    },
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  move: {
    boxShadow: "none",
    borderRadius: "8px",
    height: "40px",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
}));

export default function MoveStep({
  moveNextArray,
  selectedRowsData,
  setSelectedRowsData,
  setSelectedID,
  moveToNext,
  setMoveToNext,
  MoveToNextStep,
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });
  const handleChange = (event) => {
    const name = event.target.name;
    setMoveToNext({
      ...moveToNext,
      round_id: event.target.value,
    });
    setStepSelectError("");
  };
  const [showMore, setShowMore] = useState(false);
  const [typeFeedbackError, setTypeFeedbackError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const alert = useAlert();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [stepSelectError, setStepSelectError] = useState(null);
  const [ctcError, setCtcError] = useState(null);
  const handleDelete = (id, index) => {
    var arr = [...selectedRowsData];
    var ids = [];
    if (index !== -1) {
      arr.splice(index, 1);
      setSelectedRowsData(arr);
      arr.map((item) => {
        ids.push(item.id);
      });
      setSelectedID(ids);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const feedbackList = [
    { name: "Good Candidate", value: "Good Candidate" },
    { name: "Other", value: "Other" },
  ];

  const validateData = () => {
    var is_valid = true;
    var step_error = null;
    var type_error = null;
    var ctcError = null;
    if (!moveToNext?.round_id) {
      step_error = "Please Select Next Step";
      is_valid = false;
    }
    if (
      moveToNext?.feedback &&
      moveToNext?.feedback?.name?.toLowerCase() === "other" &&
      !moveToNext?.feedback_typed
    ) {
      type_error = "Please Type your feedback";
      is_valid = false;
    }
    const ctcValue = Number(moveToNext?.ctc);
    if (
      (moveToNext?.round_name?.toLowerCase() === "selected" ||
        moveToNext?.round_name?.toLowerCase() === "joined") &&
      moveToNext?.ctc
    ) {
      const ctcValue = Number(moveToNext.ctc);

      if (isNaN(ctcValue) || moveToNext.ctc.includes("-") || ctcValue <= 0) {
        ctcError = "Please enter a valid CTC (positive number)";
        is_valid = false;
        moveToNext.ctc = "";
      }
    }

    setStepSelectError(step_error);
    setTypeFeedbackError(type_error);
    setCtcError(ctcError);

    return is_valid;
  };

  const handleSubmit = () => {
    if (validateData()) {
      MoveToNextStep(setLoading);
    }
  };

  return (
    <div>
      <Button
        color="primary"
        size="small"
        variant="contained"
        onClick={handleClickOpen}
        className={classes.move}
      >
        Move to the Next Step
      </Button>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        PaperProps={{ classes: { root: classes.root1 } }}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div style={{ padding: "20px", width: "500px" }}>
          <div
            style={{
              padding: "10px",
              paddingLeft: "10px",
              // paddingBlockEnd: "40px",
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "row",
            }}
          >
            <Typography variant="h6">Move to the Next Step</Typography>
          </div>

          <div component="ul" className={classes.root}>
            {!showMore
              ? selectedRowsData.slice(0, 5).map((data, index) => {
                  let icon;

                  // if (data.label === "React") {
                  //   icon = <TagFacesIcon />;
                  // }

                  return (
                    <li key={data.id}>
                      <Chip
                        variant="outlined"
                        color="primary"
                        size="small"
                        onDelete={() => handleDelete(data.id, index)}
                        deleteIcon={<CloseIcon />}
                        label={data.name}
                        className={classes.chip}
                      />
                    </li>
                  );
                })
              : selectedRowsData.map((data, index) => {
                  let icon;

                  // if (data.label === "React") {
                  //   icon = <TagFacesIcon />;
                  // }

                  return (
                    <li key={data.id}>
                      <Chip
                        variant="outlined"
                        color="primary"
                        size="small"
                        onDelete={() => handleDelete(data.id, index)}
                        deleteIcon={<CloseIcon />}
                        label={data.name}
                        className={classes.chip}
                      />
                    </li>
                  );
                })}
            {selectedRowsData?.length > 5 && (
              <Button
                className={classes.button}
                onClick={() => {
                  setShowMore(!showMore);
                }}
                style={{
                  padding: "5px",
                  marginLeft: "7px",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                endIcon={showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                color="primary"
              >
                {showMore ? "Hide" : "View All"}
              </Button>
            )}
            <FormControl
              variant="outlined"
              className={classes.formControl}
              error={stepSelectError ? true : false}
            >
              <InputLabel className={classes.floatingLabelFocusStyle}>
                Select Next Step
              </InputLabel>
              <Select
                value={moveToNext.round_id}
                // onChange={handleChange}
                label="Select Next Step"
                inputProps={{
                  style: {
                    padding: "16px",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                  },
                }}
              >
                {moveNextArray?.map((item) => (
                  <MenuItem
                    value={item.round_id}
                    name={item.name}
                    onClick={() => {
                      setMoveToNext({
                        ...moveToNext,
                        round_name: item.name,
                        round_id: item.round_id,
                      });
                    }}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{stepSelectError}</FormHelperText>
            </FormControl>
            {(moveToNext?.round_name?.toLowerCase() === "selected" ||
              moveToNext?.round_name?.toLowerCase() === "joined") && (
              <TextField
                id="outlined-ctc-input"
                label="Enter Offered CTC"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                style={{
                  width: "100%",
                  marginBottom: "5px",
                }}
                variant="outlined"
                value={moveToNext?.ctc}
                onChange={(e) => {
                  const value = e.target.value;

                  if (value === "" || !isNaN(value)) {
                    setMoveToNext({
                      ...moveToNext,
                      ctc: value,
                    });
                  }
                }}
                error={ctcError ? true : false}
                helperText={ctcError}
              />
            )}

            <Typography variant="h6">
              Do you want to enter the feedback for Selected Applicant(s)?
            </Typography>
            <Autocomplete
              id="country-select-demo"
              options={feedbackList}
              getOptionLabel={(option) => String(option.name)}
              value={moveToNext.feedback}
              onChange={(e, newValue) => {
                setMoveToNext({ ...moveToNext, feedback: newValue });
              }}
              style={{
                background: "#fff",
                borderRadius: 8,
                width: "100%",
                marginBottom: 20,
                marginTop: 20,
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={"Feedbacks"}
                  inputProps={{
                    ...params.inputProps,
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                />
              )}
            />
            {moveToNext?.feedback &&
              moveToNext?.feedback?.name?.toLowerCase() === "other" && (
                <TextField
                  id="outlined-multiline-static"
                  label="Enter Your Feedback"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  multiline
                  style={{ width: "100%" }}
                  rows={6}
                  variant="outlined"
                  value={moveToNext?.feedback_typed}
                  onChange={(e) => {
                    setMoveToNext({
                      ...moveToNext,
                      feedback_typed: e.target.value,
                    });
                    setTypeFeedbackError(null);
                  }}
                  error={typeFeedbackError ? true : false}
                  helperText={typeFeedbackError}
                />
              )}
          </div>
          <br />
          <Grid container spacing={5}>
            <Grid item xs={6} style={{ display: "grid" }}>
              <Button
                variant="outlined"
                className={classes.button}
                onClick={handleClose}
                color="primary"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} style={{ display: "grid" }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                onClick={() => {
                  if (!loading) handleSubmit();
                }}
              >
                {loading ? <CustomButtonCircularProgress /> : "Move Applicants"}
              </Button>
            </Grid>
          </Grid>
          {/* <div
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <Button
              variant="outlined"
              className={classes.button}
              onClick={handleClose}
              color="primary"
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
            >
              Move Applicants
            </Button>
          </div> */}
        </div>
        {/* <DialogActions> */}

        {/* </DialogActions> */}
      </Dialog>
    </div>
  );
}
