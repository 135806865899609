import {
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  praveshAxiosGetReq,
  praveshAxiosPatchReq,
  praveshAxiosPostReq,
} from "../../../api/BaseApi/apiBase";
import { isGradeSystemFn } from "../../../api/Domain/selector";
import {
  collegeIdFn,
  userRolesActionsFn,
  educationGroupIdFn,
  entityInfoSelectorFn,
} from "../../../api/SignIn/selector";
import { checkForToken } from "../../../constants/ApiCall";
import { BackendBaseApi } from "../../../constants/constants";
import useAxiosApi from "../../../Hooks/useAxiosApi";
import { getSharerColumnsForTrack } from "../../../utility/allColumns";
import AssignmentTable from "../../DialogSection/AssignmentTable";
import StudentResume from "../../DialogSection/Resume/StudentResume";
import UploadFile from "../../DialogSection/UploadFile/UploadFile";
import TrackActionBar from "../../Input/TrackActionBar";
import CompanyTable from "../../Table/CompanyTable";

const NewTabs = withStyles((theme) => ({
  root: {
    borderBottom: ".7px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}))(Tabs);

const NewTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$selected": {
      color: "#1890ff",
      fontWeight: "bold",
    },
    "&:focus": {
      color: "black",
      fontWeight: "bold",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //    backgroundColor: theme.palette.background.paper,
  },
  columnHeader: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
}));

export default function TrackJobTab({
  navbarData,
  filters,
  setFilters,
  filterList,
  trackTableData,
  setTrackTableData = () => {},
  selectedRowsData,
  setSelectedRowsData = () => {},
  loading,
  moveNextArray,
  moveToNext = () => {},
  setMoveToNext = () => {},
  MoveToNextStep,
  fromPublicTrack,
  validatorData,
  data,
  selectedColumnFields,
  setSelectedColumnFields = () => {},
}) {
  const classes = useStyles();
  const alert = useAlert();
  const history = useHistory();
  const isGradeSystem = useSelector(isGradeSystemFn);
  const location = useLocation();
  const [pageData, setPageData] = useState({ currentPage: 0, pageSize: 20 });
  const [selectedID, setSelectedID] = React.useState([]);
  const [resumeData, setResumeData] = React.useState(null);
  const [openResume, setOpenResume] = useState(false);
  const handleChange = (event, newValue) => {
    if (loading) return;
    setFilters({ ...filters, current_status: newValue });
  };

  const entityInfo = useSelector(entityInfoSelectorFn) || {
    college: "College",
    university: "University",
  };

  const initialUploadOfferDetails = {
    open: false,
    fileNames: [],
    title: "Offer Letter",
    acceptedFileType: "image/*,.doc,.docx,.pdf",
    candidateId: "",
    binaryFileData: null,
    updateOffer: false,
    offerLetterId: "",
    collegeId: "",
  };
  const [uploadOffer, setUploadOffer] = useState(initialUploadOfferDetails);
  const [loadingUpload, setLoadingUpload] = useState(false);

  const userRolesPermission = useSelector(userRolesActionsFn);
  const collegeId = useSelector(collegeIdFn);
  const educationGroupId = useSelector(educationGroupIdFn);
  const urlParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (selectedRowsData?.length === 0) {
      setSelectedID([]);
    }
  }, [selectedRowsData?.length]);

  const handleOpenResume = (data) => {
    setResumeData(data);
    setOpenResume(true);
  };
  const handleCloseResume = () => {
    setOpenResume(false);
  };

  const columnss = [
    {
      field: "enrollment_id",
      headerName: "Enrollment Id",
      width: 140,
      hide: false,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "7px",
          }}
        >
          Enrollment Id
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.enrollment_id}
        </span>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      hide: false,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "65px",
          }}
        >
          Name
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.name} placement="right">
            <Typography
              color="primary"
              style={{ fontSize: "14px", cursor: "pointer" }}
              onClick={() => {
                if (!params?.row?.user_id) {
                  alert?.error(
                    "Something wrong with this data, kindly connect with GetWork"
                  );
                } else {
                  handleOpenResume(params?.row);
                }
              }}
              noWrap
            >
              {params?.row?.name}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      type: "Email",
      hide: false,
      width: 170,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "50px",
          }}
        >
          Email
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.email}
        </span>
      ),
    },
    {
      field: "phone",
      headerName: "Phone Number",
      width: 160,
      hide: false,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Phone Number
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.phone}
        </span>
      ),
      // valueGetter: params =>
      //   `${params.getValue(params.id, "firstName") || ""} ${
      //     params.getValue(params.id, "lastName") || ""
      //   }`,
    },
    {
      field: "gender",
      headerName: "Gender",
      hide: false,
      type: "text",
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Gender
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.gender ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }}>
              {params?.row?.gender ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    {
      field: "batch",
      headerName: "Batch",
      type: "text",
      width: 170,
      hide: false,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "30px",
          }}
        >
          Current Batch
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.batch}
        </span>
      ),
    },
    {
      field: "degree_name",
      headerName: "Current Degree",
      hide: false,
      type: "text",
      width: 180,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "30px",
          }}
        >
          Current Degree
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.degree_name}
        </span>
      ),
    },
    {
      field: "course_name",
      headerName: "Current Course",
      hide: false,
      type: "text",
      width: 210,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "60px",
          }}
        >
          Current Course
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "left",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
            }}
          >
            {" "}
            <Grid item xs zeroMinWidth>
              <Tooltip title={params.row.course_name} placement="right">
                <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                  {params.row.course_name}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
        // <span
        //   style={{
        //     textAlign: "center",
        //     display: "flex",
        //     margin: "auto",
        //     fontSize: "14px",
        //   }}
        // >
        //   {params.row.course_name}
        // </span>
      ),
    },

    {
      field: "course_college",
      headerName: "Current College",
      hide: false,
      type: "text",
      width: 210,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "60px",
          }}
        >
          {`Current ${entityInfo.college}`}
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "left",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
            }}
          >
            {" "}
            <Grid item xs zeroMinWidth>
              <Tooltip title={params?.row?.current_college} placement="right">
                <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                  {params?.row?.current_college}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ),
    },

    {
      field: "ugCollege",
      headerName: "College Name(UG)",
      hide: false,
      // type: "Email",
      width: 220,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "35px",
          }}
        >
          College Name(UG)
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "left",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Tooltip title={params?.row?.ugCollege ?? "NA"} placement="right">
                <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                  {params?.row?.ugCollege ?? "NA"}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ),
    },

    {
      field: "ugdegree",
      headerName: "Degree(UG)",
      hide: false,
      type: "text",
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Degree(UG)
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.ugDegree ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.ugDegree ?? "NA"}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "ugSpecialization",
      headerName: "Specialization(UG)",
      hide: false,
      type: "text",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Specialization (UG)
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.ugSpecialization ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.ugSpecialization ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    {
      field: "ugPercentage",
      headerName: isGradeSystem ? "CGPA(UG)" : "Percentage(UG)",
      hide: false,
      type: "text",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          {isGradeSystem ? "CGPA(UG)" : "Percentage(UG)"}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.ugPercentage ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.ugPercentage ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },

    {
      field: "pgCollege",
      headerName: "College Name(PG)",
      hide: false,
      // type: "Email",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "35px",
          }}
        >
          College Name(PG)
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Tooltip title={params?.row?.pgCollege ?? "NA"} placement="right">
                <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                  {params?.row?.pgCollege ?? "NA"}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "pgDegree",
      headerName: "Degree(PG)",
      hide: false,
      type: "text",
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Degree(PG)
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.pgDegree ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.pgDegree ?? "NA"}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "pgSpecialization",
      headerName: "Specialization(PG)",
      hide: false,
      type: "text",
      width: 200,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Specialization (PG)
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.pgSpecialization ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }}>
              {params?.row?.pgSpecialization ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    {
      field: "pgPercentage",
      headerName: isGradeSystem ? "CGPA(PG)" : "Percentage(PG)",
      hide: false,
      type: "text",
      width: 180,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          {isGradeSystem ? "CGPA(PG)" : "Percentage(PG)"}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.pgPercentage ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.pgPercentage ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },

    {
      field: "phdCollege",
      headerName: "College Name(Phd)",
      hide: false,
      // type: "Email",
      width: 220,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "35px",
          }}
        >
          College Name(Phd)
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Tooltip
                title={params?.row?.phdCollege ?? "NA"}
                placement="right"
              >
                <Typography color="inherit" noWrap style={{ fontSize: "14px" }}>
                  {params?.row?.phdCollege ?? "NA"}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "phdDegree",
      headerName: "Degree(Phd)",
      hide: false,
      type: "text",
      width: 160,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Degree(Phd)
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.phdDegree ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }}>
              {params?.row?.phdDegree ?? "NA"}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "phdSpecialization",
      headerName: "Specialization(Phd)",
      hide: false,
      type: "text",
      width: 220,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Specialization (Phd)
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.phdSpecialization ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }}>
              {params?.row?.phdSpecialization ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    {
      field: "phdPercentage",
      headerName: isGradeSystem ? "CGPA(Phd)" : "Percentage(Phd)",
      hide: false,
      type: "text",
      width: 180,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          {isGradeSystem ? "CGPA(Phd)" : "Percentage(Phd)"}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip title={params?.row?.phdPercentage ?? "NA"} placement="right">
            <Typography style={{ fontSize: "14px" }}>
              {params?.row?.phdPercentage ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    // {
    //   field: "secondarySchool",
    //   headerName: " Secondary School",
    //   hide: false,
    //   type: "text",
    //   width: 180,
    //   renderHeader: (params) => (
    //     <strong
    //       style={{
    //         color: "#5a6268",
    //         marginLeft: "15px",
    //       }}
    //     >
    //       Secondary School
    //     </strong>
    //   ),
    //   renderCell: (params) => (
    //     <span
    //       style={{
    //         textAlign: "center",
    //         display: "flex",
    //         margin: "auto",
    //         fontSize: "14px",
    //       }}
    //     >
    //       <Tooltip
    //         title={params?.row?.secondarySchool ?? "NA"}
    //         placement="right"
    //       >
    //         <Typography style={{ fontSize: "14px" }} noWrap>
    //           {params?.row?.secondarySchool ?? "NA"}
    //         </Typography>
    //       </Tooltip>{" "}
    //     </span>
    //   ),
    // },
    {
      field: "secondaryBoard",
      headerName: "Secondary Board",
      hide: false,
      type: "text",
      width: 180,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Secondary Board
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.secondaryBoard ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.secondaryBoard ?? "NA"}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "secondaryPercentage",
      headerName: isGradeSystem ? "Secondary CGPA" : "Secondary Percentage",
      hide: false,
      type: "text",
      width: 240,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          {isGradeSystem ? "Secondary CGPA" : "Secondary Percentage"}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.secondaryPercentage ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.secondaryPercentage ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },

    // {
    //   field: "seniorSecondarySchool",
    //   headerName: "Senior Secondary School",
    //   hide: false,
    //   type: "text",
    //   width: 220,
    //   renderHeader: (params) => (
    //     <strong
    //       style={{
    //         color: "#5a6268",
    //         marginLeft: "15px",
    //       }}
    //     >
    //       Senior Secondary School
    //     </strong>
    //   ),
    //   renderCell: (params) => (
    //     <span
    //       style={{
    //         textAlign: "center",
    //         display: "flex",
    //         margin: "auto",
    //         fontSize: "14px",
    //       }}
    //     >
    //       <Tooltip
    //         title={params?.row?.seniorSecondarySchool ?? "NA"}
    //         placement="right"
    //       >
    //         <Typography style={{ fontSize: "14px" }} noWrap>
    //           {params?.row?.seniorSecondarySchool ?? "NA"}
    //         </Typography>
    //       </Tooltip>{" "}
    //     </span>
    //   ),
    // },
    {
      field: "seniorSecondaryBoard",
      headerName: "Senior Secondary Board",
      hide: false,
      type: "text",
      width: 220,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Senior Secondary Board
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.seniorSecondaryBoard ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.seniorSecondaryBoard ?? "NA"}
            </Typography>
          </Tooltip>{" "}
        </span>
      ),
    },
    {
      field: "seniorSecondaryPercentage",
      headerName: "Senior Secondary Percentage",
      hide: false,
      type: "text",
      width: 250,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          {isGradeSystem
            ? "Senior Secondary CGPA"
            : "Senior Secondary Percentage"}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          <Tooltip
            title={params?.row?.seniorSecondaryPercentage ?? "NA"}
            placement="right"
          >
            <Typography style={{ fontSize: "14px" }} noWrap>
              {params?.row?.seniorSecondaryPercentage ?? "NA"}
            </Typography>
          </Tooltip>
        </span>
      ),
    },

    {
      field: "applied_on",
      headerName: "Applied On",
      type: "date",
      width: 135,
      hide: false,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Applied On
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.applied_on}
        </span>
      ),
    },
    {
      field: "assignment",
      headerName: "Assignment",
      width: 140,
      hide: false,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            marginLeft: "15px",
          }}
        >
          Assignment
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
          }}
        >
          {params.row.assignment_list?.length > 0 ? (
            <AssignmentTable data={params.row} />
          ) : (
            "NA"
          )}
        </span>
      ),
    },
  ];

  const [columns, setColumns] = useState(columnss);

  const [shareColumns, setShareColumns] = useState(
    localStorage.getItem("track_column_fields") !== null
      ? JSON.parse(localStorage.getItem("track_column_fields"))
      : getSharerColumnsForTrack(isGradeSystem, entityInfo.college)
  );

  useEffect(() => {
    if (typeof isGradeSystem === "boolean")
      setShareColumns(getSharerColumnsForTrack(isGradeSystem));
  }, [isGradeSystem]);

  // useEffect(() => {
  //   if (shareColumns) {
  //     let temp = [...columns];
  //     let fieldsToShare = [];
  //     shareColumns?.map((item, index) => {
  //       temp[index].hide = item?.hide;
  //       if (!item?.hide) {
  //         fieldsToShare?.push(item?.headerName);
  //       }
  //     });
  //     setSelectedColumnFields(fieldsToShare);
  //     setColumns(temp);
  //     localStorage?.setItem(
  //       "track_column_fields",
  //       JSON.stringify(shareColumns)
  //     );
  //   }
  // }, [shareColumns]);
  useEffect(() => {
    if (shareColumns) {
      let temp = [...columns];
      let fieldsToShare = [];

      shareColumns?.forEach((item, index) => {
        if (temp[index]) {
          temp[index].hide = item?.hide;
          if (!item?.hide) {
            fieldsToShare.push(item?.headerName);
          }
        } else {
          temp[index] = {
            hide: item?.hide,
            headerName: item?.headerName,
          };
          if (!item?.hide) {
            fieldsToShare.push(item?.headerName);
          }
        }
      });

      setSelectedColumnFields(fieldsToShare);
      setColumns(temp);
      localStorage?.setItem(
        "track_column_fields",
        JSON.stringify(shareColumns)
      );
    }
  }, [shareColumns]);

  useEffect(() => {
    if (
      fromPublicTrack &&
      validatorData?.action_performed?.action?.length > 0
    ) {
      const removeStrings = ["degree_id,course_id, job_id"];
      var arr2 = validatorData?.action_performed?.action.filter(
        (item) => !removeStrings.includes(item)
      );
      var arr1 = [...columns];
      //var arr2 = [...validatorData?.action_performed?.action];
      var filteredArray = filterByReference(arr1, arr2);

      let fieldsToShare = [];
      filteredArray?.map((item, index) => {
        if (!item?.hide) fieldsToShare?.push(item?.headerName);
      });

      setSelectedColumnFields(fieldsToShare);
      setColumns(filteredArray);
    }
  }, [validatorData]);

  const filterByReference = (arr1, arr2) => {
    let res = [];
    res = arr1.filter((el) => {
      return arr2.find((element) => {
        return element === el.field;
      });
    });
    return res;
  };

  const endPoint = `${BackendBaseApi?.PRAVESH}api/common/offer_letter/?job_id=${
    data?.niyukti_job_id || urlParams?.get("job_id")
  }`;

  const { data: offerLetters, apiCall: getUpdatedOfferLetters } = useAxiosApi({
    url: endPoint,
    mountingCallFlag: collegeId || educationGroupId ? true : false,
  });

  const getOfferLetterUrl = (userId) => {
    const matchingItem = offerLetters?.find((item) => item?.user_id === userId);

    if (!matchingItem) return false;
    else return [matchingItem?.offer_letter, matchingItem?.id];
  };

  const callOfferApi = async (isPatchCall) => {
    if (!uploadOffer?.candidateId) return;

    const endPoint = `api/common/offer_letter/${
      isPatchCall ? `${uploadOffer?.offerLetterId}/` : ""
    }`;

    const formData = new FormData();

    formData.append("file_type", 19);

    if (data?.niyukti_job_id) {
      formData.append("offer_source", "COMPANY");
      formData.append("company_id", data?.niyukti_company_id);
      formData.append("job_id", data?.niyukti_job_id);
    } else {
      formData.append("offer_source", "COLLEGE");
      formData.append("company_id", data?.company_id);
      formData.append("job_id", filters?.job_id);
    }

    formData.append("college_id", uploadOffer?.collegeId);
    if (isPatchCall) formData.append("id", uploadOffer?.offerLetterId);
    formData.append("user", uploadOffer?.candidateId);
    formData.append("sub_category_id", 19);
    formData.append("file", uploadOffer?.binaryFileData);
    formData.append("source_page", "College Track Applicants");

    setLoadingUpload(true);

    try {
      let res;
      if (isPatchCall) res = await praveshAxiosPatchReq(endPoint, formData);
      else res = await praveshAxiosPostReq(endPoint, formData);

      if (res?.data?.success) {
        alert.success(res?.data?.data?.message);
        setUploadOffer(initialUploadOfferDetails);
        getUpdatedOfferLetters();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingUpload(false);
    }
  };

  useEffect(() => {
    let flag = ["Hired", "Offered", "Joined"].includes(
      filters?.navbar_object?.heading
    );
    let isNiyuktiJob = ["GETWORK_FULFILLED", "GETWORK_INBOUND"]?.includes(
      data?.job_source
    );
    // in public links no view for offer letters and update function allowed

    if (flag && !fromPublicTrack) {
      const offerLetterColumn = {
        field: "offerLetter",
        width: 150,
        sortable: false,
        hide: false,
        renderHeader: (params) => (
          <div className={classes.columnHeader}>
            <strong
              style={{
                color: "#5a6268",
                marginLeft: "15px",
              }}
            >
              Offer Letter
            </strong>
          </div>
        ),
        renderCell: (params) => (
          <span
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
            }}
          >
            {getOfferLetterUrl(params.row.user_id)[0] ? (
              <>
                <Button
                  className={classes?.uploadOffer}
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => {
                    const url = getOfferLetterUrl(params.row.user_id)[0];
                    window.open(url, "_blank").focus();
                  }}
                >
                  View
                </Button>
                {!isNiyuktiJob && (
                  <Button
                    className={classes?.uploadOffer}
                    variant="outlined"
                    color="primary"
                    size="small"
                    style={{ marginLeft: 3 }}
                    onClick={() => {
                      const [offerLetterUrl, offerLetterId] = getOfferLetterUrl(
                        params.row.user_id
                      );

                      setUploadOffer({
                        ...uploadOffer,
                        open: true,
                        candidateId: params.row.user_id,
                        updateOffer: true,
                        offerLetterId: offerLetterId,
                        collegeId: params?.row?.college_id,
                      });
                    }}
                  >
                    Update
                  </Button>
                )}
              </>
            ) : (
              <>
                {params.row.user_id && !isNiyuktiJob ? (
                  <Button
                    className={classes?.uploadOffer}
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => {
                      setUploadOffer({
                        ...uploadOffer,
                        open: true,
                        candidateId: params.row.user_id,
                        collegeId: params.row.college_id,
                      });
                    }}
                  >
                    Upload
                  </Button>
                ) : (
                  "NA"
                )}
              </>
            )}
          </span>
        ),
      };
      setColumns([...columnss, offerLetterColumn]);
    } else {
      setColumns([...columnss]);
    }
  }, [filters?.navbar_object?.heading, offerLetters]);

  const [tableData, setTableData] = useState([]);
  const [educationDetialsData, setEducationDetialsData] = React.useState(null);
  const [educationDetialsLoading, setEducationDetialsLoading] =
    React.useState(false);

  const getEducationDetialsData = async (id) => {
    //const url = `api/manage_student/student_application_count/?student_id=${id}`;
    const url = `api/student/student_education_list/?college_id=${
      fromPublicTrack ? validatorData?.college_id : collegeId
    }&student_id=${id}`;
    setEducationDetialsLoading(true);
    try {
      const res = await praveshAxiosGetReq(url);

      if (res.data.success) {
        const statusResponse = res?.data?.data;

        setEducationDetialsData({ ...statusResponse });
        setEducationDetialsLoading(false);
      } else {
        console.log("error ocuured");
      }
    } catch (error) {
      setEducationDetialsLoading(false);
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };

  useEffect(() => {
    if (trackTableData?.length && pageData) {
      let userIdString = ``;
      setTableData([]);
      const arr = [...trackTableData];
      arr
        ?.slice(
          (pageData?.currentPage + 1) * pageData?.pageSize - pageData?.pageSize,
          (pageData?.currentPage + 1) * pageData?.pageSize
        )
        ?.map((item) => {
          if (item?.user_id) {
            userIdString += `${item?.user_id},`;
          }
        });

      getEducationDetialsData(userIdString.replace(/,\s*$/, ""));
    }
  }, [
    pageData,
    trackTableData?.length,
    /* filters */ filters?.degree,
    filters?.course,
    filters?.batch,
    filters?.applied_on,
    filters?.enrollment_search_check === true,
    filters?.search_check === true,
    filters?.current_status,
  ]);

  useEffect(() => {
    if (trackTableData?.length > 0 && educationDetialsData) {
      const arr = [...trackTableData];
      setTableData([]);
      arr
        ?.slice(
          (pageData?.currentPage + 1) * pageData?.pageSize - pageData?.pageSize,
          (pageData?.currentPage + 1) * pageData?.pageSize
        )
        ?.forEach((item) => {
          educationDetialsData &&
            educationDetialsData[item.user_id] &&
            Object?.keys(educationDetialsData[item.user_id])?.map((key) => {
              if (key === "1") {
                item.secondarySchool =
                  educationDetialsData[item.user_id][key]?.college_name;
                item.secondaryBoard =
                  educationDetialsData[item.user_id][key]?.board;
                item.secondaryPercentage =
                  educationDetialsData[item.user_id][key]?.percent;
              } else if (key === "2") {
                item.seniorSecondarySchool =
                  educationDetialsData[item.user_id][key]?.college_name;
                item.seniorSecondaryBoard =
                  educationDetialsData[item.user_id][key]?.board;
                item.seniorSecondaryPercentage =
                  educationDetialsData[item.user_id][key]?.percent;
              } else if (key === "3") {
                item.ugCollege =
                  educationDetialsData[item.user_id][key]?.college_name;
                item.ugDegree = educationDetialsData[item.user_id][key]?.degree;
                item.ugSpecialization =
                  educationDetialsData[item.user_id][key]?.specialization;
                item.ugPercentage =
                  educationDetialsData[item.user_id][key]?.percent;
              } else if (key === "4") {
                item.pgCollege =
                  educationDetialsData[item.user_id][key]?.college_name;
                item.pgDegree = educationDetialsData[item.user_id][key]?.degree;
                item.pgSpecialization =
                  educationDetialsData[item.user_id][key]?.specialization;
                item.pgPercentage =
                  educationDetialsData[item.user_id][key]?.percent;
              } else if (key === "5") {
                item.phdCollege =
                  educationDetialsData[item.user_id][key]?.college_name;
                item.phdDegree =
                  educationDetialsData[item.user_id][key]?.degree;
                item.phdSpecialization =
                  educationDetialsData[item.user_id][key]?.specialization;
                item.phdPercentage =
                  educationDetialsData[item.user_id][key]?.percent;
              } else if (key === "gender") {
                item.gender = educationDetialsData[item.user_id][key];
              }
            });
        });
      setTableData(arr);
    } else {
      setTableData([]);
    }
  }, [educationDetialsData, trackTableData]);

  useEffect(() => {
    setTableData([]);
    setTrackTableData([]);
  }, [filters?.navbar_object]);

  return (
    <div className={classes.root}>
      {/* <AppBar
        position="static"
        style={{
          backgroundColor: "#fafafa",
          boxShadow: "none",
          borderBottom: ".5px solid #dddddd",
        }}
      > */}
      <NewTabs
        style={{
          //backgroundColor: "#fafafa",
          boxShadow: "none",

          //borderBottom: ".5px solid #dddddd",
        }}
        value={filters?.current_status}
        onChange={(e, newValue) => {
          if (loading) return;
          handleChange(e, newValue);
        }}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        disabled={loading}
      >
        {navbarData &&
          Object.keys(navbarData).map((item) => (
            <NewTab
              value={navbarData[item].round_id}
              disabled={loading}
              onClick={(e) => {
                setFilters({
                  ...filters,
                  current_status: navbarData[item]?.round_id,
                  navbar_object: navbarData[item],
                  search: null,
                  search_check: false,
                  enrollment_search: null,
                  enrollment_search_check: false,
                  course: null,
                  degree: null,
                  batch: null,
                  applied_on: null,
                });
                // onTabClick(e, navbarData[item]?.round_id, navbarData[item]);
              }}
              label={
                <Tooltip title={navbarData[item].tooltip} placement="bottom">
                  <span>{`${navbarData[item].heading}(${navbarData[item].count})`}</span>
                </Tooltip>
              }
              wrapped
              {...a11yProps(navbarData[item].round_id)}
            />
          ))}
      </NewTabs>
      {/* </AppBar> */}

      <TrackActionBar
        filters={filters}
        setFilters={setFilters}
        selectedRowsData={selectedRowsData}
        setSelectedRowsData={setSelectedRowsData}
        selectedID={selectedID}
        setSelectedID={setSelectedID}
        filterList={filterList}
        moveNextArray={moveNextArray}
        moveToNext={moveToNext}
        setMoveToNext={setMoveToNext}
        MoveToNextStep={MoveToNextStep}
        fromPublicTrack={fromPublicTrack}
        validatorData={validatorData}
        userRolesPermission={userRolesPermission}
        navbarData={navbarData}
        data={data}
        tableColumns={shareColumns}
        setTableColumns={setShareColumns}
      />
      <br />
      {navbarData &&
        Object.keys(navbarData).map((item) => (
          <TabPanel
            value={filters?.current_status}
            index={navbarData[item].round_id}
          >
            <CompanyTable
              loading={loading || educationDetialsLoading}
              list={tableData?.length > 0 ? tableData : []}
              columns={columns}
              setSelectedRowsData={setSelectedRowsData}
              selectedRowData={selectedRowsData}
              selectedID={selectedID}
              setSelectedID={setSelectedID}
              pageData={pageData}
              setPageData={setPageData}
            />
          </TabPanel>
        ))}
      <StudentResume
        open={openResume}
        handleClose={handleCloseResume}
        data={resumeData}
        moveNextArray={moveNextArray}
        moveToNext={moveToNext}
        setMoveToNext={setMoveToNext}
        MoveToNextStep={MoveToNextStep}
        fromPublicTrack={fromPublicTrack}
        validatorData={validatorData}
        filters={filters}
        navbarData={navbarData}
      />
      {uploadOffer?.open && (
        <UploadFile
          data={uploadOffer}
          handleUpload={() => {
            callOfferApi(uploadOffer?.updateOffer);
          }}
          handleClose={() => {
            setUploadOffer({ ...uploadOffer, open: false });
          }}
          open={uploadOffer.open}
          fileNames={uploadOffer.fileNames}
          setFileNames={(fileName, fileData) => {
            setUploadOffer({
              ...uploadOffer,
              fileNames: fileName,
              binaryFileData: fileData,
            });
          }}
          loading={loadingUpload}
        />
      )}
    </div>
  );
}
