//Imports
import axios from "axios";
import { checkForToken } from "../../constants/ApiCall";
import { BackendBaseApi } from "../../constants/constants";
//Exports
export const LOGIN_API = "LOGIN_API";
export const USER_DETAILS = "USER_DETAILS";
export const LOGGED_IN = "LOGGED_IN";
export const TEAM_MEMBER_DETAILS = "TEAM_MEMBER_DETAILS";
//Login Call Action
export const loginAPI = (
  data,
  history,
  setLoading,
  setErrors,
  errors,
  alert,
  location,
  isEducationGroup,
  isCollegeGroup
) => {
  return async (dispatch) => {
    setLoading(true);
    try {
      const res = await axios.post(
        BackendBaseApi.PRAVESH + "api/saas/login/",
        data,
        { withCredentials: true }
      );
      if (res.data.success) {
        alert.success("Login Successful");

        if (location.search && location.search.includes("?source")) {
          history.push({
            pathname: `${location.search.replace("?source=", "")}`,
          });
        } else if (res.data?.data && !res.data.data.is_first_login) {
          history.push({ pathname: "/reset-new-password", email: data?.email });
        } else {
          // if (isEducationGroup) history.push({ pathname: "/dashboard" });
          // else if (isCollegeGroup) history.push({ pathname: "/college-group/dashboard" });
          // else history.push({ pathname: "dashboard" });

          const collegeViewCollegeId =
            localStorage.getItem("college_id") || null;

          dispatch(
            userDetailsApi(
              collegeViewCollegeId,
              history,
              location,
              alert,
              false,
              true
            )
          );
        }
      } else {
        alert.error("Please check yor Email and Password");
        setErrors({
          ...errors,
          email: "Please Enter valid Email Address",
          password: "Please Enter valid Password",
        });
      }
      setLoading(false);

      // dispatch({
      //   type: LOGIN_API,
      //   payload: res.data.data,
      // });
    } catch (error) {
      setLoading(false);
      // if (error.response.status === 401) {
      //   if (Logout()) history.push("dashboard");
      //   throw error;
      // }

      setErrors({
        ...errors,
        email: "Please Enter valid Email Address",
        password: "Please Enter valid Password",
      });
      alert.error("Please check yor Email and Password");
      throw error;
    }
  };
};

//User Detail Call Action
export const userDetailsApi = (
  collegeId = null,
  history,
  location,
  alert,
  isEducationGroup,
  isPublic = false
) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(
        BackendBaseApi.SANCHALAN + "api/layout/user_details/",
        { withCredentials: true }
      );
      if (res.data.success) {
        let obj;
        if (collegeId) {
          obj = {
            ...res.data.data,
            college_details: {
              ...res.data.data?.college_details,
              college_id: collegeId,
            },
          };
        } else {
          obj = res.data.data;
        }

        dispatch({
          type: USER_DETAILS,
          payload: obj,
        });
        dispatch({
          type: LOGGED_IN,
          payload: true,
        });

        if (isPublic) {
          if (res.data.data?.is_education_group_admin)
            history.push({ pathname: "/educational-group/dashboard" });
          else if (res.data.data?.is_college_group_admin)
            history.push({ pathname: "/college-group/dashboard" });
          // else history.push({ pathname: "dashboard" });
          else history.push({ pathname: "/home" });
        }
      } else {
        alert.error(res.data.error);
      }
    } catch (error) {
      if (!isPublic) {
        if (error?.response?.status === 401) {
          dispatch({
            type: LOGGED_IN,
            payload: false,
          });
          dispatch({
            type: "USER_LOGOUT",
            payload: {},
          });
          checkForToken(error, history, location, alert, dispatch);
        }
      }
    }
  };
};
