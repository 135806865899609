import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import CollegeImage from "../../assets/img/company.jpg";
import CheckRegister from "../../components/Input/CheckRegister";
import LeftSideComponent from "../../components/Public/LeftSideComponent";
import RightSideComponent from "../../components/Public/RightSideComponent";
import { userLoggedInSelectorFn } from "../../api/Domain/selector";
import checkPasswordLink from "../../components/Input/CheckPasswordLink";
import CheckPasswordLink from "../../components/Input/CheckPasswordLink";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    // marginTop: "50px",
  },
  image: {
    backgroundImage: `url(${CollegeImage})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
}));

function NewPasswordLink() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const isLoggedIn = useSelector(userLoggedInSelectorFn);
  useEffect(() => {
    if (isLoggedIn) {
      history.push("dashboard");
    }
  }, []);
  return (
    <>
      {/* <Navbar /> */}
      <Grid container component="main" className={classes.root}>
        <LeftSideComponent />

        <RightSideComponent ChildComponent={CheckPasswordLink} />
      </Grid>
    </>
  );
}

export default NewPasswordLink;
